import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, LinearProgress } from "@mui/material";
import dayjs from "dayjs";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { dateFormat } from "../../../../core/constants";
import ProductDocumentModal from "../../../../components/ProductDocumentModal";
import DispatchInfoModal from "../../Supply/common/DispatchInfoModal";

const RFSDetails = ({ rfsData, fetchParent }) => {
  const [showProductDocumentModal, setShowProductDocumentModal] = useState({
    open: false,
    vendorProductCategoryId: null,
    projectProductCategoryId: null
  });
  const [showDispatchInfoModal, setShowDispatchInfoModal] = useState({
    open: false,
    projectProductCategoryId: null,
    dispatchReceiptApi: null,
    updateApi: null,
    getApi: null,
    modalTitle: ""
  });

  let columns = [
    {
      field: "request_for_sample_at",
      headerName: "RFS Date",
      width: 200,
      renderCell: (params) => {
        return params.row.request_for_sample_at ? (
          <div>{dayjs(params.row.request_for_sample_at).format(dateFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "due_date_request_for_sample_at",
      headerName: "Due date Sample Dispatch Supplier",
      width: 200,
      renderCell: (params) => {
        return params.row.due_date_request_for_sample_at ? (
          <div>{dayjs(params.row.due_date_request_for_sample_at).format(dateFormat)}</div>
        ) : (
          ""
        );
      }
    },
    // {
    //   field: "rfs_due_date",
    //   headerName: "RFS Due Date",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.rfs_due_date ? (
    //       <div>{dayjs(params.row.rfs_due_date).format(dateFormat)}</div>
    //     ) : (
    //       ""
    //     );
    //   }
    // },
    {
      field: "supplier_document_upload",
      headerName: "Supplier Document Upload",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() =>
              setShowProductDocumentModal({
                vendorProductCategoryId: params.row.vendors_products_category_id,
                projectProductCategoryId: params.row.id,
                open: true
              })
            }>
            View Documents
          </Button>
        );
      }
    },
    {
      field: "assigned_to_qa_at",
      headerName: "Request For QA Date",
      width: 170,
      renderCell: (params) => {
        return params.row.assigned_to_qa_at ? (
          <div>{dayjs(params.row.assigned_to_qa_at).format(dateFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "assigned_to_qa",
      headerName: "Approval From QA",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.assigned_to_qa_at
              ? params.row.is_qa_approved_supplier_documents === true
                ? "Passed"
                : "Pending"
              : ""}
          </div>
        );
      }
    },
    {
      field: "dispatch_info",
      headerName: "Dispatch Info",
      width: 200,

      renderCell: (params) => {
        return (
          <Button
            disabled={
              params.row.assigned_to_qa_at && params.row.is_qa_approved_supplier_documents === true
                ? false
                : true
            }
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() =>
              setShowDispatchInfoModal({
                open: true,
                projectProductCategoryId: params.row.id,
                updateApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/update-sample-supplier-to-covvalent-dispatch-info`,
                getApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/sample-supplier-to-covvalent-dispatch-info`,
                dispatchReceiptApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/upload-dispatch-document/supplier-to-covvalent-dispatch-info`,
                modalTitle: "Sample Dispatch Info - [Supplier-to-Covvalent]"
              })
            }>
            View
          </Button>
        );
      }
    },
    {
      field: "expected_supplier_to_covvalent_receiving_at",
      headerName: "Expected Sample Receipt Date",
      width: 250,
      renderCell: (params) => {
        return params.row.expected_supplier_to_covvalent_receiving_at ? (
          <div>
            {dayjs(params.row.expected_supplier_to_covvalent_receiving_at).format(dateFormat)}
          </div>
        ) : (
          ""
        );
      }
    },
    {
      field: "due_date_expected_supplier_to_covvalent_receiving_at",
      headerName: "Sample Dispatch Due Date",
      width: 200,
      renderCell: (params) => {
        return params.row.due_date_expected_supplier_to_covvalent_receiving_at ? (
          <div>
            {dayjs(params.row.due_date_expected_supplier_to_covvalent_receiving_at).format(
              dateFormat
            )}
          </div>
        ) : (
          ""
        );
      }
    },
    {
      field: "sample_to_buyer_dispatch_info",
      headerName: "Sample To Buyer Dispatch Info",
      width: 200,

      renderCell: (params) => {
        return (
          <Button
            disabled={
              params.row.assigned_to_qa_at && params.row.is_qa_approved_supplier_documents === true
                ? false
                : true
            }
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() =>
              setShowDispatchInfoModal({
                open: true,
                projectProductCategoryId: params.row.id,
                updateApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/update-sample-covvalent-to-buyer-dispatch-info`,
                getApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/sample-covvalent-to-buyer-dispatch-info`,
                dispatchReceiptApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/upload-dispatch-document/covvalent-to-buyer-dispatch-info`,
                modalTitle: "Sample Dispatch Info - [Covvalent-to-Buyer]"
              })
            }>
            View
          </Button>
        );
      }
    },
    {
      field: "expected_covvalent_to_buyer_receiving_at",
      headerName: "Buyer Receipt Date",
      width: 250,
      renderCell: (params) => {
        return params.row.expected_covvalent_to_buyer_receiving_at ? (
          <div>{dayjs(params.row.expected_covvalent_to_buyer_receiving_at).format(dateFormat)}</div>
        ) : (
          ""
        );
      }
    }
  ];

  return (
    <div className="h-[400px] w-full mb-4">
      <DataGrid
        rowSelection={false}
        rows={rfsData}
        columns={columns}
        //loading={loading}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        // onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showProductDocumentModal.open) && (
        <ProductDocumentModal
          open={showProductDocumentModal.open}
          onClose={() =>
            setShowProductDocumentModal({
              open: false,
              vendorProductCategoryId: null,
              projectProductCategoryId: null
            })
          }
          vendorProductCategoryId={showProductDocumentModal.vendorProductCategoryId}
          projectProductCategoryId={showProductDocumentModal.projectProductCategoryId}
          fetchParent={fetchParent}
        />
      )}
      {showDispatchInfoModal.open && (
        <DispatchInfoModal
          open={showDispatchInfoModal.open}
          projectProductCategoryId={showDispatchInfoModal.projectProductCategoryId}
          updateApi={showDispatchInfoModal.updateApi}
          getApi={showDispatchInfoModal.getApi}
          dispatchReceiptApi={showDispatchInfoModal.dispatchReceiptApi}
          fetchParent={fetchParent}
          modalTitle={showDispatchInfoModal.modalTitle}
          onClose={() => {
            setShowDispatchInfoModal({
              open: false,
              projectProductCategoryId: null,
              updateApi: null,
              dispatchReceiptApi: null,
              getApi: null,
              modalTitle: ""
            });
          }}
        />
      )}
    </div>
  );
};

export default RFSDetails;
