import { Route } from "react-router-dom";
import QueriesListSection from "./QueriesList";
import QueryDetails from "./QueryDetails";

const routes = [
  {
    route: "",
    Component: QueriesListSection
  },
  {
    route: "id/:id",
    Component: QueryDetails
  }
];

export default () =>
  routes.map(({ Component, route }) => <Route key={route} path={route} element={<Component />} />);
