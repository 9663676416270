import { Button, IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SupplierSampleDocumentUploadComponent = () => {
  return (
    <div className="flex flex-row items-center justify-start space-x-3 mb-3">
      <div className="w-[70px]">
        <Typography>COA</Typography>
      </div>
      <Button fullWidth variant="contained" component="label">
        Upload File
        <input type="file" hidden />
      </Button>
      <IconButton onClick={() => {}}>
        <HighlightOffIcon />
      </IconButton>
    </div>
  );
};

export default SupplierSampleDocumentUploadComponent;
