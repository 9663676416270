import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { axiosInstance } from "../../core/apiInstance";
import SelectProductCategoryModal from "./SelectProductCategoryModal";

const SelectProductModal = ({ open, onClose, onSelect, showAddSingleProduct, name }) => {
  const [searchText, setSearchText] = useState(name);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState();

  useEffect(() => {
    handleCategoryChange(0, null);
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [searchText]);

  const handleCategoryChange = (id, index) => {
    let categoryId = null;
    let newSelectedCategoryId = [...selectedCategoryId];
    newSelectedCategoryId[index] = id;
    if (id === selectedCategoryId[index]) {
      newSelectedCategoryId = newSelectedCategoryId.slice(0, index);
      categoryId = newSelectedCategoryId[newSelectedCategoryId.slice(0, index).length - 1];
    } else {
      newSelectedCategoryId = newSelectedCategoryId.slice(0, index + 1);
      categoryId = newSelectedCategoryId[index];
    }

    setSelectedCategoryId([...newSelectedCategoryId]);

    axiosInstance
      .get("/api/categories", {
        params: {
          categoryId
        }
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (selectedCategoryId && selectedCategoryId.length === 1) {
      alert("Please select Applications (2nd dropdown)");
    } else {
      axiosInstance
        .get("/api/products", {
          params: {
            searchText,
            categoryId: selectedCategoryId[selectedCategoryId.length - 1]
          }
        })
        .then((response) => {
          setProducts(response.data.result);
        });
    }
  };

  const handleOnProductSelectInComp = (product) => {
    setSelectedProductCategory(product);
    console.log(product);
    // handleClose();
    // handleOnProductSelect(product);
  };

  const handleOnSelectProduct = (product) => {
    onSelect(product);
    onClose();
  };

  const showProductVariants = (product) => {
    axiosInstance
      .get("/api/products", {
        params: {
          parentId: product.id,
          noHierarchy: true,
          categoryId: product.id ? null : selectedCategoryId[selectedCategoryId.length - 1]
        }
      })
      .then((response) => {
        setProducts([
          ...products.slice(0, products.findIndex((p) => p.id === product.id) + 1),
          ...response.data.result.map((p) => ({ ...p, isParentOf: product })),
          ...products.slice(products.findIndex((p) => p.id === product.id) + 1)
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Select Product
              </Typography>
            </div>
            <Button variant="outlined" onClick={showAddSingleProduct}>
              Add product
            </Button>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[550px] flex">
          <div className="w-[100%]">
            <div>
              <TextField
                id="input-with-icon-textfield"
                label="Search"
                fullWidth
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                variant="standard"
              />
              <div className="">
                {categories.map((category, index) => (
                  <div key={category.name} className="mt-1">
                    {category.result.length > 0 && (
                      <FormControl key={category} fullWidth variant="standard">
                        <InputLabel>{category.name}</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={category?.result.find((item) => item.checked)?.id || ""}
                          onChange={(e) => handleCategoryChange(e.target.value, index)}
                          label="Age">
                          {category.result.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <Button fullWidth color="primary" variant="outlined" onClick={handleSubmit}>
                  SEARCH
                </Button>
              </div>
            </div>
            <div className="mt-5 overflow-y-auto h-[350px] flex-wrap gap-4">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="flex w-full flex-col items-start rounded shadow-md min-[200px]:p-2 sm:p-2 lg:p-3">
                  {product.isParentOf && (
                    <h5 className="text-sm font-bold italic">
                      Child Of: {product.isParentOf?.name}
                    </h5>
                  )}
                  <h5 className="text-sm font-bold">{product.uid}</h5>
                  <h2 className="my-1 text-left text-base font-medium capitalize">
                    {product.isParentOf ? product.full_name : product.name}
                  </h2>
                  <p className="text-left text-sm">{product.description}</p>
                  <div className="flex flex-row">
                    {product.is_parent && (
                      <Button
                        size="small"
                        onClick={() => showProductVariants(product)}
                        variant="outlined">
                        Show Variants
                      </Button>
                    )}
                    {product.isParentOf && (
                      <Button
                        size="small"
                        onClick={() => handleOnProductSelectInComp(product)}
                        variant="outlined">
                        Select Category
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {Boolean(selectedProductCategory) && (
          <SelectProductCategoryModal
            open={Boolean(selectedProductCategory)}
            onClose={() => setSelectedProductCategory(false)}
            onSelect={handleOnSelectProduct}
            product={selectedProductCategory}
            categoryId={selectedCategoryId[selectedCategoryId.length - 1]}
          />
        )}
      </div>
    </Modal>
  );
};

export default SelectProductModal;
