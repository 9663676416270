import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../core/apiInstance";
import { useEffect, useState, useRef } from "react";
import { useAuthStore } from "../state/auth.state";
import useChatScroll from "../core/hooks/useChatScroll";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";

const TicketsModal = ({ open, onClose, id, userTeam }) => {
  let [ticket, setTicket] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    tickets: []
  });
  const [isClosedTicket, setIsCloseTicket] = useState(false);
  const { user } = useAuthStore();
  const ref = useChatScroll(data.tickets);

  const interval = useRef(null);

  useEffect(() => {
    getTickets();
    interval.current = window.setInterval(() => getTickets(), 5000);
    return () => {
      window.clearInterval(interval.current);
    };
  }, []);

  const getTickets = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/ppm/project-actions/project-products-categories/${id}/tickets`,
        { user_id: user.id }
      );

      setIsCloseTicket(!response.data.is_ticket_open);
      setData(response.data);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const addTicket = async () => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${id}/open-tickets`,
        { user_id: user.id, user_team: userTeam }
      );
      getTickets();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendTicket = async (e) => {
    e.preventDefault();

    setLoading(true);
    const regex = new RegExp(/(Share Product details|Share Order Quantity)/g);
    const putOnHold = regex.test(ticket);
    try {
      const response = await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${id}/tickets`,
        { ticket, user_id: user.id, user_team: userTeam },
        {
          params: { putOnHold: putOnHold }
        }
      );
      setTicket("");
      console.log(response);
      getTickets();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleCloseTicket = async () => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${id}/close-tickets`,
        { user_id: user.id, user_team: userTeam }
      );
      getTickets();
    } catch (err) {
      console.log(err);
    }
  };

  console.log(isLoading);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Tickets - Project Product Id- {id}
              </Typography>
            </div>
            {!isClosedTicket && user.id == data.ticket_created_by_user_id && (
              <Button variant="outlined" onClick={handleCloseTicket}>
                Close Ticket
              </Button>
            )}
            {data && data.is_ticket_open === false && data.tickets.length > 0 && (
              <Button variant="outlined" onClick={addTicket}>
                Reopen Ticket
              </Button>
            )}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[400px]">
          <div className="flex flex-col justify-end">
            <Box flex="1" overflow="auto">
              {isClosedTicket && data.tickets.length === 0 && (
                <div className="flex flex-col flex-1 justify-center items-center h-[350px]">
                  <Typography variant="h6" component="h3">
                    No tickets added yet
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addTicket}
                    className="ml-[20px]">
                    Create Ticket
                  </Button>
                </div>
              )}
              {data.tickets.length > 0 && (
                <div ref={ref} className="h-[310px] overflow-y-auto">
                  <Grid container direction="column" spacing={2} alignItems="flex-start">
                    {data &&
                      data.tickets.map((ticket) => (
                        <Grid key={ticket.id} item>
                          <Box border={1} px={2} py={1} bgcolor="white" borderRadius="md">
                            <Typography variant="body2" color="text.primary">
                              {ticket.user_team} - {ticket.ticket} -
                              <span className=" font-light">
                                at {dayjs(ticket.created_at).format("hh:mm A DD/MM/YYYY")}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              )}
            </Box>
          </div>

          {!isClosedTicket && (
            <div className="absolute bottom-0 left-0 w-full">
              <Box px={2} borderTop="1px solid" borderColor="divider">
                <div className="flex flex-row justify-center space-x-3 ">
                  {["Share Product details", "Share Order Quantity"].map((item) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      onChange={(e) =>
                        setTicket(() => {
                          if (e.target.checked) {
                            return ticket + e.target.value;
                          } else {
                            return ticket.replace(e.target.value, "");
                          }
                        })
                      }
                      control={<Checkbox />}
                      label={item}
                      labelPlacement={item}
                    />
                  ))}
                </div>
                <div className="pb-2">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <TextField
                        placeholder="Type your message..."
                        variant="outlined"
                        fullWidth
                        value={ticket}
                        size="small"
                        onChange={(e) => setTicket(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={handleSendTicket}>
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TicketsModal;
