import { create } from "zustand";

const defaultState = { severity: "success", message: "", open: false };

let timeout;
const useShowAlertStore = create((set) => ({
  alertState: defaultState,
  showAlert: (data) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      console.log("closing alert");
      return set((state) => ({ ...state, alertState: { ...defaultState } }));
    }, 4000);

    set((state) => ({ ...state, alertState: { ...data } }));
  }
}));

export { useShowAlertStore };
