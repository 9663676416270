import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { Button, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DispatchInfoModal from "../../../Dashboard/Supply/common/DispatchInfoModal";
import { axiosInstance } from "../../../../core/apiInstance";
import dayjs from "dayjs";
import { dateFormat } from "../../../../core/constants";

const Logistics = () => {
  const [showDispatchInfoModal, setShowDispatchInfoModal] = useState({
    open: false,
    updateApi: null,
    dispatchReceiptApi: null,
    getApi: null,
    modalTitle: ""
  });
  const [data, setData] = useState([]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50
    },
    {
      field: "account",
      headerName: "Account",
      width: 200
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 300
    },
    {
      field: "logistics_type",
      headerName: "Logistics Type",
      width: 250,
      renderCell: (params) => {
        return params.row.logistics_type ? <div>{params.row.logistics_type}</div> : null;
      }
    },
    {
      field: "po_date",
      headerName: "P.O. Date",
      width: 100,
      renderCell: (params) => {
        return params.row.po_date ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.po_date).format(dateFormat)}
          </div>
        ) : null;
      }
    },
    {
      field: "expected_delivery_date",
      headerName: "Expected Delivery Date",
      width: 200,
      renderCell: (params) => {
        return params.row.expected_delivery_date ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.expected_delivery_date).format(dateFormat)}
          </div>
        ) : null;
      }
    },
    {
      field: "logistics_info_pss",
      headerName: "PSS Logistics Info",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="flex space-x-3">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                setShowDispatchInfoModal({
                  open: true,
                  updateApi:
                    params.row.logistics_type === "BULK"
                      ? `/api/ppm/projects-products-categories/bulk-supplier-to-covvalent/${params.row.id}`
                      : `/api/ppm/projects-products-categories/pss-supplier-to-covvalent/${params.row.id}`,
                  modalTitle:
                    params.row.logistics_type === "BULK"
                      ? "SUPPLIER TO COVVALENT BULK INFO"
                      : "SUPPLIER TO COVVALENT PSS INFO",
                  id: params.row.id
                });
              }}>
              {params.row.logistics_type === "BULK"
                ? "BULK SUPPLIER TO COVVALENT"
                : "PSS SUPPLIER TO COVVALENT"}
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                setShowDispatchInfoModal({
                  open: true,
                  updateApi:
                    params.row.logistics_type === "BULK"
                      ? `/api/ppm/projects-products-categories/bulk-covvalent-to-buyer/${params.row.id}`
                      : `/api/ppm/projects-products-categories/pss-covvalent-to-buyer/${params.row.id}`,
                  modalTitle:
                    params.row.logistics_type === "BULK"
                      ? "COVVALENT TO BUYER BULK INFO"
                      : "COVVALENT TO BUYER PSS INFO",
                  unloadingDateRequired: params.row.logistics_type === "BULK" ? true : false,
                  id: params.row.id
                });
              }}>
              {params.row.logistics_type === "BULK"
                ? "BULK COVVALENT TO BUYER"
                : "PSS COVVALENT TO BUYER"}
            </Button>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  const fetchDraftOrders = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/projects-products-categories/logistics");
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[calc(100vh-200px)]">
      <Typography variant="h5" gutterBottom>
        Logistics
      </Typography>
      <div className="w-full mb-4 mt-4">
        <div className="h-[calc(100vh-200px)] overflow-y-auto">
          <DataGrid
            rowSelection={false}
            rows={data}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            disableMultipleColumnsSorting
            hideFooter
            disableColumnMenu
            editMode="row"
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
          />
        </div>
      </div>
      {Boolean(showDispatchInfoModal.open) && (
        <DispatchInfoModal
          open={showDispatchInfoModal.open}
          onClose={() =>
            setShowDispatchInfoModal({
              open: false,
              updateApi: null,
              dispatchReceiptApi: null,
              getApi: null,
              modalTitle: ""
            })
          }
          dispatchReceiptApi={`/api/ppm/projects-products-categories/upload-receipt-pss-supplier-to-covvalent/${showDispatchInfoModal.id}`}
          updateApi={showDispatchInfoModal.updateApi}
          // TODO: make get api for receipt-pss-supplier-to-covvalent
          // getApi={}
          modalTitle={showDispatchInfoModal.modalTitle}
          fetchParent={fetchDraftOrders}
          unloadingDateRequired={showDispatchInfoModal?.unloadingDateRequired}
          id={showDispatchInfoModal.id}
        />
      )}
    </div>
  );
};

export default Logistics;
