/* eslint-disable no-undef */
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import * as yup from "yup";
import { axiosInstance } from "../../core/apiInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";

const ExternalQuotationPage = () => {
  const [units, setUnits] = useState([]);
  const [data, setData] = useState({});
  const { id } = useParams();

  const schema = yup.object().shape({
    valid_upto_date: yup.string().required("This field is required"),
    price_low: yup.number().typeError("This field is required").required("This field is required"),
    price_high: yup.number().typeError("This field is required").required("This field is required"),
    unit: yup.string().required("This field is required"),
    quantity: yup.number().typeError("This field is required").required("This field is required")
  });

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      unit: ""
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    handleFetchMetaData();
    fetchQuotationDetails();
  }, [id]);

  const handleFetchMetaData = async () => {
    try {
      const unitsResponse = await axiosInstance.get("/api/ppm/metadata/units");
      setUnits(unitsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchQuotationDetails = async () => {
    try {
      const response = await axiosInstance.get(`/api/external/quotation-cache-form/object/${id}`);
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleQuotationFormSubmit = async (data) => {
    try {
      const response = await axiosInstance.post(
        `/api/external/quotation-cache-form/object/${id}`,
        data
      );
      if (response.data.is_completed) {
        setData({
          is_completed: true
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="absolute h-full w-full flex bg-gray-100 justify-center">
      <Header />
      {!data?.is_completed && (
        <div className="w-[720px] min-[200px]:w-full sm:w-full md:w-[450px] lg:w-[750px] absolute h-full bg-white">
          <div className="mt-[70px] p-[30px]">
            <Typography variant="h5" component="h2">
              Quotation Form
            </Typography>
            <div className="mt-5">
              <Typography variant="body1">
                Hi {data?.point_of_contact_name}, We need you to fill quotation for the following
                product{" "}
              </Typography>
            </div>
            <div className="my-2">
              <Typography variant="h6" component="h5">
                Product details
              </Typography>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Product name:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.product_name}
              </Typography>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Description:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.description || "No description provided"}
              </Typography>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Category:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.categories?.map((category) => category.category_name).join(" -> ")}
              </Typography>
            </div>
            <div className="mt-2 mb-0">
              <Typography variant="h6" component="h5">
                Quotation
              </Typography>
            </div>
            <form onSubmit={handleSubmit((data) => handleQuotationFormSubmit(data))}>
              <div className="flex flex-row gap-4 items-end">
                <Typography sx={{ width: 1 }} variant="subtitle1">
                  {"Required Quantity >"}
                </Typography>
                <TextField
                  {...register("quantity")}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Quantity"
                  variant="standard"
                  error={Boolean(errors.quantity?.message)}
                />
                <FormControl fullWidth variant="standard" error={Boolean(errors.unit?.message)}>
                  <InputLabel>Unit</InputLabel>
                  <Select
                    {...register("unit")}
                    labelId="demo-simple-select-standard-label"
                    value={watch("unit")}
                    label="Unit">
                    {units.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h6">=</Typography>
                <TextField
                  {...register("price_low")}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Price"
                  variant="standard"
                  type={"number"}
                  error={Boolean(errors.price_low?.message)}
                />
                <Typography variant="subtitle1">/KG</Typography>
              </div>
              <div className="flex flex-row gap-4 items-end">
                <Typography sx={{ width: 1 }} variant="subtitle1">
                  {"Required Quantity <"}
                </Typography>
                <TextField
                  disabled
                  value={watch("quantity")}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Quantity"
                  variant="standard"
                  error={Boolean(errors.quantity?.message)}
                />
                <FormControl fullWidth variant="standard" value={watch("unit")}>
                  <InputLabel>Unit</InputLabel>
                  <Select
                    disabled
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={watch("unit")}
                    label="Unit">
                    {units.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h6">=</Typography>
                <TextField
                  {...register("price_high")}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Price"
                  variant="standard"
                  type={"number"}
                  error={Boolean(errors.price_high?.message)}
                />
                <Typography variant="subtitle1">/KG</Typography>
              </div>
              <div className="my-2 mt-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="valid_upto_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disablePast
                        value={value}
                        onChange={onChange}
                        name="valid_upto_date"
                        label="Valid Upto Date"
                      />
                    )}
                  />
                  {errors.valid_upto_date?.message && (
                    <FormHelperText error>{errors.valid_upto_date?.message}</FormHelperText>
                  )}
                </LocalizationProvider>
              </div>
              <div className="my-5">
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      {data?.is_completed && (
        <div className="absolute h-full w-full bg-white mt-[70px] z-10 p-[30px] flex flex-col justify-center items-center">
          <img
            className="h-[65px]"
            src={`${process.env.PUBLIC_URL}/assets/images/green-tick.png`}
            alt=""
          />
          <div className="mt-10">
            <Typography variant="h5" component="h2">
              Thank you for submitting the quotation
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalQuotationPage;
