export const segments = {
  VENDOR_DEVELOPMENT: "Developing new vendors",
  SUPPLY_TEAM_APPROVAL: "Approval from supply team",
  GETTING_QUOTES: "Getting quotes (mail sent)",
  PRICE_READY_PENDING_OTHER_VENDORS_QUOTATION: "Price ready (waiting other vendor quotation)",
  PRICE_READY_FINAL: "Price Ready",
  FINAL_SUPPLIER_PRICE_AVAILABLE: "Finalized supplier and prices",
  FINALIZE_SUPPLIER_GET_QUOTES: "Finalize supplier & get quotes",
  NEW_VENDOR_DEVELOPMENT: "New Vendor Development",
  FINALIZE_SUPPLIER_ADD_DATA_POINT: "Finalize supplier & add data point"
};

export const demandStatus = {
  FINAL_SUPPLIER_PRICE_AVAILABLE: "Ready",
  FINALIZE_SUPPLIER_ADD_DATA_POINT: "In-Progress",
  NEW_VENDOR_DEVELOPMENT: "New Vendor Development"
};

// eslint-disable-next-line no-undef
const isDevelopment = process.env.NODE_ENV === "development";
export const dueDateTimeFormat = isDevelopment ? "YYYY-MM-DD HH:mm A" : "YYYY-MM-DD";
export const dateFormat = "YYYY-MM-DD";
