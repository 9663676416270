import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import * as dayjs from "dayjs";
import { axiosInstance } from "../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import AddQuotationModal from "../../Orders/common/AddQuotationModal";
import AddUpdateIncotermModal from "../../../../components/AddUpdateIncotermModal";
import SelectProductModal from "../../../../components/SelectProductModal";
import ProductDocumentModal from "../../../../components/ProductDocumentModal";

const VendorProductsModal = ({ open, handleClose, id }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [showAddQuotationModal, setShowAddQuotationModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  const [showIncotermModal, setShowIncotermModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  const [showProductDocumentsModal, setProductDocumentsModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  // const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      handleFetchVendorProducts(id);
    }
  }, [id]);

  const handleFetchVendorProducts = (id) => {
    setLoading(true);
    axiosInstance
      .get(`/api/vendors/${id}/products`)
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleOnProductSelect = async (productCategory) => {
    setLoading(true);
    try {
      await axiosInstance.post(`/api/vendors/${id}/add-product/${productCategory.id}`);
      setLoading(false);
      handleFetchVendorProducts(id);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Product list
              </Typography>
            </div>
            <Button variant="outlined" onClick={() => setShowSelectProductModal(true)}>
              Add product
            </Button>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[400px]">
          <DataGrid
            loading={isLoading}
            hideFooter
            rows={data}
            rowCount={1000}
            paginationMode="server"
            disableColumnMenu
            getRowId={(row) => row.product_category_id}
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
            columns={[
              {
                field: "product_category_id",
                headerName: "Product category Id",
                sortable: false
              },
              {
                filed: "document_action",
                headerName: "Documents",
                sortable: false,
                width: 180,
                renderCell: (params) => {
                  return (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      component="label"
                      onClick={() =>
                        setProductDocumentsModal({
                          open: true,
                          vendorProductCategoryId: params.row.vendor_product_category_id
                        })
                      }>
                      View Documents
                    </Button>
                  );
                }
              },
              {
                field: "product_name",
                headerName: "Product name",
                sortable: false,
                minWidth: 200,
                maxWidth: 600
              },
              {
                field: "categories",
                headerName: "Categories",
                sortable: false,
                width: 300,
                renderCell: (params) => {
                  return (
                    <div className="w-full overflow-y-auto">
                      {params.row.categories.map((cat) => (
                        <label
                          key={cat.category_id}
                          className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                          {cat.category_name}
                        </label>
                      ))}
                    </div>
                  );
                }
              },
              {
                field: "product_description",
                headerName: "Product description",
                sortable: false,
                width: 200
              },
              {
                field: "valid_upto_date",
                headerName: "Quotation Valid upto",
                width: 200,
                renderCell: (params) => {
                  return (
                    <div className="w-full overflow-y-auto">
                      {params.row.valid_upto_date
                        ? `${dayjs(params.row.valid_upto_date).diff(
                            new Date(),
                            "day",
                            false
                          )} days left`
                        : ""}
                    </div>
                  );
                }
              },
              {
                field: "action_2",
                headerName: "Add/Update Quotation",
                width: 200,
                renderCell: (params) => {
                  return (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      component="label"
                      onClick={() =>
                        setShowAddQuotationModal({
                          open: true,
                          vendorProductCategoryId: params.row.vendor_product_category_id
                        })
                      }>
                      {params.row.valid_upto_date ? "Update" : "Add"} Quotation
                    </Button>
                  );
                }
              },
              {
                field: "incoterm",
                headerName: "Incoterm",
                width: 200,
                renderCell: (params) => {
                  return <div className="w-full overflow-y-auto">{params.row.incoterm || ""}</div>;
                }
              },
              {
                field: "action_3",
                headerName: "Add/Update Incoterm",
                width: 200,
                renderCell: (params) => {
                  return (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      component="label"
                      onClick={() =>
                        setShowIncotermModal({
                          open: true,
                          vendorProductCategoryId: params.row.vendor_product_category_id
                        })
                      }>
                      {params.row.incoterm ? "Update" : "Add"} Incoterm
                    </Button>
                  );
                }
              }
            ]}
          />
        </div>
        {showSelectProductModal && (
          <SelectProductModal
            open={showSelectProductModal}
            onClose={() => setShowSelectProductModal(false)}
            onSelect={handleOnProductSelect}
          />
        )}
        {Boolean(showAddQuotationModal.open) && (
          <AddQuotationModal
            open={showAddQuotationModal.open}
            onClose={() => setShowAddQuotationModal({ open: false, vendorProductCategoryId: null })}
            vendorProductCategoryId={showAddQuotationModal.vendorProductCategoryId}
            fetchParent={() => handleFetchVendorProducts(id)}
          />
        )}
        {Boolean(showIncotermModal.open) && (
          <AddUpdateIncotermModal
            open={showIncotermModal.open}
            onClose={() => setShowIncotermModal({ open: false, vendorProductCategoryId: null })}
            vendorProductCategoryId={showIncotermModal.vendorProductCategoryId}
            fetchParent={() => handleFetchVendorProducts(id)}
          />
        )}
        {Boolean(showProductDocumentsModal.open) && (
          <ProductDocumentModal
            open={open}
            onClose={() => setProductDocumentsModal({ open: false, vendorProductCategoryId: null })}
            vendorProductCategoryId={showProductDocumentsModal.vendorProductCategoryId}
            fetchParent={() => handleFetchVendorProducts(id)}
          />
        )}
      </div>
    </Modal>
  );
};

export default VendorProductsModal;
