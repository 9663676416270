import { Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../../../core/apiInstance";

const CreateCompareModal = ({ open, onClose, fetchParent }) => {
  const { register, watch, setValue, handleSubmit } = useForm();

  const handleSelectFile = (e, type) => {
    if (e.target.files.length === 0) return;
    setValue(`input_${type}`, e.target.files[0]);
  };

  const handleCompareSubmit = async (value) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file", value.input_a);

      const responseA = await axiosInstance.post(
        "/api/ppm/tds-comparison/upload-tds",
        bodyFormData
      );

      bodyFormData.delete("file");
      bodyFormData.append("file", value.input_b);

      const responseB = await axiosInstance.post(
        "/api/ppm/tds-comparison/upload-tds",
        bodyFormData
      );

      const compareResponse = await axiosInstance.post("/api/ppm/tds-comparison", {
        name: value.name,
        input_a: responseA.data.documentLink,
        input_b: responseB.data.documentLink
      });

      console.log(compareResponse);
      onClose();
      fetchParent && fetchParent();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Create Compare
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[400px] h-[250px]">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleCompareSubmit)}>
            <TextField
              {...register("name")}
              fullWidth
              id="outlined-multiline-static"
              label="Name"
            />
            <Button fullWidth color="primary" variant="outlined" component="label">
              {watch("input_a") ? watch("input_a").name : "Select Client TDS"}
              <input type="file" hidden onChange={(e) => handleSelectFile(e, "a")} />
            </Button>
            <Button fullWidth color="primary" variant="outlined" component="label">
              {watch("input_b") ? watch("input_b").name : "Select Supplier TDS"}
              <input type="file" hidden onChange={(e) => handleSelectFile(e, "b")} />
            </Button>

            <Button type="submit" fullWidth color="primary" variant="contained">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCompareModal;
