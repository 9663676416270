import { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { Button, LinearProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import CreateCompareModal from "./CreateCompareModal";
import { saveData } from "../../../../core/utilsFunctions";

const TDSCompareSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showTDSCompareModal, setShowTDSCompareModal] = useState(false);

  const handleDownloadOutput = (name, id) => {
    axiosInstance
      .get(`/api/ppm/tds-comparison/object/${id}/download-output-file`)
      .then((response) => {
        saveData(response.data, `${name}-output.csv`);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80
    },
    {
      field: "name",
      headerName: "Document's name",
      width: 250
    },
    {
      field: "input_a",
      headerName: "Client TDS",
      width: 250,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.row.input_a)}>
            Download Client TDS
          </Button>
        );
      }
    },
    {
      field: "input_b",
      headerName: "Supplier TDS",
      width: 250,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.row.input_b)}>
            Download Supplier TDS
          </Button>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150
    },
    {
      field: "output",
      headerName: "Output file",
      width: 150,
      renderCell: (params) => {
        return params.row.output ? (
          <Button
            variant="contained"
            color="primary"
            label="Doc Type"
            onClick={() => handleDownloadOutput(params.row.name, params.row.id)}>
            Download
          </Button>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    },
    {
      field: "started_at",
      headerName: "Started at",
      width: 150,
      renderCell: (params) => {
        return params.row.started_at ? (
          <Typography variant="body2" color="textSecondary">
            {dayjs(params.row.started_at).format("hh:mm A DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    },
    {
      field: "completed_at",
      headerName: "Completed at",
      width: 150,
      renderCell: (params) => {
        return params.row.completed_at ? (
          <Typography variant="body2" color="textSecondary">
            {dayjs(params.row.completed_at).format("hh:mm A DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    }
  ];

  const interval = useRef(null);

  useEffect(() => {
    fetchAllDocuments();
    interval.current = window.setInterval(() => fetchAllDocuments(), 10000);
    return () => {
      window.clearInterval(interval.current);
    };
  }, []);

  const fetchAllDocuments = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/ppm/tds-comparison");
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          TDS Comparison
        </Typography>
        <div className="ml-5">
          <div className="flex items-center gap-3">
            <Button
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => setShowTDSCompareModal(true)}>
              New Compare
            </Button>
          </div>
        </div>
      </div>
      <div className="h-full">
        <DataGrid
          loading={loading}
          rowSelection={false}
          disableColumnMenu
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
          rows={data}
          columns={columns}
        />
        {showTDSCompareModal && (
          <CreateCompareModal
            fetchParent={fetchAllDocuments}
            open={showTDSCompareModal}
            onClose={() => setShowTDSCompareModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TDSCompareSection;
