import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, LinearProgress } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import SelectSupplierModal from "../../Supply/common/SelectSupplierModal";
import ProductDocumentModal from "../../../../components/ProductDocumentModal";
import AddUpdateIncotermModal from "../../../../components/AddUpdateIncotermModal";
import AddQuotationModal from "../../Orders/common/AddQuotationModal";
import { useAuthStore } from "../../../../state/auth.state";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../../../core/apiInstance";
import { useShowAlertStore } from "../../../../state/alert.state";
import AddSupplierModal from "../commons/AddSupplierModal";
import DispatchInfoModal from "../../Supply/common/DispatchInfoModal";
import CustomizationEquivalentDateModal from "./DateModal";
import { dateFormat } from "../../../../core/constants";

// eslint-disable-next-line no-undef
const isDevelopment = process.env.NODE_ENV === "development";

const RFQDetailsCustomized = ({ rfqData, onMarkAsRegret, onMarkAsNVD, fetchParent }) => {
  const [showSelectSupplierModal, setShowSelectSupplierModal] = useState({
    open: false,
    projectsProductsCategoriesId: null,
    vendorProductCategoryId: null,
    segment: null
  });
  const [showAddQuotationModal, setShowAddQuotationModal] = useState({
    id: null,
    activityId: null,
    vendorProductCategoryId: null,
    open: false
  });
  const [showProductDocumentsModal, setProductDocumentsModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  const [showIncotermModal, setShowIncotermModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  const [showDispatchInfoModal, setShowDispatchInfoModal] = useState({
    open: false,
    projectProductCategoryId: null,
    updateApi: null,
    getApi: null,
    modalTitle: ""
  });

  const [showCustomizationEquivalentDateModal, setShowCustomizationEquivalentDateModal] = useState({
    open: false,
    projectProductCategoryId: null,
    updateApi: null,
    getApi: null,
    modalTitle: ""
  });

  const [showAddSupplierModal, setShowAddSupplierModal] = useState({
    open: false,
    projectsProductsCategoriesId: null,
    vendorProductCategoryId: null,
    segment: null
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const { showAlert } = useShowAlertStore();
  const { user } = useAuthStore();
  const isAdmin =
    user && user.userTeams && user.userTeams.find((item) => item.team_name === "ADMIN");

  const dueDateTimeFormat = isDevelopment ? "YYYY-MM-DD HH:mm A" : "YYYY-MM-DD";

  let columns = [
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,

      renderCell: (params) => {
        return params.row.created_at ? (
          <div>{dayjs(params.row.created_at).format(dueDateTimeFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "nvd",
      headerName: "NVD",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            disabled={params.row.raise_as_new_vendor_development}
            onClick={() => onMarkAsNVD(params.row.id)}>
            {params.row.is_new_vendor_development
              ? "NVD Approved"
              : params.row.raise_as_new_vendor_development
              ? "Raised NVD to Admin"
              : "Mark as NVD"}
          </Button>
        );
      }
    },
    {
      field: "regret",
      headerName: "Regret",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          params.row.product_name && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              disabled={params.row.raise_as_regret}
              onClick={() => onMarkAsRegret(params.row.id)}>
              {params.row.is_regret_product
                ? "Regret Approved"
                : params.row.raise_as_regret
                ? "Raised Regret to Admin"
                : "Mark as regret"}
            </Button>
          )
        );
      }
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 200,
      renderCell: (params) => {
        return params.row.due_date ? dayjs(params.row.due_date).format(dueDateTimeFormat) : "";
      }
    },
    {
      field: "view_suppliers",
      headerName: "View Suppliers",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowAddSupplierModal({
                open: true,
                customization: true,
                projectsProductsCategoriesId: params.row.id
              })
            }>
            View Supplier
          </Button>
        );
      }
    },
    {
      field: "selected_supplier_name",
      headerName: "Selected Supplier",
      width: 200,
      renderCell: (params) => {
        return params.row.vendor_name ? <div>{params.row.vendor_name}</div> : "";
      }
    },
    {
      field: "covvalent_to_supplier_dispatch_info",
      headerName: "Covvalent to Supplier Dispatch Info",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            disabled={!params.row.vendor_name}
            variant="outlined"
            color="primary"
            onClick={() =>
              setShowDispatchInfoModal({
                open: true,
                projectProductCategoryId: params.row.id,
                updateApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/update-sample-covvalent-to-supplier-dispatch-info`,
                getApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/sample-covvalent-to-supplier-dispatch-info`,
                dispatchReceiptApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/upload-dispatch-document/covvalent_to_supplier_dispatch_info`,
                modalTitle: "Sample Dispatch Info - [Covvalent-to-Supplier]"
              })
            }>
            View
          </Button>
        );
      }
    },
    {
      field: "sample_equivalent_making_date",
      headerName: "Expected Date To Make Equivalent Sample",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            disabled={!params.row.vendor_name}
            variant="outlined"
            color="primary"
            onClick={() =>
              setShowCustomizationEquivalentDateModal({
                open: true,
                projectProductCategoryId: params.row.id,
                updateApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/update-customized-sample-expected-making-date`,
                getApi: `/api/ppm/project-actions/project-products-categories/${params.row.id}/customized-sample-expected-making-date`,
                modalTitle: "Supplier Expected Date To Make Equivalent Sample"
              })
            }>
            {params.row.sample_equivalent_making_date
              ? dayjs(params.row.sample_equivalent_making_date).format(dateFormat)
              : "Add"}
          </Button>
        );
      }
    },
    {
      field: "supplier_document_upload",
      headerName: "Supplier Document Upload",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            disabled={!params.row.vendor_name}
            variant="outlined"
            color="primary"
            onClick={() =>
              setProductDocumentsModal({
                vendorProductCategoryId: params.row.vendors_products_category_id,
                projectProductCategoryId: params.row.id,
                open: true
              })
            }>
            View Documents
          </Button>
        );
      }
    },
    {
      field: "assigned_to_qa_at",
      headerName: "Request For QA Date",
      width: 170,
      renderCell: (params) => {
        return params.row.assigned_to_qa_at ? (
          <div>{dayjs(params.row.assigned_to_qa_at).format(dueDateTimeFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "assigned_to_qa",
      headerName: "Approval From QA",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.assigned_to_qa_at
              ? params.row.is_qa_approved_supplier_documents === true
                ? "Passed"
                : "Pending"
              : ""}
          </div>
        );
      }
    },
    {
      field: "add_quotation",
      headerName: "Add Quotation",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            disabled={!params.row.vendor_name}
            variant="contained"
            onClick={() => {
              console.log(
                "params.row.vendors_products_categories_id",
                params.row.vendors_products_categories_id
              );
              setShowAddQuotationModal({
                open: true,
                id: params.row.id,
                activityId: null,
                vendorProductCategoryId: params.row.vendors_products_category_id
              });
            }}>
            ADD QUOTATION
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    console.log(rfqData);
    if (searchParams.get("supplyTeam") === null && !isAdmin) {
      handleChangeSearchParams("supplyTeam", user.id);
    }
  }, [rfqData]);

  const handleChangeSearchParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSelectVendor = async (projectsProductsCategoriesId, vendorProductCategoryId) => {
    try {
      await axiosInstance.post(
        `/api/ppm/projects/project-product-category/${projectsProductsCategoriesId}/move-to-final`,
        { vendorProductCategoryId }
      );
      fetchParent && fetchParent();
      setShowSelectSupplierModal({
        open: false,
        status: null,
        projectsProductsCategoriesId: null,
        vendorProductCategoryId: null
      });
      showAlert({ open: true, message: "Quotes Submitted TO DEMAND", severity: "success" });
    } catch (err) {
      console.log(vendorProductCategoryId);
    }
  };

  const handleRefreshSelectSupplier = () => {
    setShowSelectSupplierModal({
      ...showSelectSupplierModal,
      refresh: !showSelectSupplierModal.refresh
    });
    fetchParent();
  };

  return (
    <div className="h-[400px] w-full mb-4">
      <DataGrid
        rowSelection={false}
        rows={rfqData}
        columns={columns}
        //loading={loading}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        // onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showSelectSupplierModal.open) && (
        <SelectSupplierModal
          open={showSelectSupplierModal.open}
          onClose={() =>
            setShowSelectSupplierModal({
              open: false,
              projectsProductsCategoriesId: null,
              vendorProductCategoryId: null,
              status: null
            })
          }
          vendorProductCategoryId={showSelectSupplierModal.vendorProductCategoryId}
          projectsProductsCategoriesId={showSelectSupplierModal.projectsProductsCategoriesId}
          fetchParent={fetchParent}
          setProductDocumentsModal={setProductDocumentsModal}
          setShowIncotermModal={setShowIncotermModal}
          supplyTeam={isAdmin ? searchParams.get("supplyTeam") : user.id}
          segment={showSelectSupplierModal.segment}
          setShowAddQuotationModal={setShowAddQuotationModal}
          handleSelectVendor={handleSelectVendor}
          refresh={showSelectSupplierModal.refresh}
        />
      )}
      {Boolean(showAddQuotationModal.open) && (
        <AddQuotationModal
          fetchParent={handleRefreshSelectSupplier}
          open={showAddQuotationModal.open}
          id={showAddQuotationModal.id}
          vendorProductCategoryId={showAddQuotationModal.vendorProductCategoryId}
          activityId={showAddQuotationModal.activityId}
          query={{ isCustomizationQuotation: true }}
          onClose={() =>
            setShowAddQuotationModal({
              open: false,
              id: null,
              activityId: null,
              vendorProductCategoryId: null
            })
          }
        />
      )}
      {Boolean(showIncotermModal.open) && (
        <AddUpdateIncotermModal
          open={showIncotermModal.open}
          onClose={() => setShowIncotermModal({ open: false, vendorProductCategoryId: null })}
          vendorProductCategoryId={showIncotermModal.vendorProductCategoryId}
          fetchParent={handleRefreshSelectSupplier}
        />
      )}
      {Boolean(showProductDocumentsModal.open) && (
        <ProductDocumentModal
          open={open}
          onClose={() =>
            setProductDocumentsModal({
              open: false,
              vendorProductCategoryId: null,
              projectProductCategoryId: null
            })
          }
          vendorProductCategoryId={showProductDocumentsModal.vendorProductCategoryId}
          projectProductCategoryId={showProductDocumentsModal.projectProductCategoryId}
          fetchParent={handleRefreshSelectSupplier}
        />
      )}
      {Boolean(showAddSupplierModal.open) && (
        <AddSupplierModal
          open={showAddSupplierModal.open}
          onClose={() =>
            setShowAddSupplierModal({
              open: false,
              projectsProductsCategoriesId: null,
              vendorProductCategoryId: null,
              status: null
            })
          }
          vendorProductCategoryId={showAddSupplierModal.vendorProductCategoryId}
          projectsProductsCategoriesId={showAddSupplierModal.projectsProductsCategoriesId}
          fetchParent={fetchParent}
          setProductDocumentsModal={setProductDocumentsModal}
          setShowIncotermModal={setShowIncotermModal}
          supplyTeam={isAdmin ? searchParams.get("supplyTeam") : user.id}
          segment={showAddSupplierModal.segment}
          setShowAddQuotationModal={setShowAddQuotationModal}
          handleSelectVendor={handleSelectVendor}
          refresh={showAddSupplierModal.refresh}
        />
      )}
      {showDispatchInfoModal.open && (
        <DispatchInfoModal
          open={showDispatchInfoModal.open}
          projectProductCategoryId={showDispatchInfoModal.projectProductCategoryId}
          updateApi={showDispatchInfoModal.updateApi}
          getApi={showDispatchInfoModal.getApi}
          dispatchReceiptApi={showDispatchInfoModal.dispatchReceiptApi}
          fetchParent={fetchParent}
          modalTitle={showDispatchInfoModal.modalTitle}
          onClose={() => {
            setShowDispatchInfoModal({
              open: false,
              projectProductCategoryId: null,
              dispatchReceiptApi: null,
              updateApi: null,
              getApi: null,
              modalTitle: ""
            });
          }}
        />
      )}
      {showCustomizationEquivalentDateModal.open && (
        <CustomizationEquivalentDateModal
          open={showCustomizationEquivalentDateModal.open}
          projectProductCategoryId={showCustomizationEquivalentDateModal.projectProductCategoryId}
          updateApi={showCustomizationEquivalentDateModal.updateApi}
          getApi={showCustomizationEquivalentDateModal.getApi}
          fetchParent={fetchParent}
          modalTitle={showCustomizationEquivalentDateModal.modalTitle}
          onClose={() => {
            setShowCustomizationEquivalentDateModal({
              open: false,
              projectProductCategoryId: null,
              updateApi: null,
              getApi: null,
              modalTitle: ""
            });
          }}
        />
      )}
    </div>
  );
};

export default RFQDetailsCustomized;
