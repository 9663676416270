import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";

const AssignToModal = ({
  open,
  onClose,
  fetchParent,
  projectsProductsCategoriesId,
  supplyTeamId
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    handleFetchSupplyTeamMembers();
  }, []);

  const handleFetchSupplyTeamMembers = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=SUPPLY");
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectSupplyTeamMember = async (id) => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/assign-to/supply-team/${id}`
      );
      fetchParent();
      onClose();
      console.log(id, supplyTeamId, projectsProductsCategoriesId);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Assign to
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[400px]">
          <DataGrid
            hideFooter
            rows={data}
            disableColumnMenu
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
            columns={[
              {
                field: "id",
                headerName: "id",
                sortable: false,
                width: 80
              },
              {
                field: "name",
                headerName: "Supply team member",
                sortable: false,
                width: 350
              },
              {
                field: "action",
                headerName: "Action",
                width: 200,
                renderCell: (params) => {
                  return (
                    <Button
                      fullWidth
                      disabled={supplyTeamId == params.row.id}
                      color="primary"
                      variant="contained"
                      component="label"
                      onClick={() => handleSelectSupplyTeamMember(params.row.id)}>
                      {supplyTeamId == params.row.id ? "Already assigned" : "Select"}
                    </Button>
                  );
                }
              }
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssignToModal;
