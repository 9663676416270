import { Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../../core/apiInstance";
import { demandStatus } from "../../../../core/constants";
import { saveData } from "../../../../core/utilsFunctions";
import TicketsModal from "../../../../components/TicketsModal";
import dayjs from "dayjs";
// import SummaryModal from "./SummaryModal";
import TrackStagesModal from "./TrackStagesModal";

const SummarySection = () => {
  const [data, setData] = useState({ products: [] });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    pagination: {
      pageSize: 10,
      page: 0
    },
    sort: []
  });
  const [searchParams, setSearchParams] = useSearchParams();

  let { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSummaryData();
  }, []);

  const fetchSummaryData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/ppm/projects/object/${projectId}/summary`);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleFilterChange = (type, model) => {
    if (type === "pagination") {
      setFilter((filter) => ({ ...filter, pagination: { ...model } }));
    } else if (type === "sort") {
      setFilter((filter) => ({ ...filter, sort: [model[0]] }));
    }
  };

  const handleSummaryCsv = () => {
    axiosInstance.post(`/api/ppm/projects/object/${projectId}/summary/csv`).then((res) => {
      saveData(res.data, `${data?.name}-summary.csv`);
    });
  };

  const handleOnEdit = () => {
    navigate(`/dashboard/orders/projects/action/edit/projectId/${projectId}`);
  };

  const handleChangeSearchParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleRemoveSearchParams = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const handlePutOnHold = async (ppcId) => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${ppcId}/put-on-hold`
      );
      fetchSummaryData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleResume = async (ppcId) => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${ppcId}/resume`
      );
      fetchSummaryData();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 80
    },
    {
      field: "track",
      headerName: "Track",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              handleChangeSearchParams("projectId", params.row.id);
            }}>
            VIEW
          </Button>
        ) : null;
      }
    },
    {
      field: "track_order",
      headerName: "Track Order",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            disabled={params.row.has_orders ? false : true}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              navigate(`orders/${params.row.id}`);
            }}>
            Track Order
          </Button>
        ) : null;
      }
    },
    {
      field: "action_ticket",
      headerName: "Tickets",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            endIcon={params.row.is_ticket_open ? <ErrorOutlineIcon /> : <div />}
            onClick={() => handleChangeSearchParams("ticketId", params.row.id)}>
            Tickets
          </Button>
        ) : null;
      }
    },
    {
      field: "action_on_hold",
      headerName: "On Hold Action",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.is_on_hold ? (
          <Button
            disabled={params.row.is_regret_product}
            color="primary"
            variant="outlined"
            onClick={() => handleResume(params.row.id)}>
            Resume
          </Button>
        ) : (
          <Button
            disabled={params.row.is_regret_product}
            color="primary"
            variant="outlined"
            onClick={() => handlePutOnHold(params.row.id)}>
            Put on hold
          </Button>
        );
      }
    },
    {
      field: "is_regret_product",
      headerName: "Regret Status",
      width: 100,
      renderCell: (params) => params.row.is_regret_product && <div>Regret</div>
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 150
    },
    {
      field: "product_name",
      headerName: "Product name",
      width: 200
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        );
      }
    },
    {
      field: "quoted_price",
      headerName: "Quotation cost price",
      width: 180,
      renderCell: (params) => {
        return params.row?.quoted_price ? (
          <div className="w-full overflow-y-auto">Rs.{params.row.quoted_price}/KG</div>
        ) : null;
      }
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80
    },
    {
      field: "unit",
      headerName: "Unit",
      sortable: false,
      width: 80
    },
    {
      field: "valid_upto_date",
      headerName: "Valid upto",
      width: 200,
      renderCell: (params) => {
        return params.row.valid_upto_date ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.valid_upto_date).diff(new Date(), "day", false)} days left
          </div>
        ) : (
          ""
        );
      }
    },
    {
      field: "supply_team_name",
      headerName: "Assigned to",
      sortable: false,
      width: 150
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return <div className="w-full overflow-y-auto">{demandStatus[params.row.status]}</div>;
      }
    },
    {
      field: "incoterm",
      headerName: "Incoterm",
      width: 200,
      renderCell: (params) => {
        return params.row.incoterm ? <div>{params.row.incoterm}</div> : <div>Not Available</div>;
      }
    },
    {
      field: "tds",
      headerName: "TDS document",
      width: 200,
      renderCell: (params) => {
        return params.row.tds ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => window.open(params.row.tds)}>
            Download TDS
          </Button>
        ) : (
          <div>Not Available</div>
        );
      }
    },
    {
      field: "msds",
      headerName: "MSDS document",
      width: 200,
      renderCell: (params) => {
        return params.row.msds ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => window.open(params.row.msds)}>
            Download MSDS
          </Button>
        ) : (
          <div>Not Available</div>
        );
      }
    },
    {
      field: "coa",
      headerName: "COA document",
      width: 200,
      renderCell: (params) => {
        return params.row.coa ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => window.open(params.row.coa)}>
            Download COA
          </Button>
        ) : (
          <div>Not Available</div>
        );
      }
    },
    {
      field: "price_added_at",
      headerName: "Price added at",
      width: 150
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 150
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 150
    }
    // {
    //   field: "valid_upto_date",
    //   headerName: "Valid upto",
    //   width: 200,
    //   renderCell: (params) => {
    //     const dateObj = new Date(params.row.valid_upto_date);
    //     const date = formatDistance(dateObj, new Date(), { addSuffix: true });
    //     return <div className="w-full overflow-y-auto">{date}</div>;
    //   }
    // }
  ];

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Summary
        </Typography>
        <div className="ml-5 flex items-center justify-between gap-3 w-full">
          <Button color="primary" variant="outlined" onClick={handleSummaryCsv}>
            Download CSV
          </Button>
          <Button color="primary" variant="outlined" onClick={handleOnEdit}>
            Edit
          </Button>
        </div>
      </div>
      <div className="h-full">
        <DataGrid
          rowSelection={false}
          rows={data.products}
          columns={columns}
          loading={loading}
          rowCount={502}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          paginationMode="server"
          paginationModel={filter.pagination}
          onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
          sortingMode="server"
          sortModel={filter.model}
          onSortModelChange={(sort) => handleFilterChange("sort", sort)}
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
        {Boolean(searchParams.get("ticketId")) && (
          <TicketsModal
            userTeam="DEMAND"
            open={searchParams.get("ticketId")}
            onClose={() => handleRemoveSearchParams("ticketId")}
            id={searchParams.get("ticketId")}
            fetchParent={fetchSummaryData}
          />
        )}
        {/* {Boolean(searchParams.get("projectId")) && (
          <SummaryModal
            open={searchParams.get("projectId")}
            onClose={() => handleRemoveSearchParams("projectId")}
            id={searchParams.get("projectId")}
            projectId={projectId}
          />
        )} */}
        {Boolean(searchParams.get("projectId")) && (
          <TrackStagesModal
            open={searchParams.get("projectId")}
            onClose={() => handleRemoveSearchParams("projectId")}
            id={searchParams.get("projectId")}
            projectId={projectId}
          />
        )}
      </div>
    </div>
  );
};

export default SummarySection;
