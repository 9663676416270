import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from "@mui/x-data-grid";

function DataGridCustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

export default DataGridCustomToolbar;
