import { Route } from "react-router-dom";
import AllProjectsSection from "./AllProjects";
import ProjectProfileSection from "./ProjectProfile";
import SummarySection from "./Summary";
import ProjectProductsOrders from "./ProjectProductsOrders";
import POAllOrders from "./POAllOrders";

const routes = [
  {
    route: "projects",
    Component: AllProjectsSection
  },
  {
    route: "projects/action/:action",
    Component: ProjectProfileSection
  },
  {
    route: "projects/action/:action/projectId/:projectId",
    Component: ProjectProfileSection
  },
  {
    route: "projects/summary/:projectId",
    Component: SummarySection
  },
  {
    route: "projects/summary/:projectId/orders/:orderId",
    Component: ProjectProductsOrders
  },
  {
    route: "po",
    Component: POAllOrders
  }
];

export default () =>
  routes.map(({ Component, route }) => <Route key={route} path={route} element={<Component />} />);
