import { Button, Card, CardContent, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { useNavigate } from "react-router-dom";

const ProjectsView = ({ accountManager, searchText }) => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, [accountManager, searchText]);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/projects", {
        params: {
          accountManager,
          searchText
        }
      });
      setProjects(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      await axiosInstance.delete(`/api/ppm/projects/object/${id}`);
      fetchProjects();
    } catch (err) {
      console.log(err);
    }
  };

  const handlePutOnHold = async (id) => {
    try {
      await axiosInstance.post(`/api/ppm/projects/object/${id}/put-on-hold`);
      fetchProjects();
    } catch (err) {
      console.log(err);
    }
  };

  const handleResume = async (id) => {
    try {
      await axiosInstance.post(`/api/ppm/projects/object/${id}/resume`);
      fetchProjects();
    } catch (err) {
      console.log(err);
    }
  };

  return projects.length === 0 ? (
    <div className="flex justify-between items-center">
      <Typography variant="h6" gutterBottom>
        No such projects found
      </Typography>
    </div>
  ) : (
    projects.map((item) => (
      <div key={item.id} className="mb-10">
        <Card>
          <CardContent>
            <div className="flex justify-between">
              <Typography variant="h6" gutterBottom>
                Company name: {item.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Created at: {dayjs(item.created_at).format("DD MMM YYYY")}
              </Typography>
            </div>
            <div className="flex justify-between pt-4">
              <Typography variant="body1" gutterBottom>
                POC name: {item.point_of_contact_name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                POC email: {item.point_of_contact_email}
              </Typography>
              <Typography variant="body1" gutterBottom>
                POC phone number: {item.point_of_contact_phone_number}
              </Typography>
            </div>
            {false && (
              <div className="flex justify-between pt-2">
                <Typography variant="body1" gutterBottom>
                  Product quotation available: 2/10
                </Typography>
              </div>
            )}
            <div className="flex justify-between pt-2">
              <Typography variant="body1" gutterBottom>
                Account Manager: {item.account_manager.name}
              </Typography>
            </div>
            <div className="flex pt-2 justify-between">
              <div className="flex gap-4">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate(`summary/${item.id}`)}>
                  View Summary
                </Button>
                {false && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate(`worksheet/${item.id}`)}>
                    Open WorkSheet
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate(`action/edit/projectId/${item.id}`)}>
                  Edit
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={item.is_on_hold}
                  onClick={() => navigate(`action/addMoreProject/projectId/${item.id}`)}>
                  Add more products
                </Button>
                {item.is_on_hold ? (
                  <Button color="primary" variant="outlined" onClick={() => handleResume(item.id)}>
                    Resume
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handlePutOnHold(item.id)}>
                    Put on hold
                  </Button>
                )}
              </div>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleDeleteProject(item.id)}>
                Delete
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    ))
  );
};

export default ProjectsView;
