import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { axiosInstance } from "../../../../core/apiInstance";
import { useState } from "react";
import { useAuthStore } from "../../../../state/auth.state";
// import { useAuthStore } from "../state/auth.state";

const FeedbackModal = ({ open, onClose, postApi }) => {
  const [feedback, setFeedback] = useState();
  const { user } = useAuthStore();

  const sendFeedbackViaTickets = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(postApi, { feedback, user_id: user.id });

      console.log(response);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <Typography>Feedback</Typography>
        </div>
        <div className="w-[600px] h-[250px]">
          <div className="flex flex-col justify-end">
            <Box sx={{ width: "100%" }}>
              <div className="flex flex-row items-center justify-start space-x-3 mb-3">
                <div className="w-[70px]">
                  <Typography>Feedback</Typography>
                </div>
                <TextField
                  onChange={(event) => setFeedback(event.target.value)}
                  fullWidth
                  variant="standard"
                  multiline
                  row={4}
                />
              </div>
            </Box>
            <Button
              color="primary"
              variant="contained"
              className="top-1"
              component="label"
              onClick={sendFeedbackViaTickets}>
              Send Feedback
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
