import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../../../core/apiInstance";

const categoriesPlaceholder = ["Example: Paints & Coatings", "Example: Additives"];

const AddTempProductModal = ({ onClose, open }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    handleSubmit,
    register
    // formState: { errors }
  } = useForm();

  useEffect(() => {
    handleCategoryChange(0, null);
  }, []);

  const handleCategoryChange = (id, index) => {
    let categoryId = null;
    let newSelectedCategoryId = [...selectedCategoryId];
    newSelectedCategoryId[index] = id;
    if (id === selectedCategoryId[index]) {
      newSelectedCategoryId = newSelectedCategoryId.slice(0, index);
      categoryId = newSelectedCategoryId[newSelectedCategoryId.slice(0, index).length - 1];
    } else {
      newSelectedCategoryId = newSelectedCategoryId.slice(0, index + 1);
      categoryId = newSelectedCategoryId[index];
    }

    setSelectedCategoryId([...newSelectedCategoryId]);

    axiosInstance
      .get("/api/categories", {
        params: {
          categoryId
        }
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddProductSubmit = async (data) => {
    const [, category_name_level_2] = selectedCategoryId;
    try {
      if (!category_name_level_2) {
        alert("Please Select Categories");
      } else {
        onClose({
          temp_product_name: data.name,
          temp_category_id: category_name_level_2
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 h-[325px] w-[400px]">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Add Product
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit(handleAddProductSubmit)}>
          <div className="h-[400px]">
            <TextField
              {...register("name")}
              id="input-with-icon-textfield"
              label="Product name"
              placeholder="Example: Alkyd Resin"
              fullWidth
              variant="standard"
            />
            <div className="">
              {categories.map((category, index) => (
                <div key={category.name} className="mt-1">
                  {category.result.length > 0 && (
                    <FormControl key={category} fullWidth variant="standard">
                      <InputLabel>{category.name}</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        placeholder={categoriesPlaceholder[index]}
                        value={category?.result.find((item) => item.checked)?.id || ""}
                        onChange={(e) => handleCategoryChange(e.target.value, index)}
                        label="Age">
                        {category.result.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              ))}
            </div>
            <div className="my-5">
              <Button type="submit" fullWidth variant="contained" color="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddTempProductModal;
