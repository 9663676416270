import { Button, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import dayjs from "dayjs";
import { dateFormat } from "../../../../core/constants";
import PSSFeedbackReportModal from "./PSSFeedbackReportModal";
import DateModal from "../../Queries/QueryDetails/DateModal";
import QualityFeedbackModal from "../../Supply/QualityAnalysis/QualityFeedbackModal";

const RunningOrders = () => {
  const [data, setData] = useState([]);
  const [showPssFeedbackReportModal, setShowPssFeedbackReportModal] = useState({
    open: false,
    id: null
  });
  const [showBuyerFeedbackDateModal, setShowBuyerFeedbackDateModal] = useState({
    open: false,
    id: null
  });
  const [showQualityFeedbackModal, setShowQualityFeedbackModal] = useState({
    open: false,
    title: "",
    api: ""
  });
  const params = useParams();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 200
    },
    // {
    //   field: "raw_material_audit",
    //   headerName: "Expected Raw Material Audit Date",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.raw_material_audit_at ? (
    //       <div>{dayjs(params.row.raw_material_audit_at).format(dateFormat)}</div>
    //     ) : null;
    //   }
    // },
    // {
    //   field: "is_raw_material_audit_delayed",
    //   headerName: "Raw Material Audit Status",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.is_actual_raw_material_audit_delayed ? (
    //       params.row.is_actual_raw_material_audit_delayed === "ON TIME" ? (
    //         <div className="text-green-600">{params.row.is_actual_raw_material_audit_delayed}</div>
    //       ) : params.row.is_actual_raw_material_audit_delayed === "DELAYED" ? (
    //         <div className="text-red-600">
    //           {params.row.is_actual_raw_material_audit_delayed} by{" "}
    //           {params.row.raw_material_audit_delayed_by} days
    //         </div>
    //       ) : null
    //     ) : null;
    //   }
    // },
    // {
    //   field: "mid_process_audit",
    //   headerName: "Expected Mid Process Date",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.mid_process_at ? (
    //       <div>{dayjs(params.row.mid_process_at).format(dateFormat)}</div>
    //     ) : null;
    //   }
    // },
    // {
    //   field: "is_mid_process_delayed",
    //   headerName: "Mid Process Status",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.is_actual_mid_process_delayed ? (
    //       params.row.is_actual_mid_process_delayed === "ON TIME" ? (
    //         <div className="text-green-600">{params.row.is_actual_mid_process_delayed}</div>
    //       ) : params.row.is_actual_mid_process_delayed === "DELAYED" ? (
    //         <div className="text-red-600">
    //           {params.row.is_actual_mid_process_delayed} by {params.row.mid_process_delayed_by} days
    //         </div>
    //       ) : null
    //     ) : null;
    //   }
    // },
    {
      field: "logistics_for_pss",
      headerName: "Logistics For PSS",
      width: 200,
      renderCell: (params) => {
        return params.row.logistics_type
          ? params.row.logistics_type === "PSS_SUPPLIER_TO_COVVALENT" ||
            (params.row.logistics_type === "PSS_COVVALENT_TO_BUYER" &&
              !params.row.pss_buyer_feedback_date)
            ? "IN PROGRESS"
            : "DONE"
          : null;
      }
    },

    {
      field: "pss_feedback_date",
      headerName: "PSS Feedback Date",
      width: 250,
      renderCell: (params) => {
        console.log(params.row.id);
        return params.row.pss_buyer_feedback_date ? (
          <div>{dayjs(params.row.pss_delivery_at).format(dateFormat)}</div>
        ) : (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              setShowBuyerFeedbackDateModal({
                open: params.row.id,
                id: params.row.id
              });
            }}>
            UPDATE BUYER FEEDBACK DATE
          </Button>
        );
      }
    },
    {
      field: "pss_feedback",
      headerName: "PSS Feedback",
      width: 300,
      renderCell: (params) => {
        return params.row.pss_feedback_report ? (
          "APPROVED"
        ) : (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              setShowPssFeedbackReportModal({
                open: params.row.id,
                id: params.row.id
              });
            }}>
            {params.row.pss_feedback_report ? "APPROVED" : "APPROVE / REJECT"}
          </Button>
        );
      }
    },
    {
      field: "logistics_for_bulk_order",
      headerName: "Logistics For Bulk Order",
      width: 200,
      renderCell: (params) => {
        return params.row.logistics_type
          ? params.row.logistics_type === "BULK"
            ? params.row.assigned_to_logistics
              ? "IN PROGRESS"
              : "DONE"
            : null
          : null;
      }
    },
    {
      field: "cancel",
      headerName: "CANCEL",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            disabled={params.row.logistics_type === "BULK" ? true : false}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowQualityFeedbackModal({
                open: true,
                api: `/api/ppm/order-actions/object/${params.row.id}/cancel-order`,
                title: "CANCEL ORDER"
              })
            }>
            CANCEL
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  // const handleUploadPSSFeedbackReport = (event, orderId) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const bodyFormData = new FormData();
  //     bodyFormData.append("file", event.target.files[0]);
  //     axiosInstance
  //       .post(
  //         `/api/ppm/projects-products-categories/upload-pss-feedback-report/${orderId}`,
  //         bodyFormData
  //       )
  //       .then((response) => {
  //         setData((preValue) => ({
  //           ...preValue,
  //           pss_feedback_report: response.data.url
  //         }));
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const fetchDraftOrders = async () => {
    console.log(params);
    const { orderId } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${orderId}/orders?state=RUNNING`
      );
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[calc(100vh-240px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showPssFeedbackReportModal.open) && (
        <PSSFeedbackReportModal
          open={showPssFeedbackReportModal.id}
          onClose={() => setShowPssFeedbackReportModal({ open: false, id: null })}
          id={showPssFeedbackReportModal.id}
          fetchParent={fetchDraftOrders}
        />
      )}
      {Boolean(showBuyerFeedbackDateModal.open) && (
        <DateModal
          open={showBuyerFeedbackDateModal.id}
          onClose={() => setShowBuyerFeedbackDateModal({ open: false, id: null })}
          updateApi={`/api/ppm/projects-products-categories/orders/update-buyer-feedback-date/${showBuyerFeedbackDateModal.id}`}
          fetchParent={fetchDraftOrders}
          modalTitle={"Update Expected Buyer Feedback Date"}
        />
      )}
      {Boolean(showQualityFeedbackModal.open) && (
        <QualityFeedbackModal
          open={showQualityFeedbackModal.open}
          api={showQualityFeedbackModal.api}
          fetchParent={fetchDraftOrders}
          title={showQualityFeedbackModal.title}
          onClose={() =>
            setShowQualityFeedbackModal({
              open: false,
              title: "",
              api: ""
            })
          }
        />
      )}
    </div>
  );
};

export default RunningOrders;
