import React from "react";
import {
  Accordion,
  AccordionSummary,
  LinearProgress,
  AccordionDetails,
  TextField,
  Typography,
  Stack,
  InputAdornment,
  Chip,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import { useState, useEffect } from "react";
import { axiosInstance } from "../../../core/apiInstance";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { dateFormat } from "../../../core/constants";

const Analytics2 = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showCustomColumns, setShowCustomColumns] = useState(false);

  const initialColumns = [
    {
      field: "product_name",
      headerName: "ProductName",
      width: 320,
      renderCell: (params) => {
        return (
          <div>
            <p>{params.row.full_name}</p>
            {params.row.is_customization === true && <Chip label="Customization" color="primary" />}
          </div>
        );
      }
    },
    {
      field: "on_hold",
      headerName: "On Hold",
      width: 100,
      renderCell: (params) => {
        return (
          <div>{params.row.is_on_hold === true && <Chip label="On Hold" color="primary" />}</div>
        );
      }
    },
    {
      field: "regret",
      headerName: "Regret",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.is_regret_product === true && <Chip label="Regret" color="primary" />}
          </div>
        );
      }
    },
    {
      field: "progress",
      headerName: "Progress",
      width: 220,
      renderCell: (params) => {
        return (
          <div>
            {params.is_regret_product || params.row.is_on_hold ? (
              ""
            ) : (
              <>
                {params.row.progress === "Delayed" && <Chip label="DELAYED" color="error" />}
                {params.row.progress === "On time" && <Chip label="ON TIME" color="success" />}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: "show_customization_columns",
      headerName: "Customization columns",
      width: 170,
      renderCell: (params) => {
        return (
          <div>
            {params.row.is_customization ? (
              showCustomColumns === false ? (
                <Button variant="outlined" color="primary" onClick={setCustomizationColumns}>
                  View columns
                </Button>
              ) : (
                <Button variant="outlined" color="primary" onClick={setHideCustomizationColumns}>
                  Hide columns
                </Button>
              )
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "supplier_to_covvalent_start_date",
      headerName: "Sample Dispatch Supplier To Covvalent Start Date",
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            {params.row.is_regret_product || params.row.is_on_hold ? (
              ""
            ) : (
              <>
                {params.row.request_for_sample_at
                  ? dayjs(params.row.request_for_sample_at).format(dateFormat)
                  : "Yet to start"}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: "supplier_to_covvalent_due_date",
      headerName: "Sample Dispatch Supplier To Covvalent Due Date",
      width: 300,
      renderCell: (params) => {
        let originalDate = new Date(params.row.expected_supplier_to_covvalent_receiving_at);
        let curDate = new Date();
        return (
          <div
            className={
              params.row.sta === "Sample Supplier to Covvalent In Transit"
                ? curDate > originalDate
                  ? "text-red-600"
                  : ""
                : ""
            }>
            {params.row.expected_supplier_to_covvalent_receiving_at ? (
              <p>{dayjs(originalDate).format(dateFormat)}</p>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    {
      field: "covvalent_to_buyer_start_date",
      headerName: "Sample Dispatch Covvalent To Buyer Start Date",
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            {params.row.expected_supplier_to_covvalent_receiving_at ? (
              <p>
                {dayjs(params.row.expected_supplier_to_covvalent_receiving_at).format(dateFormat)}
              </p>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    {
      field: "expected_covvalent_to_buyer_receiving_at",
      headerName: "Expected Covvalent To Buyer Sample receiving Date",
      width: 300,
      renderCell: (params) => {
        let curDate = new Date();
        let originalDate = new Date(params.row.expected_covvalent_to_buyer_receiving_at);
        return (
          <div
            className={
              params.row.sta === "Sample Covvalent To Buyer In Transit"
                ? curDate > originalDate
                  ? "text-red-600"
                  : ""
                : ""
            }>
            {params.row.expected_supplier_to_covvalent_receiving_at ? (
              <p>{dayjs(originalDate).format(dateFormat)}</p>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    {
      field: "buyer_feedback_start_date",
      headerName: "Buyer Feedback Start Date",
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            {params.row.expected_covvalent_to_buyer_receiving_at ? (
              <p>{dayjs(params.row.expected_covvalent_to_buyer_receiving_at).format(dateFormat)}</p>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    {
      field: "buyer_feedback_date_due_date",
      headerName: "Buyer Feedback Due Date",
      width: 300,
      renderCell: (params) => {
        let curDate = new Date();
        let originalDate = new Date(params.row.buyer_feedback_date);
        return (
          <div
            className={
              !params.row.is_buyer_feedback_approved
                ? curDate > originalDate
                  ? "text-red-600"
                  : ""
                : ""
            }>
            {params.row.expected_covvalent_to_buyer_receiving_at ? (
              <p>{dayjs(originalDate).format(dateFormat)}</p>
            ) : (
              ""
            )}
          </div>
        );
      }
    }
  ];

  const customizationColumns = [
    {
      field: "sample_buyer_to_covvalent_start_date",
      headerName: "Customization Sample Buyer to Covvalent Start Date",
      width: 400,
      renderCell: (params) => {
        return (
          <div>
            {params.row.is_customization ? (
              <div>
                <p>{dayjs(params.row.created_at).format(dateFormat)}</p>
              </div>
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "sample_buyer_to_covvalent_due_date",
      headerName: "Customization Sample Buyer to Covvalent Due Date",
      width: 400,
      renderCell: (params) => {
        return (
          <div>
            {params.row.is_customization ? (
              <div>
                <p>
                  {dayjs(params.row.expected_sample_buyer_to_covalent_receiving_at).format(
                    dateFormat
                  )}
                </p>
              </div>
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "sample_covvalent_to_supplier_start_date",
      headerName: "Customization Sample Covvalent To Supplier Start Date",
      width: 400,
      renderCell: (params) => {
        let originalDate = new Date(params.row.expected_sample_covvalent_to_supplier_receiving_at);
        originalDate.setDate(originalDate.getDate() + 1);
        return (
          <div>
            {params.row.is_customization ? (
              params.row.expected_sample_covvalent_to_supplier_receiving_at ? (
                <div>
                  <p>{dayjs(originalDate).format(dateFormat)}</p>
                </div>
              ) : (
                ""
              )
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "sample_covvalent_to_supplier_due_date",
      headerName: "Customization Sample Covvalent To Supplier Due Date",
      width: 400,
      renderCell: (params) => {
        let originalDate = new Date(params.row.expected_sample_covvalent_to_supplier_receiving_at);
        originalDate.setDate(originalDate.getDate() + 2);
        return (
          <div>
            {params.row.is_customization ? (
              params.row.expected_sample_covvalent_to_supplier_receiving_at ? (
                <div>
                  <p>{dayjs(originalDate).format(dateFormat)}</p>
                </div>
              ) : (
                ""
              )
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "sample_equivalent_preparation_date_start_date",
      headerName: "Customization Sample Equivalent Preparation Start Date",
      width: 400,
      renderCell: (params) => {
        let originalDate = new Date(params.row.sample_equivalent_making_date);
        return (
          <div>
            {params.row.is_customization ? (
              params.row.sample_equivalent_making_date ? (
                <div>
                  <p>{dayjs(originalDate).format(dateFormat)}</p>
                </div>
              ) : (
                ""
              )
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    },
    {
      field: "sample_equivalent_preparation_date_due_date",
      headerName: "Customization Sample Equivalent Preparation Due Date",
      width: 400,
      renderCell: (params) => {
        let originalDate = new Date(params.row.sample_equivalent_making_date);
        return (
          <div>
            {params.row.is_customization ? (
              <div>
                <p>{dayjs(originalDate).format(dateFormat)}</p>
              </div>
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      }
    }
  ];

  const [columns, setColumns] = useState(initialColumns);

  const setCustomizationColumns = () => {
    setShowCustomColumns(true);
    setColumns((prev) => {
      let newArray = [...prev];
      newArray.splice(5, 0, ...customizationColumns);
      return newArray;
    });
  };

  const setHideCustomizationColumns = () => {
    setShowCustomColumns(false);
    setColumns(initialColumns);
  };

  const handleSubmit = async () => {
    await axiosInstance
      .get("/api/ppm/analytics", {
        params: {
          searchText
        }
      })
      .then((response) => {
        setData(response.data);
      });
  };

  const fetchAnalytics = async () => {
    let response = await axiosInstance.get("/api/ppm/analytics", {
      searchText: searchText
    });
    setData(response.data);
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [searchText]);

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex flex-row space-x-5 items-center mb-4">
        <Typography variant="h5" gutterBottom>
          Analytics
        </Typography>

        <TextField
          id="input-with-icon-textfield"
          label="Search"
          fullWidth
          onChange={(event) => setSearchText(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          variant="outlined"
        />
      </div>

      {data &&
        data.map((elem, index) => {
          return (
            <div className="mt-10" key={index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                  <Stack direction="row" spacing={5}>
                    <Typography>Project Name: {elem.name}</Typography>
                    <Typography>No. : {elem.count}</Typography>
                    <Typography>POC: {elem.point_of_contact_name} </Typography>
                    <Typography>Contact: {elem.point_of_contact_phone_number}</Typography>
                    <Typography>Email: {elem.point_of_contact_email}</Typography>
                    <Typography>KAM: {elem.KAM}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <DataGrid
                    hideFooter
                    rows={elem.products}
                    //loading={loading}
                    disableColumnMenu
                    slots={{
                      loadingOverlay: LinearProgress
                    }}
                    columns={columns}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export default Analytics2;
