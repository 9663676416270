import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { Button, LinearProgress } from "@mui/material";

const DraftOrdersSupply = () => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 200
    },
    {
      field: "account",
      headerName: "Account",
      width: 200
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 200
    },
    {
      field: "po_date",
      headerName: "P.O. Date",
      width: 200
    },
    {
      field: "expected_delivery_date",
      headerName: "Expected Delivery Date",
      width: 200
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: () => {
        return (
          <Button fullWidth variant="contained" color="primary" onClick={() => {}}>
            View
          </Button>
        );
      }
    }
  ];

  return (
    <div className="h-[calc(100vh-500px)]">
      <DataGrid
        rowSelection={false}
        rows={[]}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
    </div>
  );
};

export default DraftOrdersSupply;
