import { Typography, Button } from "@mui/material";
import { axiosInstance } from "../../../core/apiInstance";
import { saveData } from "../../../core/utilsFunctions";
// import dayjs from "dayjs";
// import { useAuthStore } from "../../../../state/auth.state";

const Configurations = () => {
  const uploadNewTimelines = async (e) => {
    if (e.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      let response = await axiosInstance.post(
        "/api/ppm/supply-team-timelines/upload-timelines",
        formData
      );
      console.log(response.data);
      // update state
    } catch (err) {
      console.log(err);
    }
  };

  const downloadNewTimelines = () => {
    axiosInstance.get("/api/ppm/supply-team-timelines/download-timelines").then((res) => {
      saveData(res.data, "Timelines.csv");
    });
  };

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-6">
            <Typography variant="h5" gutterBottom>
              Configurations
            </Typography>
          </div>
          <div className="flex flex-col space-y-2">
            <Button color="primary" variant="contained" component="label">
              Upload New Timelines
              <input type="file" hidden onChange={(e) => uploadNewTimelines(e)} />
            </Button>
            <Button fullWidth variant="contained" color="primary" onClick={downloadNewTimelines}>
              Download Timelines
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
