import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Button, LinearProgress } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSearchParams } from "react-router-dom";
import AssignToModal from "../common/AssignToModal";
import TicketsModal from "../../../../components/TicketsModal";
import { axiosInstance } from "../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";

const NewProductAssign = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showAssignToModal, setShowAssignToModal] = useState({
    open: false,
    projectsProductsCategoriesId: null,
    supplyTeamId: null
  });

  const [searchParams, setSearchParams] = useSearchParams({
    currentView: "NEW_PRODUCT_ASSIGN"
  });

  useEffect(() => {
    setLoading(true);
    fetchQuotations();
    setLoading(false);
  }, []);

  const fetchQuotations = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/ppm/quotations/non-assigned-products");
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const productColumns = [
    {
      field: "projects_products_categories_id",
      headerName: "Id",
      renderCell: (params) => {
        return params.row.product_name ? (
          <div className="w-full overflow-y-auto">{params.row.projects_products_categories_id}</div>
        ) : null;
      },
      width: 80
    },
    {
      field: "assign_to",
      headerName: "Supply team member",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowAssignToModal({
                open: true,
                projectsProductsCategoriesId: params.row.projects_products_categories_id,
                supplyTeamId: params.row.supply_team_id
              })
            }>
            Assign to
          </Button>
        ) : null;
      }
    },
    {
      field: "action_ticket",
      headerName: "Tickets",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            endIcon={params.row.is_ticket_open ? <ErrorOutlineIcon /> : <div />}
            onClick={() =>
              setSearchParams({
                currentView: "NEW_PRODUCT_ASSIGN",
                ticketId: params.row.projects_products_categories_id
              })
            }>
            Tickets
          </Button>
        ) : null;
      }
    },
    {
      field: "product_name",
      headerName: "Product name",
      width: 300
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        );
      }
    },
    {
      field: "product_description",
      headerName: "Product description",
      width: 300
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80
    },
    {
      field: "unit",
      headerName: "Unit",
      sortable: false,
      width: 80
    },
    {
      field: "other_info",
      headerName: "Other info",
      sortable: false,
      width: 200
    },
    {
      field: "tds_document_link",
      headerName: "Required Product TDS",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.tds_document_link ? (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                component="label"
                onClick={() => {
                  window.open(params.row.tds_document_link, "_blank");
                }}>
                Download TDS
              </Button>
            ) : (
              "Not Available"
            )}
          </div>
        );
      }
    }
  ];

  const ticketId = searchParams.get("ticketId");

  return (
    <div className="h-full">
      <DataGrid
        loading={loading}
        hideFooter
        getRowId={(row) => row.projects_products_categories_id}
        rows={data}
        disableColumnMenu
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
        columns={productColumns}
      />
      {Boolean(showAssignToModal.open) && (
        <AssignToModal
          open={showAssignToModal.open}
          onClose={() =>
            setShowAssignToModal({
              open: false,
              projectsProductsCategoriesId: null,
              supplyTeamId: null
            })
          }
          supplyTeamId={showAssignToModal.supplyTeamId}
          projectsProductsCategoriesId={showAssignToModal.projectsProductsCategoriesId}
          fetchParent={fetchQuotations}
        />
      )}
      {Boolean(ticketId) && (
        <TicketsModal
          userTeam="SUPPLY"
          open={ticketId}
          onClose={() =>
            setSearchParams({
              currentView: "NEW_PRODUCT_ASSIGN"
            })
          }
          id={ticketId}
          fetchParent={fetchQuotations}
        />
      )}
    </div>
  );
};

export default NewProductAssign;
