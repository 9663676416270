import { DataGrid } from "@mui/x-data-grid";
import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../core/apiInstance";
import { dueDateTimeFormat, segments } from "../../../../core/constants";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";

const RaisedToAdminProducts = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchAdminRegretProducts();
    setLoading(false);
  }, []);

  const fetchAdminRegretProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/ppm/quotations/raised-to-admin-products");
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleApproveRegret = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/approve-regret`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleApproveNVD = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/approve-new-vendor-development`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleApproveCustomization = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/approve-customization`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleRejectRegret = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/reject-regret`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleRejectNVD = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/reject-new-vendor-development`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleRejectCustomization = async (projectsProductsCategoriesId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/reject-customization`
      );
      fetchAdminRegretProducts();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const productColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 220,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              params.row.raise_as_regret && handleApproveRegret(params.row.id);
              params.row.raise_as_new_vendor_development && handleApproveNVD(params.row.id);
              params.row.raise_as_customization && handleApproveCustomization(params.row.id);
            }}>
            Approve {params.row.raise_as_regret && "Regret"}
            {params.row.raise_as_new_vendor_development && "NVD"}
            {params.row.raise_as_customization && "Customization"}
          </Button>
        );
      }
    },
    {
      field: "action2",
      headerName: "Action",
      sortable: false,
      width: 220,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              params.row.raise_as_regret && handleRejectRegret(params.row.id);
              params.row.raise_as_new_vendor_development && handleRejectNVD(params.row.id);
              params.row.raise_as_customization && handleRejectCustomization(params.row.id);
            }}>
            Reject {params.row.raise_as_regret && "Regret"}
            {params.row.raise_as_new_vendor_development && "NVD"}
            {params.row.raise_as_customization && "Customization"}
          </Button>
        );
      }
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 100,
      renderCell: (params) => {
        return params.row.due_date ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.due_date).format(dueDateTimeFormat)}
          </div>
        ) : null;
      }
    },
    {
      field: "project_name",
      headerName: "Project Name",
      sortable: false,
      width: 120
    },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: false,
      width: 250
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        );
      }
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80
    },
    {
      field: "unit",
      headerName: "Unit",
      sortable: false,
      width: 80
    },
    {
      field: "supply_team_member_name",
      headerName: "Assigned To",
      sortable: false
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return segments[params.row.status];
      }
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => {
        return params.row.created_at ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.created_at).format(dueDateTimeFormat)}
          </div>
        ) : null;
      }
    }
  ];

  return (
    <div className="h-full">
      <DataGrid
        loading={loading}
        hideFooter
        rows={data}
        disableColumnMenu
        disableRowSelectionOnClick
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
        columns={productColumns}
      />
    </div>
  );
};

export default RaisedToAdminProducts;
