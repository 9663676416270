import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../../../core/apiInstance";
import { useEffect, useState } from "react";
import { green } from "@mui/material/colors";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import RejectionModal from "./RejectionModal";
import ApprovalFeedbackModal from "./ApprovalFeedbackModal";
import RequestForSampleInfoModal from "./RequestForSampleInfoModal";
// import dayjs from "dayjs";
// import { dateFormat } from "../../../../core/constants";
import DispatchInfoModal from "../../Supply/common/DispatchInfoModal";
import DateModal from "../../Queries/QueryDetails/DateModal";
import FeedbackModal from "./FeedbackModal";

const TrackStagesModal = ({ open, onClose, id, projectId }) => {
  const [showQuotationFeedbackModal, setShowQuotationFeedbackModal] = useState({
    status: null,
    open: false,
    api: null
  });
  const [showRfsInfoModal, setShowRfsInfoModal] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState({
    open: false,
    status: null
  });

  const [stageData, setStageData] = useState();

  const [showDispatchInfoModal, setShowDispatchInfoModal] = useState({
    open: false
  });

  const [showBuyerFeedbackDateModal, setShowBuyerFeedbackDateModal] = useState({
    open: false,
    projectProductCategoryId: null,
    updateApi: null,
    getApi: null,
    modalTitle: ""
  });

  useEffect(() => {
    getStage(id);
  }, []);

  const getStage = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/ppm/projects/object/${id}/track-stages-v2`);
      setStageData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNoModalClose = async () => {
    setShowQuotationFeedbackModal({
      status: null,
      open: false
    });
    getStage(id);
  };

  const handleApproveModalClose = async () => {
    setShowQuotationFeedbackModal({
      status: null,
      open: false
    });
    getStage(id);
  };

  const handleRfsInfoModalClose = () => {
    setShowRfsInfoModal(false);
    getStage(id);
  };

  const stepStyle = {
    "& .Mui-completed": {
      "& .MuiStepIcon-root": {
        color: green[500]
      }
    },
    "& .Mui-active": {
      "& .MuiStepIcon-root": {
        color: green[500]
      }
    }
  };

  const renderActionButtons = (key) => {
    switch (key) {
      case "QUOTATION_SUBMITTED":
        return (
          <div className="flex items-center flex-col mt-2 gap-1">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() =>
                setShowQuotationFeedbackModal({
                  open: true,
                  status: "APPROVE",
                  api: `/api/ppm/project-actions/project-products-categories/${id}/approve-quotation`
                })
              }>
              Approve
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() =>
                setShowQuotationFeedbackModal({
                  open: true,
                  status: "REJECT",
                  api: `/api/ppm/project-actions/project-products-categories/${id}/quotation-revision`
                })
              }>
              Reject
            </Button>
          </div>
        );
      case "REQUEST_FOR_SAMPLE":
        return (
          <div className="mt-2">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => setShowRfsInfoModal(true)}>
              RFS
            </Button>
          </div>
        );
      case "RFS_BUYER_DETAILS":
        return (
          <div className="mt-2">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => {
                setShowDispatchInfoModal({
                  open: true,
                  projectProductCategoryId: id,
                  updateApi: `/api/ppm/project-actions/project-products-categories/${id}/update-sample-buyer-to-covvalent-dispatch-info`,
                  getApi: `/api/ppm/project-actions/project-products-categories/${id}/sample-buyer-to-covvalent-dispatch-info`,
                  dispatchReceiptApi: `/api/ppm/project-actions/project-products-categories/${id}/upload-dispatch-document/sample-buyer-to-covvalent-dispatch-info`,
                  modalTitle: "Sample Dispatch Info - [Buyer-to-Covvalent]"
                });
              }}>
              Dispatch Info
            </Button>
          </div>
        );
      case "BUYERS_FEEDBACK":
        return (
          <div className="flex items-center flex-col mt-2 gap-1">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => {
                setShowFeedbackModal({
                  open: true,
                  status: "APPROVE"
                });
              }}>
              Approve
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() =>
                setShowFeedbackModal({
                  open: true,
                  status: "REJECT"
                })
              }>
              Reject
            </Button>
          </div>
        );
      case "BUYER_FEEDBACK_DATE":
        return (
          <div className="flex items-center flex-col mt-2 gap-1">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => {
                setShowBuyerFeedbackDateModal({
                  open: true
                });
              }}>
              Select Feedback Date
            </Button>
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
          <div className="flex flex-row justify-between pb-[20px]">
            <Typography variant="h5" className="text-center">
              Current Stage - {stageData?.currentStage}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="w-[1000px] h-[200px] overflow-x-auto">
            <div className="flex flex-col justify-end">
              <Box sx={{ width: "100%" }}>
                {stageData?.currentStage !== "PO" && (
                  <Stepper activeStep={stageData?.activeIndex} alternativeLabel sx={stepStyle}>
                    {stageData &&
                      stageData.stages.map((stage) => (
                        <Step key={stage.label}>
                          <StepLabel>{stage.label}</StepLabel>
                          {renderActionButtons(stage.action)}
                        </Step>
                      ))}
                  </Stepper>
                )}
                {stageData?.currentStage === "PO" &&
                  stageData.orders.map((order) => (
                    <div className="py-5" key={order.id}>
                      <Typography variant="h6">Order Id - {order.id}</Typography>
                      <div className="mt-5">
                        <Stepper activeStep={order?.activeIndex} alternativeLabel sx={stepStyle}>
                          {order.stages.map((stage) => (
                            <Step key={stage.label}>
                              <StepLabel>{stage.label}</StepLabel>
                              {renderActionButtons(stage.action)}
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </div>
                  ))}
              </Box>
            </div>
          </div>
        </div>
      </Modal>
      {showQuotationFeedbackModal.open && showQuotationFeedbackModal.status === "REJECT" && (
        <RejectionModal
          open={showQuotationFeedbackModal.open}
          onClose={handleNoModalClose}
          api={showQuotationFeedbackModal.api}
          id={id}
        />
      )}
      {showQuotationFeedbackModal.open && showQuotationFeedbackModal.status === "APPROVE" && (
        <ApprovalFeedbackModal
          open={showQuotationFeedbackModal.open}
          onClose={handleApproveModalClose}
          api={showQuotationFeedbackModal.api}
        />
      )}
      {showRfsInfoModal && (
        <RequestForSampleInfoModal
          open={id}
          onClose={handleRfsInfoModalClose}
          api={showQuotationFeedbackModal.api}
          id={id}
          projectId={projectId}
        />
      )}
      {showDispatchInfoModal.open && (
        <DispatchInfoModal
          open={showDispatchInfoModal.open}
          projectProductCategoryId={showDispatchInfoModal.projectProductCategoryId}
          updateApi={showDispatchInfoModal.updateApi}
          getApi={showDispatchInfoModal.getApi}
          dispatchReceiptApi={showDispatchInfoModal.dispatchReceiptApi}
          fetchParent={() => getStage(id)}
          modalTitle={showDispatchInfoModal.modalTitle}
          onClose={() =>
            setShowDispatchInfoModal({
              open: false,
              projectProductCategoryId: null,
              dispatchReceiptApi: null,
              updateApi: null,
              getApi: null,
              modalTitle: ""
            })
          }
        />
      )}
      {showBuyerFeedbackDateModal.open && (
        <DateModal
          open={showBuyerFeedbackDateModal.open}
          projectProductCategoryId={id}
          updateApi={`/api/ppm/project-actions/project-products-categories/${id}/update-buyer-feedback-date`}
          getApi={`/api/ppm/project-actions/project-products-categories/${id}/buyer-feedback-date`}
          fetchParent={() => getStage(id)}
          modalTitle={"Buyer Feedback Date"}
          onClose={() =>
            setShowBuyerFeedbackDateModal({
              open: false
            })
          }
        />
      )}
      {showFeedbackModal.open && (
        <FeedbackModal
          open={showFeedbackModal.open}
          onClose={() => {
            setShowFeedbackModal({
              open: false,
              status: null
            });
            getStage(id);
          }}
          postApi={
            showFeedbackModal.status === "APPROVE"
              ? `/api/ppm/project-actions/project-products-categories/${id}/approve-buyer-feedback`
              : `/api/ppm/project-actions/project-products-categories/${id}/reject-buyer-feedback`
          }
        />
      )}
    </div>
  );
};

export default TrackStagesModal;
