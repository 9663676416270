import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { dateFormat } from "../../../../../core/constants";
import DataGridCustomToolbar from "../../../../../components/DataGridCustomToolbar";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import AddQuotationModal from "../../../Orders/common/AddQuotationModal";
import SupplierPOModal from "../../commons/SupplierPOModal";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../../core/apiInstance";
import RevisedQuotationModal from "../../../Orders/ProjectProductsOrders/RevisedQuotationModal";

const DraftOrdersPO = ({ fetchParent }) => {
  const [showSupplierPoModal, setShowSupplierPoModal] = useState({
    open: false,
    orderId: null
  });
  const [showAddQuotationModal, setShowAddQuotationModal] = useState({
    id: null,
    activityId: null,
    vendorProductCategoryId: null,
    open: false,
    order_id: null
  });
  const [showRevisedQuotationModal, setShowRevisedQuotationModal] = useState({
    open: false,
    id: null
  });
  const [data, setData] = useState([]);
  const params = useParams();

  const columns = [
    {
      field: "rrq_date",
      headerName: "RRQ Date",
      width: 200,
      renderCell: (params) => {
        return params.row.rrq_at ? <div>{dayjs(params.row.rrq_at).format(dateFormat)}</div> : null;
      }
    },
    {
      field: "rrq_due_date",
      headerName: "RRQ Due Date",
      width: 200,
      renderCell: (params) => {
        return params.row.rrq_due_date ? (
          <div>{dayjs(params.row.rrq_due_date).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "update_suppliers_quotation",
      headerName: "Update Supplier's Quotation",
      width: 200,
      renderCell: (rowParams) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              setShowRevisedQuotationModal({
                open: true,
                id: params.id,
                vpcid: rowParams.row.vpcid,
                orderId: rowParams.row.id
              });
            }}>
            Update Quotation
          </Button>
        );
      }
    },
    {
      field: "po_raised_at",
      headerName: "P.O. Raised Date",
      width: 200,
      renderCell: (params) => {
        return params.row.buyer_po_raised_at ? (
          <div>{dayjs(params.row.buyer_po_raised_at).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "buyer_po_raised",
      headerName: "Buyer P.O. Raised",
      width: 200,
      renderCell: (params) => {
        return params.row.buyer_po ? "DONE" : "PENDING";
      }
    },
    {
      field: "supplier_po",
      headerName: "Supplier P.O.",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            // disabled={params.row.buyer_po_raised_at ? false : true}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowSupplierPoModal({
                open: true,
                orderId: params.row.id
              })
            }>
            Upload
          </Button>
        );
      }
    },
    {
      field: "po_due_date",
      headerName: "P.O. Due Date",
      width: 200,
      renderCell: (params) => {
        return params.row.po_due_date ? (
          <div>{dayjs(params.row.po_due_date).format(dateFormat)}</div>
        ) : null;
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  const fetchDraftOrders = async () => {
    const { id } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${id}/orders?state=DRAFT`
      );
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[calc(100vh-500px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showAddQuotationModal.open) && (
        <AddQuotationModal
          fetchParent={fetchParent}
          open={showAddQuotationModal.open}
          id={showAddQuotationModal.id}
          vendorProductCategoryId={showAddQuotationModal.vendorProductCategoryId}
          activityId={showAddQuotationModal.activityId}
          query={{ isRRQ: true, order_id: showAddQuotationModal.order_id }}
          onClose={() =>
            setShowAddQuotationModal({
              open: false,
              id: null,
              activityId: null,
              vendorProductCategoryId: null,
              order_id: null
            })
          }
        />
      )}
      {Boolean(showSupplierPoModal.open) && (
        <SupplierPOModal
          open={true}
          onClose={() =>
            setShowSupplierPoModal({
              open: false,
              orderId: null
            })
          }
          orderId={showSupplierPoModal.orderId}
          fetchParent={fetchDraftOrders}
        />
      )}
      {Boolean(showRevisedQuotationModal.open) && (
        <RevisedQuotationModal
          open={showRevisedQuotationModal.open}
          onClose={() =>
            setShowRevisedQuotationModal({
              open: false,
              id: null
            })
          }
          fetchParent={fetchDraftOrders}
          getApi={"/api/ppm/quotations/forms"}
          projectsProductsCategoryId={params.id}
          orderId={showRevisedQuotationModal.orderId}
          user_team={"supply"}
          vpcId={showRevisedQuotationModal.vpcid}
          postApi={"/api/ppm/quotations/mode/ADD_QUOTATION_CACHE"}
        />
      )}
    </div>
  );
};

export default DraftOrdersPO;
