import { Tab, Tabs, Typography } from "@mui/material";
import NewProductAssign from "./NewProductAssign";
import { useSearchParams } from "react-router-dom";
import RaisedToAdminProducts from "./RaisedToAdminProducts";

const QuotationsViewAdminSection = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    currentView: "NEW_PRODUCT_ASSIGN"
  });
  const handleTabValueChange = (event, newValue) => {
    setSearchParams({ currentView: newValue });
  };

  const tabValue = searchParams.get("currentView");

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Quotation Admin View
        </Typography>
      </div>
      <Tabs value={tabValue} onChange={handleTabValueChange} centered sx={{ width: "100%" }}>
        <Tab
          sx={{ width: "50%", maxWidth: "50%" }}
          fullWidth
          value="NEW_PRODUCT_ASSIGN"
          label={"Non Assigned"}
        />
        <Tab
          sx={{ width: "50%", maxWidth: "50%" }}
          fullWidth
          value="RAISED_TO_ADMIN"
          label={"Raised to Admin Products"}
        />
      </Tabs>
      <div className="h-full w-full">
        {tabValue === "NEW_PRODUCT_ASSIGN" && <NewProductAssign />}
        {tabValue === "RAISED_TO_ADMIN" && <RaisedToAdminProducts />}
      </div>
    </div>
  );
};

export default QuotationsViewAdminSection;
