/* eslint-disable no-undef */
import axios from "axios";
import { useAuthStore } from "../state/auth.state";
import { useShowAlertStore } from "../state/alert.state";

console.log(process.env.NODE_ENV);
const devEnv = process.env.NODE_ENV === "development";

const axiosInstance = axios.create({
  baseURL: devEnv ? "http://localhost:4000/" : "https://api.covvalent.com/"
});

axiosInstance.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    useShowAlertStore.getState().showAlert({
      severity: "error",
      message: "You are not authorized to access this resource. Please login again.",
      open: true
    });
    useAuthStore.getState().setToken(null, {});
  } else if (
    error.response.status === 404 ||
    error.response.status === 400 ||
    error.response.status === 500
  ) {
    useShowAlertStore.getState().showAlert({
      severity: "error",
      message: error.response?.data?.error || error.response?.data?.message,
      open: true
    });
  } else if (error.response.status === 502 || error.response.status === 504) {
    useShowAlertStore.getState().showAlert({
      severity: "error",
      message: "Server is down. Please try again later.",
      open: true
    });
  }

  return Promise.reject(error);
});

export { axiosInstance };
