import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
import ExternalQuotationPage from "./app/External";
import DashboardPage from "./app/Dashboard";
import dashboardRoutesIndex from "./app/Dashboard/dashboard.routes";
import AuthRoutes from "./app/Auth";
import authRoutes from "./app/Auth/auth.routes";
import { useShowAlertStore } from "./state/alert.state";
import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useAuthStore } from "./state/auth.state";
import jwtDecode from "jwt-decode";

const ReRouteComponent = () => {
  const { token } = useAuthStore();
  if (token) {
    const user = jwtDecode(token);
    console.log(user);
    const isDemandSide = user.userTeams.find((team) => team.team_name === "DEMAND");
    const isSupplySide = user.userTeams.find((team) => team.team_name === "SUPPLY");
    if (isDemandSide) {
      return <Navigate to="/dashboard/orders/projects" />;
    } else if (isSupplySide) {
      return <Navigate to="/dashboard/queries" />;
    } else {
      return <Navigate to="/dashboard/products" />;
    }
  } else {
    return <div />;
  }
};

const appRoutes = [
  {
    route: "/",
    Component: ReRouteComponent
  },
  {
    route: "dashboard",
    nestedRoutes: dashboardRoutesIndex,
    Component: DashboardPage
  },
  {
    route: "external/quotation-form/:id",
    Component: ExternalQuotationPage
  },
  {
    route: "auth",
    nestedRoutes: authRoutes,
    Component: AuthRoutes
  }
];

function App() {
  const {
    alertState: { severity, message, open }
  } = useShowAlertStore();

  const navigate = useNavigate();
  let location = useLocation();
  const { token } = useAuthStore();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "development") {
      document.title = "DEV MODE";
    }

    if (!token) {
      if (location.pathname != "/auth/login") {
        navigate("/auth/login");
      }
    }
  }, [location, token]);

  return (
    <>
      <Routes>
        {appRoutes.map(({ Component, route, nestedRoutes }) => (
          <Route key={route} path={route} element={<Component />}>
            {nestedRoutes && nestedRoutes()}
          </Route>
        ))}
      </Routes>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => {}}>
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </>
  );
}

export default App;
