import { Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../../../core/apiInstance";
import { useState } from "react";
// import { useState } from "react";

const QualityFeedbackModal = ({ open, onClose, api, fetchParent, title }) => {
  const [reason, setReason] = useState("");

  const handleApproveQa = () => {
    axiosInstance
      .post(api, {
        feedback: reason
      })
      .then(() => {
        fetchParent && fetchParent();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {title}
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[200px] ">
          <TextField
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="bottom-3"
            fullWidth
            label={"Feedback for approval"}
            rows={5}
            multiline
          />
          <Button fullWidth color="primary" variant="contained" onClick={() => handleApproveQa()}>
            Approve
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QualityFeedbackModal;
