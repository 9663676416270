import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import RunningOrdersPO from "./RunningOrdersPO";
import DraftOrdersPO from "./DraftOrdersPO";
import OnHoldPO from "./OnHoldPO";
import { useParams } from "react-router-dom";
import CancelledPO from "./CancelledPO";
import CompletedPO from "./CompletedPO";

const PODetails = ({ poData, fetchParent }) => {
  const [tabValue, setTabValue] = useState("DRAFT_ORDERS");

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const params = useParams();

  console.log(params);

  return (
    <div className="h-[400px] w-full mb-4">
      <div className="overflow-x-auto">
        <Tabs value={tabValue} onChange={handleTabValueChange} centered sx={{ width: "100%" }}>
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="DRAFT_ORDERS" label={"Draft Orders"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="RUNNING" label={"Running"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="ON_HOLD" label={"On Hold"} />\
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="CANCELLED" label={"Cancelled"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="COMPLETED" label={"Completed"} />
        </Tabs>
        {tabValue === "DRAFT_ORDERS" && <DraftOrdersPO poData={poData} fetchParent={fetchParent} />}
        {tabValue === "RUNNING" && <RunningOrdersPO />}
        {tabValue === "ON_HOLD" && <OnHoldPO />}
        {tabValue === "CANCELLED" && <CancelledPO />}
        {tabValue === "COMPLETED" && <CompletedPO />}
      </div>
    </div>
  );
};

export default PODetails;
