import { Chip, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../core/apiInstance";

// const statusLabel = {
//   ON_HOLD: "On Hold",
//   REGRET_PRODUCT: "Regret Product",
//   CLOSED: "Closed",
//   RUNNING: "Running"
// };

const colorIndicator = {
  ON_TIME: "bg-lime-200",
  DELAYED: "bg-red-400"
};

const CriticalProjects = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("ALL");
  const [buyerType, setBuyerType] = useState("ALL");

  useEffect(() => {
    fetchData();
  }, [status, buyerType]);

  const columns = [
    {
      accessorKey: "project_name",
      header: "Account's Name",
      enablePinning: true
      // enableColumnActions: false
    },
    {
      accessorKey: "product_name",
      header: "Product Name",
      enablePinning: true,
      enableColumnActions: false,
      Cell: ({ cell, row }) => {
        return (
          <div className="">
            {cell.getValue()}{" "}
            {row.original.status === "ON_HOLD" && <Chip label="On Hold" color="error" />}{" "}
            {row.original.status === "REGRET_PRODUCT" && <Chip label="Regret" color="error" />}
          </div>
        );
      }
    },
    // {
    //   accessorKey: "status",
    //   header: "Status",
    //   enablePinning: true,
    //   Cell: ({ cell }) => statusLabel[cell.getValue()]
    // },
    {
      header: "Quotes Submitted",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_quotes_submitted?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_quotes_submitted?.label}
          </div>
        );
      }
    },
    {
      header: "Quotes Feedback Pending for RFS",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_quotes_submitted_feedback_pending_for_rfs?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_quotes_submitted_feedback_pending_for_rfs?.label}
          </div>
        );
      }
    },
    {
      header: "Sample Dispatch Covvalent To Buyer",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        console.log(row.original.stage_sample_dispatch_covvalent_to_buyer?.status === "ON_TIME");
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_sample_dispatch_covvalent_to_buyer?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_sample_dispatch_covvalent_to_buyer?.label}
          </div>
        );
      }
    },
    {
      header: "Buyer Feedback And PO",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_buyer_feedback_and_po?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_buyer_feedback_and_po?.label}
          </div>
        );
      }
    },
    {
      header: "PSS Dispatch Covvalent To Buyer",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        console.log(row.original.stage_pss_covvalent_to_buyer?.status === "ON_TIME");
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_pss_covvalent_to_buyer?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_pss_covvalent_to_buyer?.label}
          </div>
        );
      }
    },
    {
      header: "PSS Feedback",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        console.log(row.original.stage_pss_feedback_awaited?.status === "ON_TIME");
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_pss_feedback_awaited?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_pss_feedback_awaited?.label}
          </div>
        );
      }
    },
    {
      header: "BULK Dispatch Covvalent To Buyer",
      enableColumnActions: false,
      size: 250,
      minSize: 250,
      maxSize: 250,
      Cell: ({ row }) => {
        console.log(row.original.stage_bulk_covvalent_to_buyer?.status === "ON_TIME");
        return (
          <div
            className={`${
              colorIndicator[row.original.stage_bulk_covvalent_to_buyer?.status]
            } w-full h-[30px] flex justify-center items-center`}>
            {row.original.stage_bulk_covvalent_to_buyer?.label}
          </div>
        );
      }
    }
  ];

  const fetchData = async () => {
    try {
      console.log("Status: ", status);
      const response = await axiosInstance.get("/api/ppm/analytics/critical-projects", {
        params: { status, buyerType }
      });
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (!data || data.length == 0) {
    return null;
  }

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex flex-row">
        <Typography variant="h5" gutterBottom>
          Critical Projects Dashboard
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Status</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={status}
            label="Filter"
            onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="ALL">ALL</MenuItem>
            <MenuItem value="RUNNING_ON_TIME">Running - On Time</MenuItem>
            <MenuItem value="RUNNING_DELAYED">Running - Delayed</MenuItem>
            <MenuItem value="REGRET_PRODUCT">Regret Products</MenuItem>
            <MenuItem value="ON_HOLD">On Hold</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Buyer Type</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={buyerType}
            label="Buyer Type"
            onChange={(e) => setBuyerType(e.target.value)}>
            <MenuItem value="ALL">ALL</MenuItem>
            <MenuItem value="KSA">Key Strategic Accounts</MenuItem>
          </Select>
        </FormControl>
      </div>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGlobalFilter={true}
        enablePagination={false}
        initialState={{ columnPinning: { left: ["project_name", "product_name", "status"] } }}
      />
    </div>
  );
};

export default CriticalProjects;
