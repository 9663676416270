import { DateRange } from "react-date-range";
import { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const DateRangePicker = ({ value, onChange, label }) => {
  const [state, setState] = useState([
    {
      startDate: value?.startDate ? new Date(value.startDate) : null,
      endDate: value?.endDate ? new Date(value.endDate) : null,
      key: "selection"
    }
  ]);

  const [show, setShow] = useState(false);
  const [closeArray, setCloseArray] = useState([0, 1]);

  useEffect(() => {
    if (closeArray[0] === 0 && closeArray[1] === 0 && state[0].startDate && state[0].endDate) {
      setShow(false);
      onChange &&
        onChange({
          startDate: dayjs(state[0].startDate).format("YYYY-MM-DD"),
          endDate: dayjs(state[0].endDate).format("YYYY-MM-DD")
        });
    }
  }, [state, closeArray]);

  const handleClearDate = () => {
    setState([{ startDate: null, endDate: null, key: "selection" }]);
    setCloseArray([0, 1]);
    setShow(false);
    setState(() => [
      {
        startDate: null,
        endDate: null
      }
    ]);
    onChange &&
      onChange({
        startDate: null,
        endDate: null
      });
  };

  const handleOpen = () => {
    setShow((previousState) => !previousState);
    setState([{ startDate: new Date(), endDate: null, key: "selection" }]);
  };

  const onSelectEndDate = (item) => {
    setCloseArray(item);
  };

  return (
    <div className="relative">
      <Button variant="outlined" onClick={handleOpen}>
        {label}
        {closeArray[1] === 0 &&
          `: From - ${dayjs(state[0].startDate).format("YYYY-MM-DD")} To -
        ${dayjs(state[0].endDate).format("YYYY-MM-DD")}`}
      </Button>

      {state[0].startDate && state[0].endDate && (
        <IconButton onClick={handleClearDate}>
          <CloseIcon />
        </IconButton>
      )}

      {show && (
        <div className="absolute z-10">
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState(() => [item.selection])}
            onRangeFocusChange={(item) => onSelectEndDate(item)}
            onShownDateChange={(DateRange, Calendar) => console.log(DateRange, Calendar)}
            months={2}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
