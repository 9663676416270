import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../../../core/apiInstance";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Rename to Add New Supplier And Quotation
const AddQuotationModal = ({
  open,
  onClose,
  activityId,
  id: projectsProductsCategoryId,
  vendorProductCategoryId,
  fetchParent,
  query
}) => {
  console.log(projectsProductsCategoryId);
  const [units, setUnits] = useState([]);
  const [data, setData] = useState({});

  const quotationSchema = yup.object().shape({
    valid_upto_date: yup.string().required("This field is required"),
    price_low: yup.number().typeError("This field is required").required("This field is required"),
    price_high: yup.number().typeError("This field is required").required("This field is required"),
    unit: yup.string().required("This field is required"),
    quantity: yup.number().typeError("This field is required").required("This field is required")
  });

  const quotationAndVendorSchema = yup.object().shape({
    company_name: yup.string().required("This field is required"),
    point_of_contact_name: yup.string().required("This field is required"),
    point_of_contact_email: yup.string().required("This field is required"),
    point_of_contact_phone_number: yup
      .number()
      .typeError("This field is required")
      .required("This field is required"),
    valid_upto_date: yup.string().required("This field is required"),
    price_low: yup.number().typeError("This field is required").required("This field is required"),
    price_high: yup.number().typeError("This field is required").required("This field is required"),
    unit: yup.string().required("This field is required"),
    quantity: yup.number().typeError("This field is required").required("This field is required"),
    tds_document_link: yup.string().required("This field is required")
  });

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      unit: ""
    },
    resolver: yupResolver(
      activityId || vendorProductCategoryId ? quotationSchema : quotationAndVendorSchema
    )
  });

  useEffect(() => {
    handleFetchMetaData();
    console.log(activityId || projectsProductsCategoryId || vendorProductCategoryId);
    if (activityId || projectsProductsCategoryId || vendorProductCategoryId) {
      fetchQuotationDetails();
    }
  }, []);

  const handleFetchMetaData = async () => {
    try {
      const unitsResponse = await axiosInstance.get("/api/ppm/metadata/units");
      setUnits(unitsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchQuotationDetails = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/quotations/forms", {
        params: { activityId, projectsProductsCategoryId, vendorProductCategoryId }
      });

      const {
        point_of_contact_name,
        point_of_contact_email,
        point_of_contact_phone_number,
        company_name,
        quantity,
        price_high,
        price_low,
        unit,
        valid_upto_date
      } = response.data;

      const validUptoDate = dayjs(valid_upto_date);

      reset({
        company_name,
        point_of_contact_name,
        point_of_contact_phone_number,
        point_of_contact_email,
        quantity,
        price_high,
        price_low,
        unit,
        valid_upto_date: validUptoDate
      });

      setData(response.data);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleQuotationFormSubmit = async (data) => {
    try {
      const mode =
        activityId || vendorProductCategoryId
          ? "ADD_QUOTATION_CACHE"
          : "CREATE_VENDOR_AND_QUOTATION";
      let queryParams = {};
      if (query && typeof query === "object") {
        queryParams = { ...query };
      }
      const response = await axiosInstance.post(`/api/ppm/quotations/mode/${mode}`, data, {
        params: { activityId, projectsProductsCategoryId, vendorProductCategoryId, ...queryParams }
      });
      fetchParent && fetchParent();
      console.log(response);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadDocument = (event) => {
    if (event.target.files && event.target.files[0]) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);

      axiosInstance
        .post("/api/ppm/projects/upload-tds", bodyFormData)
        .then((response) => {
          setValue("tds_document_link", response.data.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 w-[760px]">
        <form onSubmit={handleSubmit((data) => handleQuotationFormSubmit(data))}>
          <div className="flex justify-between pb-[10px]">
            <div className="flex items-center">
              <div className="mr-[20px]">
                <Typography variant="h5" component="h2">
                  {activityId || vendorProductCategoryId
                    ? "Add Quotation"
                    : "Add Vendor and its Quotation"}
                </Typography>
              </div>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {!vendorProductCategoryId && (
            <div className="">
              <Typography variant="h6" component="h5">
                Vendor details
              </Typography>
              <Typography variant="subtitle1">Company name: {watch("company_name")}</Typography>
            </div>
          )}
          {!(activityId || vendorProductCategoryId) && (
            <div className="flex flex-col mb-[15px]">
              <TextField
                {...register("company_name")}
                disabled={activityId}
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="Company name"
                variant="standard"
                error={Boolean(errors.company_name?.message)}
              />
              <div className="flex flex-row gap-4">
                <TextField
                  {...register("point_of_contact_name", {
                    touched: true
                  })}
                  disabled={activityId}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="POC name"
                  variant="standard"
                  error={Boolean(errors.point_of_contact_name?.message)}
                />
                <TextField
                  {...register("point_of_contact_email")}
                  disabled={activityId}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="POC email"
                  variant="standard"
                  error={Boolean(errors.point_of_contact_email?.message)}
                />
                <TextField
                  {...register("point_of_contact_phone_number")}
                  disabled={activityId}
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="POC number"
                  variant="standard"
                  error={Boolean(errors.point_of_contact_phone_number?.message)}
                />
              </div>
            </div>
          )}
          <div className="my-2">
            <Typography variant="h6" component="h5">
              Product details
            </Typography>
          </div>
          <div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Product name:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.product_name}
              </Typography>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Description:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.product_description || "No description provided"}
              </Typography>
            </div>
            {!activityId && (
              <div className="flex flex-row items-center gap-4">
                <Typography variant="subtitle1" component="h5">
                  Quantity:
                </Typography>
                <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                  {data?.quantity} {data?.unit}
                </Typography>
              </div>
            )}
            <div className="flex flex-row items-center gap-4">
              <Typography variant="subtitle1" component="h5">
                Category:
              </Typography>
              <Typography sx={{ fontWeight: "regular" }} variant="subtitle1" component="h5">
                {data?.categories?.map((category) => category.category_name).join(" -> ")}
              </Typography>
            </div>
            <div className="mt-2 mb-0">
              <Typography variant="h6" component="h5">
                Quotation
              </Typography>
            </div>
            <div className="flex flex-row gap-4 items-end">
              <Typography sx={{ width: 1 }} variant="subtitle1">
                {"Required Quantity >"}
              </Typography>
              <TextField
                {...register("quantity")}
                sx={{ width: "100%" }}
                id="outlined-basic"
                placeholder="Quantity"
                variant="standard"
                error={Boolean(errors.quantity?.message)}
              />
              <FormControl fullWidth variant="standard" error={Boolean(errors.unit?.message)}>
                <InputLabel>Unit</InputLabel>
                <Select
                  {...register("unit")}
                  labelId="demo-simple-select-standard-label"
                  value={watch("unit")}
                  label="Unit">
                  {units.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="h6">=</Typography>
              <TextField
                {...register("price_low")}
                sx={{ width: "100%" }}
                id="outlined-basic"
                placeholder="Price"
                variant="standard"
                type={"number"}
                error={Boolean(errors.price_low?.message)}
              />
              <Typography variant="subtitle1">/KG</Typography>
            </div>
            <div className="flex flex-row gap-4 items-end">
              <Typography sx={{ width: 1 }} variant="subtitle1">
                {"Required Quantity <"}
              </Typography>
              <TextField
                placeholder="Quantity"
                disabled
                value={watch("quantity")}
                sx={{ width: "100%" }}
                id="outlined-basic"
                variant="standard"
              />
              <FormControl fullWidth variant="standard" value={watch("unit")}>
                <InputLabel>Unit</InputLabel>
                <Select
                  disabled
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={watch("unit")}
                  label="Unit">
                  {units.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="h6">=</Typography>
              <TextField
                {...register("price_high")}
                sx={{ width: "100%" }}
                id="outlined-basic"
                placeholder="Price"
                variant="standard"
                type={"number"}
                error={Boolean(errors.price_high?.message)}
              />
              <Typography variant="subtitle1">/KG</Typography>
            </div>
            <div className="my-2 mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="valid_upto_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disablePast
                      value={value}
                      onChange={onChange}
                      name="valid_upto_date"
                      label="Valid Upto Date"
                    />
                  )}
                />
                {errors.valid_upto_date?.message && (
                  <FormHelperText error>{errors.valid_upto_date?.message}</FormHelperText>
                )}
              </LocalizationProvider>
            </div>
            {Boolean(!activityId && !vendorProductCategoryId) && (
              <div className="my-5">
                <Button variant="outlined" color="primary" component="label">
                  {watch("tds_document_link") ? "Uploaded" : "Upload TDS (Covvalent TDS)"}
                  <input type="file" hidden onChange={(event) => handleUploadDocument(event)} />
                </Button>
                {errors.tds_document_link?.message && (
                  <FormHelperText error>{errors.tds_document_link?.message}</FormHelperText>
                )}
              </div>
            )}
            <div className="my-5">
              <Button type="submit" fullWidth variant="contained" color="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddQuotationModal;
