import { Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { axiosInstance } from "../core/apiInstance";

const AddUpdateIncotermModal = ({ open, onClose, vendorProductCategoryId, fetchParent }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    console.log("on init");
    fetchIncoterm();
  }, []);

  const fetchIncoterm = async () => {
    try {
      if (vendorProductCategoryId) {
        const response = await axiosInstance.get(
          `/api/vendors/vendorProductCategory/${vendorProductCategoryId}/incoterm`
        );
        if (response.data.incoterm) {
          reset({ incoterm: response.data.incoterm });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = async (value) => {
    try {
      const response = await axiosInstance.post(
        `/api/vendors/vendorProductCategory/${vendorProductCategoryId}/incoterm`,
        value
      );
      response && fetchParent();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Add/Update Incoterm
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[300px] h-[100px]">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <TextField
              {...register("incoterm")}
              fullWidth
              placeholder="Incoterm"
              variant="standard"
              error={!!errors.incoterm}
              helperText={errors.incoterm?.message}
            />
            <div className="my-5">
              <Button fullWidth type="submit" variant="contained" color="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddUpdateIncotermModal;
