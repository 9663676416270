import { Navigate, Route } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useAuthStore } from "../../state/auth.state";
import ProductsSection from "./Products";
// import Queries from "./Queries";

import ToolsOutlet from "./Tools";
import toolsRoutes from "./Tools/tools.routes";
import SupplyOutlet from "./Supply";
import supplyRoutes from "./Supply/supply.routes";
import OrdersOutlet from "./Orders";
import ordersRoutes from "./Orders/orders.routes";
import QueriesOutlet from "./Queries";
import queriesRoutes from "./Queries/queries.routes";
import Analytics2 from "./Analytics2";
import AnalyticsDashboard from "./AnalyticsDashboard";
// import { dataDashboardRoutes } from "./DataDashBoard/datadashboard.route";
// import DataDashboardOutlet from "./DataDashBoard/datadashboard.route";
import CriticalProjects from "./CriticalProjects";
import Configurations from "./Configurations";

const ReRouteComponent = () => {
  const { token } = useAuthStore();
  if (token) {
    const user = jwtDecode(token);
    console.log(user);
    const isDemandSide = user.userTeams.find((team) => team.team_name === "DEMAND");
    const isSupplySide = user.userTeams.find((team) => team.team_name === "SUPPLY");
    if (isDemandSide) {
      return <Navigate to="/dashboard/orders/projects" />;
    } else if (isSupplySide) {
      return <Navigate to="/dashboard/queries" />;
    } else {
      return <Navigate to="/dashboard/products" />;
    }
  } else {
    return <div />;
  }
};

export const dashboardRoutes = [
  {
    route: "",
    Component: ReRouteComponent
  },
  {
    route: "tools",
    Component: ToolsOutlet,
    nestedRoutes: toolsRoutes
  },
  {
    route: "supply",
    Component: SupplyOutlet,
    nestedRoutes: supplyRoutes
  },
  {
    route: "orders",
    Component: OrdersOutlet,
    nestedRoutes: ordersRoutes
  },
  {
    route: "queries",
    Component: QueriesOutlet,
    nestedRoutes: queriesRoutes
  },
  {
    label: "Products",
    route: "products",
    Component: ProductsSection
  },
  {
    label: "Critical Projects",
    route: "analytics2",
    Component: Analytics2
  },
  {
    label: "Critical Projects",
    route: "critical-projects",
    Component: CriticalProjects
  },
  {
    label: "Overview",
    route: "analytics-dashboard",
    Component: AnalyticsDashboard
  },
  {
    label: "Configurations",
    route: "configurations",
    Component: Configurations
  }
  // {
  //   route: "data-dashboard",
  //   Comment: DataDashboardOutlet,
  //   nestedRoutes: dataDashboardRoutes
  // }
];

export default () => {
  return (
    dashboardRoutes &&
    dashboardRoutes.map(({ Component, route, nestedRoutes }) => (
      <Route key={route} path={route} element={<Component />}>
        {Boolean(nestedRoutes) && nestedRoutes()}
      </Route>
    ))
  );
};
