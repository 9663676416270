/* eslint-disable no-undef */
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import { useAuthStore } from "../state/auth.state";

const AppBarCustom = styled(AppBar)(`
  z-index: 1300 !important;
`);

const Header = () => {
  const { setToken } = useAuthStore();
  // Look for external link to not show header//

  const handleLogout = () => {
    setToken(null, {});
  };

  return (
    <AppBarCustom sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }} position="fixed">
      <Toolbar className="flex justify-between">
        <img className="h-[65px]" src={`${process.env.PUBLIC_URL}/assets/images/logo.jpg`} alt="" />
        <IconButton
          onClick={handleLogout}
          sx={{ color: "white" }}
          color="primary"
          component="label">
          <LogoutIcon className="text-white" fontSize="large" />
        </IconButton>
      </Toolbar>
    </AppBarCustom>
  );
};

export default Header;
