import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
// import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import AddNewSupplierModal from "./AddNewSupplierModal";

const AddSupplierModal = ({
  open,
  onClose,
  customization,
  projectsProductsCategoriesId,
  fetchParent
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ result: [], count: 0 });
  const [filter, setFilter] = useState({
    pagination: {
      pageSize: 10,
      page: 0
    },
    sort: []
  });

  const [addNewSupplierModal, setAddNewSupplierModal] = useState(false);

  useEffect(() => {
    fetchVendors();
  }, [filter]);

  const fetchVendors = async () => {
    setLoading(true);

    try {
      let response = await axiosInstance.get("/api/ppm/quotations/segments", {
        params: {
          projectsProductsCategoriesId,
          onlyQuery: true
        }
      });
      console.log(response.data);
      setData({ result: response.data });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    /*
    
    axiosInstance
      .get("/api/vendors", { params: filter })
      .then((response) => {
        setData({ result: response.data.result, count: response.data.count });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
      */
  };

  const handleFilterChange = (type, model) => {
    if (type === "pagination") {
      setFilter((filter) => ({ ...filter, pagination: { ...model } }));
    } else if (type === "sort") {
      setFilter((filter) => ({ ...filter, sort: [model[0]] }));
    }
  };

  const handleSelectSupplier = async (id) => {
    try {
      setLoading(true);
      console.log(projectsProductsCategoriesId);
      console.log(customization);
      await axiosInstance.post(
        `/api/ppm/projects/project-product-category/${projectsProductsCategoriesId}/non-customization/submit-quotation`,
        {
          vendorProductCategoryId: id,
          customization
        }
      );
      setLoading(false);
      fetchParent();
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 70,
      renderCell: (params) => {
        return <div>{params.row.vendors_products_categories_id}</div>;
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            component="label"
            onClick={() => handleSelectSupplier(params.row.vendors_products_categories_id)}>
            Select
          </Button>
        );
      }
    },
    {
      field: "name",
      headerName: "name",
      width: 250,
      renderCell: (params) => {
        return <div>{params.row.vendor_name}</div>;
      }
    },
    { field: "point_of_contact_name", headerName: "Point of contact name", width: 200 },
    {
      field: "point_of_contact_phone_number",
      headerName: "Point of contact phone number",
      width: 200
    },
    { field: "point_of_contact_email", headerName: "Point of contact phone email", width: 200 }
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                SELECT SUPPLIER
              </Typography>
            </div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setAddNewSupplierModal(true);
              }}>
              ADD SUPPLIER
            </Button>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[820px] h-[480px]">
          <DataGrid
            rowSelection={false}
            rows={data.result}
            columns={columns}
            loading={loading}
            rowCount={data.count}
            pageSizeOptions={[10, 50, 100]}
            disableMultipleColumnsSorting
            paginationMode="server"
            paginationModel={filter.pagination}
            onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
            sortingMode="server"
            sortModel={filter.model}
            onSortModelChange={(sort) => handleFilterChange("sort", sort)}
            disableColumnMenu
            editMode="row"
            slots={{
              loadingOverlay: LinearProgress
            }}
          />
        </div>
        {addNewSupplierModal && (
          <AddNewSupplierModal
            onClose={() => setAddNewSupplierModal(false)}
            projectProductCategoryId={projectsProductsCategoriesId}
            fetchParent={fetchVendors}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddSupplierModal;
