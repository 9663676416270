import { axiosInstance } from "../../../../core/apiInstance";
import DateRangePicker from "../../../../components/DateRangePicker";
import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import DraftOrdersSupply from "./DraftOrdersSupply";
import RunningOrdersSupply from "./RunningOrdersSupply";

const POAllOrders = () => {
  const [tabValue, setTabValue] = useState("DRAFT_ORDERS");
  const [accountManager, setAccountManager] = useState([]);
  const [supplyTeamMember, setSupplyTeamMember] = useState([]);
  const [createdAt, setCreatedAt] = useState({
    startDate: null,
    endDate: null
  });

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFetchAccountManager = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=DEMAND");
      setAccountManager(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchSupplyTeamMember = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=SUPPLY");
      setSupplyTeamMember(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFetchAccountManager();
    handleFetchSupplyTeamMember();
  }, []);

  return (
    <div className="h-[calc(100vh-200px)]">
      <Typography variant="h5" gutterBottom>
        Orders
      </Typography>
      <div className="flex items-center">
        <div className="mr-[10px]">
          <DateRangePicker
            label={"Created At"}
            value={createdAt}
            onChange={(value) => setCreatedAt({ ...value })}
          />
        </div>
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
          <InputLabel>Account manager</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={""}
            onChange={() => {}}
            label="Account Manager">
            <MenuItem key={0} value={null}>
              ALL
            </MenuItem>
            {accountManager.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <InputLabel>Supply team member</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={""}
            onChange={() => {}}
            label="Supply Team Member">
            <MenuItem key={0} value={null}>
              ALL
            </MenuItem>
            {supplyTeamMember.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="overflow-x-auto">
        <Tabs value={tabValue} onChange={handleTabValueChange} centered sx={{ width: "100%" }}>
          <Tab sx={{ width: "50%", maxWidth: "50%" }} value="DRAFT_ORDERS" label={"Draft Orders"} />
          <Tab sx={{ width: "50%", maxWidth: "50%" }} value="RUNNING" label={"Running"} />
        </Tabs>
        {tabValue === "DRAFT_ORDERS" && <DraftOrdersSupply />}
        {tabValue === "RUNNING" && <RunningOrdersSupply />}
      </div>
    </div>
  );
};

export default POAllOrders;
