import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import SelectProductModal from "../../../../components/SelectProductModal";
import SelectProductButton from "./SelectProductButton";
import { saveData } from "../../../../core/utilsFunctions";
import { useShowAlertStore } from "../../../../state/alert.state";
// import AddProductCategoryModal from "../../../../components/AddProductCategoryModal";
import dayjs from "dayjs";
import AddTempProductModal from "./AddTempProductModal";
import { useAuthStore } from "../../../../state/auth.state";

const ProjectProfileSection = () => {
  const [accountManager, setAccountManager] = useState([]);
  const [units, setUnits] = useState([]);
  const [showSelectProductModal, setShowSelectProductModal] = useState({
    open: false,
    index: null
  });
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  let { action, projectId } = useParams();
  const navigate = useNavigate();
  const { showAlert } = useShowAlertStore();
  const { user } = useAuthStore();

  const schema = yup.object().shape({
    name: yup.string().required("This field is required"),
    point_of_contact_name: yup.string().required("This field is required"),
    point_of_contact_email: yup.string().required("This field is required"),
    account_manager: yup
      .number()
      .typeError("This field is required")
      .required("This field is required"),
    products: yup.array().of(
      yup
        .object()
        .shape({
          temp_product_name: yup.string().nullable(),
          product_category_id: yup.string().nullable(),
          quantity: yup
            .number()
            .typeError("This field is required")
            .required("This field is required"),
          unit: yup.string().required("This field is required"),
          isSupplyRaised: yup.boolean().default(false),
          requirement: yup
            .array()
            .of(
              yup
                .string()
                .oneOf(["Quotes", "TDS", "MSDS", "COA", "SAMPLE"], "This field is required")
            )
        })
        .test(
          "at-least-one-field-required",
          'At least one "product"is required',
          function (values) {
            const { temp_product_name, product_category_id } = values;
            return temp_product_name || product_category_id;
          }
        )
    )
  });

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      account_manager: "",
      products: [{ unit: "MT", requirement: [], product_category_id: null }]
    },
    resolver: yupResolver(schema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products"
  });

  useEffect(() => {
    handleFetchAccountManager();
    handleFetchMetaData();
    projectId && fetchProjectDetails();
  }, [user]);

  const fetchProjectDetails = async () => {
    try {
      const response = await axiosInstance.get(`/api/ppm/projects/object/${projectId}`);

      if (action === "addMoreProject") {
        delete response.data.products;
        response.data.products = [];
        response.data.products.push({
          unit: "MT",
          requirement: [],
          product_category_id: null
        });
      }

      reset(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleProjectFormSubmit = async (data) => {
    try {
      console.log("done", data);
      if (action === "createProject") {
        const result = await axiosInstance.post("/api/ppm/projects", data);
        navigate(`../projects/summary/${result.data.projectId}`);
      } else if (action === "addMoreProject") {
        const result = await axiosInstance.post(
          `/api/ppm/projects/object/${projectId}/add-more-products`,
          data
        );
        console.log(result.data);
        navigate(`../projects/summary/${projectId}`);
      } else if (action === "edit") {
        const result = await axiosInstance.put(`/api/ppm/projects/object/${projectId}`, data);
        console.log(result.data);
        navigate(`../projects/summary/${projectId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchAccountManager = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=DEMAND");
      setAccountManager(response.data);
      if (user?.id) {
        if (user.userTeams.some((team) => team.team_name === "DEMAND")) {
          setValue("account_manager", parseInt(user.id));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchMetaData = async () => {
    try {
      const unitsResponse = await axiosInstance.get("/api/ppm/metadata/units");
      setUnits(unitsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnSelectProduct = (product) => {
    setValue(`products.${showSelectProductModal.index}.product_category_id`, product.id);
    setShowSelectProductModal({
      open: false,
      index: null
    });
  };

  const handleDownloadSampleProductList = () => {
    axiosInstance.post("/api/products/download-sample-product-list-sample").then((res) => {
      saveData(res.data, "sample-product-list.csv");
    });
  };

  const uploadBulkProductList = (event) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);

    axiosInstance
      .post("/api/products/upload-bulk-product-list", bodyFormData)
      .then((response) => {
        showAlert({
          open: true,
          message: "Uploaded Successfully",
          severity: "success"
        });
        for (const productCategory of response.data.result) {
          append({ product_category_id: productCategory.id, unit: "MT", requirement: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseAddProductModal = ({ temp_product_name, temp_category_id }) => {
    setShowAddProductModal(false);
    append({ temp_product_name, temp_category_id, unit: "MT", requirement: [] });
  };

  const hideSearchProductShowAddSingleProduct = () => {
    setShowSelectProductModal({
      open: false,
      index: null
    });
    setShowAddProductModal(true);
  };

  const handleRemoveProduct = (index, id) => {
    if (action === "edit") {
      axiosInstance
        .delete(`/api/ppm/project-actions/project-products-categories/${id}`)
        .then((res) => {
          console.log(res.data);
          // show loader
          remove(index);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (action === "createProject" || action === "addMoreProject") {
      remove(index);
    }
  };

  const handleUploadDocument = (event, index) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);

    axiosInstance
      .post("/api/ppm/projects/upload-tds", bodyFormData)
      .then((response) => {
        setValue(`products.${index}.tds_document_link`, response.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(watch(), errors);

  console.log(errors);

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <form onSubmit={handleSubmit(handleProjectFormSubmit)}>
        <div className="flex justify-between">
          <Typography variant="h5">
            {action === "createProject" && "Add Project"}
            {action === "addMoreProject" && "Add More Products"}
            {action === "edit" && "Edit Project"}
          </Typography>
          <div className="ml-5 flex items-center gap-3">
            <Button type="submit" color="primary" variant="contained">
              {action === "createProject" ? "Create" : "Save"} project
            </Button>
          </div>
        </div>
        <div>
          <Typography variant="h6" fontSize={18} fontWeight="bold" marginTop={2}>
            Company details
          </Typography>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Company name"
                variant="standard"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <div className="flex flex-row gap-4 mb-2">
            <Controller
              name="point_of_contact_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="POC name"
                  variant="standard"
                  error={!!errors.point_of_contact_name}
                  helperText={errors.point_of_contact_name?.message}
                />
              )}
            />
            <Controller
              name="point_of_contact_email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="POC email"
                  variant="standard"
                  error={!!errors.point_of_contact_email}
                  helperText={errors.point_of_contact_email?.message}
                />
              )}
            />
            <Controller
              name="point_of_contact_phone_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="POC number"
                  variant="standard"
                  error={!!errors.point_of_contact_phone_number}
                  helperText={errors.point_of_contact_phone_number?.message}
                />
              )}
            />
          </div>
          <div className="flex flex-row gap-4 mb-2">
            <FormControl fullWidth variant="standard" error={!!errors.account_manager}>
              <InputLabel>Account manager</InputLabel>
              <Select
                {...register("account_manager")}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={watch("account_manager")}
                label="Age">
                {accountManager.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.account_manager?.message && (
                <FormHelperText>{errors.account_manager?.message}</FormHelperText>
              )}
            </FormControl>
            <div className="w-full" />
            <div className="w-full" />
          </div>
        </div>
        <div className="flex justify-between items-center mt-10">
          <Typography variant="h6" fontSize={18} fontWeight="bold">
            Product list
          </Typography>
          {action != "edit" && (
            <div className="flex flex-row gap-4">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowAddProductModal(true)}>
                Add Single product
              </Button>
              <Button color="primary" variant="outlined" onClick={handleDownloadSampleProductList}>
                download sample csv for not found products
              </Button>
              <Button color="primary" variant="contained" component="label">
                Upload not found products csv
                <input type="file" hidden onChange={(e) => uploadBulkProductList(e)} />
              </Button>
            </div>
          )}
        </div>
        <div className="mt-5">
          {fields.length > 0 &&
            fields.map((item, index) => (
              <div key={item.id} className="mb-5">
                <Card sx={{ borderColor: "primary.main" }} variant="outlined">
                  <CardContent>
                    <div className="flex justify-between items-start">
                      <div className="flex gap-2 items-center mb-4">
                        <Typography variant="h6" fontSize={18} fontWeight="bold">
                          Product Id {item.projects_products_categories_id}
                        </Typography>
                        {action !== "createProject" && item.created_at && item.updated_at && (
                          <>
                            <Typography variant="body2">
                              Created At: {dayjs(item.created_at).format("DD/MM/YYYY hh:mm A")}
                            </Typography>
                            <Typography variant="body2">
                              Updated At: {dayjs(item.updated_at).format("DD/MM/YYYY hh:mm A")}
                            </Typography>
                          </>
                        )}
                      </div>
                      <Button
                        startIcon={<ClearIcon />}
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          handleRemoveProduct(index, item.projects_products_categories_id)
                        }>
                        Remove
                      </Button>
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col w-full items-center justify-between">
                        <SelectProductButton
                          disabled={action === "edit"}
                          productCategoryId={watch(`products.${index}.product_category_id`)}
                          temp_product_name={watch(`products.${index}.temp_product_name`)}
                          temp_category_id={watch(`products.${index}.temp_category_id`)}
                          onClick={() => setShowSelectProductModal({ index, open: true })}
                        />
                        {errors.products &&
                          errors?.products[index] &&
                          errors.products[index].product_category_id?.message && (
                            <FormHelperText
                              error={
                                errors.products &&
                                errors?.products[index] &&
                                errors.products[index].product_category_id
                              }>
                              {errors.products &&
                                errors?.products[index] &&
                                errors.products[index].product_category_id?.message}
                            </FormHelperText>
                          )}
                      </div>
                      <div className="flex flex-row gap-4 w-full">
                        <TextField
                          {...register(`products.${index}.quantity`)}
                          type="number"
                          fullWidth
                          label="Quantity per month"
                          variant="standard"
                          InputProps={{
                            inputProps: {
                              step: "0.0001"
                            }
                          }}
                          error={
                            !!errors?.products &&
                            errors?.products[index] &&
                            errors?.products[index]?.quantity
                          }
                          helperText={
                            errors?.products &&
                            errors?.products[index] &&
                            errors?.products[index].quantity?.message
                          }
                        />
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={
                            !!errors?.products &&
                            errors?.products[index] &&
                            errors?.products[index].unit
                          }>
                          <InputLabel>Unit</InputLabel>
                          <Select
                            {...register(`products.${index}.unit`)}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={watch(`products.${index}.unit`)}
                            disabled={action != "edit"}
                            defaultValue="MT"
                            label="Unit">
                            {units.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.products &&
                            errors?.products[index] &&
                            errors?.products[index].unit?.message && (
                              <FormHelperText>
                                {errors?.products[index].unit?.message}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 w-full items-end">
                      {true && (
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={
                            !!errors.products &&
                            errors?.products[index] &&
                            errors.products[index].requirement
                          }>
                          <InputLabel>Requirement (Multi-Select)</InputLabel>
                          <Select
                            {...register(`products.${index}.requirement`)}
                            labelId="demo-simple-select-standard-label"
                            multiple
                            id="demo-simple-select-standard"
                            value={watch(`products.${index}.requirement`)}
                            label="Requirement (Multi-Select)">
                            <MenuItem value={"Quotes"}>Quotes</MenuItem>
                            <MenuItem value={"TDS"}>TDS</MenuItem>
                            <MenuItem value={"MSDS"}>MSDS</MenuItem>
                            <MenuItem value={"COA"}>COA</MenuItem>
                          </Select>
                          {errors.products &&
                            errors?.products[index] &&
                            errors.products[index].requirement?.message && (
                              <FormHelperText>
                                {errors.products &&
                                  errors?.products[index] &&
                                  errors.products[index].requirement?.message}
                              </FormHelperText>
                            )}
                        </FormControl>
                      )}
                      {false && (
                        <>
                          <FormControlLabel required control={<Checkbox />} label="Quotes" />
                          <FormControlLabel required control={<Checkbox />} label="TDS" />
                          <FormControlLabel required control={<Checkbox />} label="MSDS" />
                        </>
                      )}
                      <Button fullWidth color="primary" variant="outlined" component="label">
                        {watch(`products.${index}.tds_document_link`) ? "Uploaded" : "Upload TDS"}
                        <input
                          type="file"
                          hidden
                          onChange={(event) => handleUploadDocument(event, index)}
                        />
                      </Button>
                      <TextField
                        {...register(`products.${index}.other_info`)}
                        fullWidth
                        label="Other info"
                        type="text"
                        variant="standard"
                      />
                    </div>
                    <FormControlLabel
                      value={watch(`products.${index}.isSupplyRaised`)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue(`products.${index}.isSupplyRaised`, true);
                        } else {
                          setValue(`products.${index}.isSupplyRaised`, false);
                        }
                      }}
                      control={
                        <Checkbox
                          disabled={
                            user.userTeams.some(
                              (team) => team.team_name === "SUPPLY_HANDLING_DEMAND"
                            )
                              ? action !== "edit"
                                ? false
                                : true
                              : true
                          }
                        />
                      }
                      label={"Raised By Supply"}
                      labelPlacement={"Raised By Supply"}
                    />
                  </CardContent>
                </Card>
              </div>
            ))}
          {action !== "edit" && (
            <div className="mt-5 pb-10">
              <Button
                startIcon={<AddIcon />}
                size="large"
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => append({ unit: "MT", requirement: [] })}>
                Add Product
              </Button>
            </div>
          )}
        </div>
      </form>
      {Boolean(showSelectProductModal.open) && (
        <SelectProductModal
          showAddSingleProduct={hideSearchProductShowAddSingleProduct}
          open={showSelectProductModal.open}
          onClose={() =>
            setShowSelectProductModal({
              index: null,
              open: false
            })
          }
          onSelect={handleOnSelectProduct}
        />
      )}
      {/* {showAddProductModal && (
        <AddProductCategoryModal open={showAddProductModal} onClose={handleCloseAddProductModal} />
      )} */}
      {showAddProductModal && (
        <AddTempProductModal open={showAddProductModal} onClose={handleCloseAddProductModal} />
      )}
    </div>
  );
};

export default ProjectProfileSection;
