import { Button, LinearProgress, Typography, TextField, InputAdornment } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, Fragment } from "react";
import DataGridCustomToolbar from "../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../core/apiInstance";
import ProductViewDetailsModal from "./ProductViewDetails";
import SearchIcon from "@mui/icons-material/Search";
import { saveData } from "../../../core/utilsFunctions";
import { useShowAlertStore } from "../../../state/alert.state";

const ProductsSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ result: [] });
  const [showViewDetailsModal, setViewDetailsModal] = useState({ id: null, show: false });
  const { showAlert } = useShowAlertStore();

  const [filter, setFilter] = useState({
    pagination: {
      pageSize: 10,
      page: 0
    },
    noHierarchy: true,
    sort: [],
    searchText: ""
  });

  useEffect(() => {
    fetchProductList();
  }, [filter]);

  const fetchProductList = () => {
    setLoading(true);
    axiosInstance
      .get("/api/products", { params: filter })
      .then((response) => {
        setData({ result: response.data.result, count: response.data.count });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  let columns = [
    {
      field: "id",
      headerName: "id",
      width: 80
    },
    { field: "full_name", headerName: "Name", width: 300 },
    {
      field: "categories",
      headerName: "Category",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto h-full flex items-center">
            {params.row.categories.map((cat) => (
              <Fragment key={cat}>
                <label className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                  {cat}
                </label>
              </Fragment>
            ))}
          </div>
        );
      },
      width: 400
    },
    { field: "slug", headerName: "Slug", width: 300 },
    { field: "description", headerName: "description", width: 600 },
    {
      field: "tags",
      headerName: "tags",
      width: 600,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.tags.map((tag) => (
              <label
                key={tag}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {tag}
              </label>
            ))}
          </div>
        );
      }
    }
  ];

  const handleFilterChange = (type, model) => {
    if (type === "pagination") {
      setFilter((filter) => ({ ...filter, pagination: { ...model } }));
    } else if (type === "sort") {
      setFilter((filter) => ({ ...filter, sort: [model[0]] }));
    }
  };

  const handleDownloadSampleProductList = () => {
    setLoading(true);
    axiosInstance.post("/api/products/download-sample-product-list-sample").then((res) => {
      saveData(res.data, "sample-product-list.csv");
      setLoading(false);
    });
  };

  const uploadBulkProductList = (event) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);

    setLoading(true);
    axiosInstance
      .post("/api/products/upload-bulk-product-list", bodyFormData)
      .then((response) => {
        showAlert({
          open: true,
          message: "Uploaded Successfully",
          severity: "success"
        });
        setLoading(false);
        fetchProductList();
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex w-full">
        <Typography variant="h5" gutterBottom>
          Master Product List
        </Typography>
        <div className="ml-5 flex items-center gap-3">
          <Button color="primary" variant="outlined" onClick={handleDownloadSampleProductList}>
            Download sample add bulk products csv
          </Button>
          <Button color="primary" variant="outlined" component="label">
            Upload add bulk products csv
            <input type="file" hidden onChange={(e) => uploadBulkProductList(e)} />
          </Button>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search"
            size="small"
            onChange={(event) => setFilter({ ...filter, searchText: event.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant="standard"
          />
        </div>
      </div>
      <div className="h-full">
        <DataGrid
          rowSelection={false}
          rows={data.result}
          columns={columns}
          loading={loading}
          rowCount={data?.count}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          paginationMode="server"
          paginationModel={filter.pagination}
          onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
          sortingMode="server"
          sortModel={filter.model}
          onSortModelChange={(sort) => handleFilterChange("sort", sort)}
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
      </div>
      <ProductViewDetailsModal
        open={showViewDetailsModal.show}
        id={showViewDetailsModal.id}
        handleClose={() => setViewDetailsModal({ show: false, id: null })}
      />
    </div>
  );
};

export default ProductsSection;
