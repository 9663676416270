import { useEffect, useRef, useState } from "react";
import { Button, FormControl, LinearProgress, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../../core/apiInstance";
import { useShowAlertStore } from "../../../../state/alert.state";
import dayjs from "dayjs";

const DocumentGeneratorSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [docType, setDocType] = useState("");
  const [document, setDocument] = useState(null);

  const interval = useRef(null);

  const { showAlert } = useShowAlertStore();

  useEffect(() => {
    fetchAllDocuments();
    interval.current = window.setInterval(() => fetchAllDocuments(), 10000);
    return () => {
      window.clearInterval(interval.current);
    };
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80
    },
    {
      field: "name",
      headerName: "Document's name",
      width: 250
    },
    {
      field: "type",
      headerName: "Type",
      width: 100
    },
    {
      field: "status",
      headerName: "Status",
      width: 150
    },
    {
      field: "input_file",
      headerName: "Input file",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.row.input_file)}>
            Download
          </Button>
        );
      }
    },
    {
      field: "output_file",
      headerName: "Output file",
      width: 150,
      renderCell: (params) => {
        return params.row.output_file ? (
          <Button
            variant="contained"
            color="primary"
            label="Doc Type"
            onClick={() => window.open(params.row.output_file)}>
            Download
          </Button>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    },
    {
      field: "started_at",
      headerName: "Started at",
      width: 150,
      renderCell: (params) => {
        return params.row.started_at ? (
          <Typography variant="body2" color="textSecondary">
            {dayjs(params.row.started_at).format("hh:mm A DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    },
    {
      field: "completed_at",
      headerName: "Completed at",
      width: 150,
      renderCell: (params) => {
        return params.row.completed_at ? (
          <Typography variant="body2" color="textSecondary">
            {dayjs(params.row.completed_at).format("hh:mm A DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Not available
          </Typography>
        );
      }
    }
  ];

  const fetchAllDocuments = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/ppm/document-generator");
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const selectFile = (event) => {
    if (event.target?.files) {
      setDocument(event.target.files[0]);
    }
  };

  const uploadDocument = async () => {
    setLoading(true);
    if (!document || !docType) {
      alert("Please select file and doc type properly");
      setLoading(false);
      return;
    }

    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file", document);
      bodyFormData.append("doc_type", docType);

      const response = await axiosInstance.post(
        "/api/ppm/document-generator/convert",
        bodyFormData
      );
      showAlert({
        open: true,
        message: "Uploaded Successfully",
        severity: "success"
      });
      setLoading(false);
      setDocType("");
      setDocument(null);
      fetchAllDocuments();
      console.log(response);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Document Generator
        </Typography>
        <div className="ml-5">
          <div className="flex items-center gap-3">
            <Button color="primary" variant="outlined" component="label">
              {document ? document.name : "Select File"}
              <input type="file" hidden onChange={(e) => selectFile(e)} />
            </Button>
            <FormControl variant="standard" sx={{ width: 100 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={docType}
                placeholder="Doc Type"
                onChange={(e) => setDocType(e.target.value)}>
                <MenuItem value="">Doc Type</MenuItem>
                <MenuItem value={"TDS"}>TDS</MenuItem>
                <MenuItem value={"MSDS"}>MSDS</MenuItem>
                <MenuItem value={"COA"}>COA</MenuItem>
              </Select>
            </FormControl>
            <Button color="primary" variant="contained" onClick={uploadDocument}>
              Upload Document
            </Button>
          </div>
        </div>
      </div>
      <div className="h-full">
        <DataGrid
          loading={loading}
          disableColumnMenu
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
          rows={data}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default DocumentGeneratorSection;
