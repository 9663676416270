import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../../components/DataGridCustomToolbar";
// import dayjs from "dayjs";
// import { dateFormat } from "../../../../core/constants";
// import DateModal from "../../Queries/QueryDetails/DateModal";

const CancelledPO = () => {
  const [data, setData] = useState([]);
  const params = useParams();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 200
    },
    {
      field: "cancelled_reason",
      headerName: "Cancelled Reason",
      width: 300
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  const fetchDraftOrders = async () => {
    console.log(params);
    const { id } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${id}/orders?state=CANCELLED`
      );
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[calc(100vh-240px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
    </div>
  );
};

export default CancelledPO;
