import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../core/apiInstance";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../DataGridCustomToolbar";

const SelectProductCategoryModal = ({ open, onClose, onSelect, product, categoryId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProductCategory();
  }, []);

  const fetchProductCategory = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/products/object/${product.id}/categories`, {
        params: { categoryId }
      });
      setLoading(false);
      setData(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelectProduct = (product) => {
    onSelect(product);
    onClose();
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      sortable: false,
      width: 80
    },
    {
      field: "full_name",
      headerName: "Product name",
      sortable: false,
      width: 160
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        );
      }
    },
    {
      field: "description",
      headerName: "description",
      sortable: false,
      width: 200
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Button variant="outlined" onClick={() => handleOnSelectProduct(params.row)}>
            Select
          </Button>
        );
      }
    }
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Select Product Category
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="h-[300px]">
          <DataGrid
            rowSelection={false}
            rows={data}
            hideFooter
            columns={columns}
            loading={loading}
            disableMultipleColumnsSorting
            disableColumnMenu
            editMode="row"
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectProductCategoryModal;
