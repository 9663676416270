import { Button, IconButton, TextField, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";

const AddNewSupplierModal = ({ onClose, projectProductCategoryId, fetchParent }) => {
  const [companyName, setCompanyName] = useState("");
  const [pocName, setPocName] = useState("");
  const [pocEmail, setPocEmail] = useState("");
  const [pocContact, setPocContact] = useState("");

  const handleSubmit = async (ppcId) => {
    let response = await axiosInstance.post("/api/vendors", {
      point_of_contact_name: pocName,
      point_of_contact_email: pocEmail,
      point_of_contact_phone_number: pocContact,
      name: companyName
    });

    console.log(response);

    if (response) {
      await axiosInstance.post(
        `/api/vendors/${response.data.vendorId}/add-project-product-category/${ppcId}`
      );
    }
    fetchParent && fetchParent();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                ADD SUPPLIER
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[820px] h-[350px] flex flex-col justify-evenly">
          <TextField
            fullWidth
            label="Company Name"
            value={companyName}
            onChange={(event) => {
              setCompanyName(event.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Point Of Contact Name"
            value={pocName}
            onChange={(event) => {
              setPocName(event.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Point Of Contact Phone Number"
            value={pocContact}
            onChange={(event) => {
              setPocContact(event.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Point Of Contact Email"
            value={pocEmail}
            onChange={(event) => {
              setPocEmail(event.target.value);
            }}
          />
          <Button
            fullWidth
            color="primary"
            className="top-2"
            variant="contained"
            onClick={() => {
              console.log("ppcId", projectProductCategoryId);
              handleSubmit(projectProductCategoryId);
            }}>
            SUBMIT
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewSupplierModal;
