import { Box, Button, Typography, Modal } from "@mui/material";
import { axiosInstance } from "../../../../core/apiInstance";
import { useShowAlertStore } from "../../../../state/alert.state";
import { useState } from "react";
// import { useAuthStore } from "../state/auth.state";

const PSSFeedbackReportModal = ({ open, onClose, id, fetchParent }) => {
  const { showAlert } = useShowAlertStore();
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);
      setFile(bodyFormData);
    }
  };

  const handleUploadApprovePSSFeedbackReport = (orderId) => {
    axiosInstance
      .post(`/api/ppm/projects-products-categories/upload-pss-feedback-report/${orderId}`, file)
      .then((response) => {
        showAlert({ open: true, message: "Uploaded Successfully", severity: "success" });
        console.log(response);
        fetchParent();
        onClose();
      })
      .catch((err) => {
        showAlert({ open: true, message: "Something went wrong", severity: "error" });
        console.log(err);
      });
  };

  const handleUploadRejectPSSFeedbackReport = (orderId) => {
    axiosInstance
      .post(`/api/ppm/projects-products-categories/upload-pss-feedback-report/${orderId}`, file, {
        params: {
          reject: "reject"
        }
      })
      .then((response) => {
        showAlert({ open: true, message: "Uploaded Successfully", severity: "success" });
        console.log(response);
        fetchParent();
        onClose();
      })
      .catch((err) => {
        showAlert({ open: true, message: "Something went wrong", severity: "error" });
        console.log(err);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="w-[600px] h-[150px]">
          <div className="flex flex-col justify-end">
            <Box sx={{ width: "100%" }}>
              <div className="flex flex-row items-center justify-start space-x-3 mb-3">
                <Typography>PSS Feedback</Typography>
              </div>
            </Box>

            <Button fullWidth color="primary" variant="outlined" component="label">
              UPLOAD Report
              <input type="file" hidden onChange={handleFileChange} />
            </Button>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              component="label"
              onClick={() => handleUploadApprovePSSFeedbackReport(id)}>
              APPROVE
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component="label"
              onClick={() => handleUploadRejectPSSFeedbackReport(id)}>
              REJECT
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PSSFeedbackReportModal;
