import { Box, Button, Typography, Modal } from "@mui/material";
import SupplierPoModal from "../../commons/SupplierPOModal";
import { useState } from "react";
import { axiosInstance } from "../../../../../core/apiInstance";
// import { axiosInstance } from "../../../../core/apiInstance";
// import { useShowAlertStore } from "../../../../state/alert.state";
// import { useAuthStore } from "../state/auth.state";

const OnHoldActionModal = ({ open, onClose, fetchParent, orderId }) => {
  const [showSupplierPoModal, setShowSupplierPoModal] = useState({
    open: false,
    orderId: null
  });

  const restartPSS = async (orderId) => {
    try {
      let response = await axiosInstance.post(
        `/api/ppm/order-actions/object/${orderId}/restart-pss`
      );

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const restartOverAll = async (orderId) => {
    try {
      let response = await axiosInstance.post(
        `/api/ppm/order-actions/object/${orderId}/restart-overall`
      );

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="w-[600px] h-[150px]">
          <div className="flex flex-col justify-end space-y-3">
            <Box sx={{ width: "100%" }}>
              <div className="flex flex-row items-center justify-start space-x-3 mb-3">
                <Typography>ACTION</Typography>
              </div>
            </Box>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => {
                restartPSS(orderId);
                setShowSupplierPoModal({
                  open: true,
                  orderId: orderId
                });
              }}>
              Restart PSS
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              onClick={() => {
                restartOverAll(orderId);
                setShowSupplierPoModal({
                  open: true,
                  orderId: orderId
                });
              }}>
              Restart OverAll
            </Button>
          </div>
        </div>
        {Boolean(showSupplierPoModal.open) && (
          <SupplierPoModal
            open={showSupplierPoModal.open}
            onClose={() =>
              setShowSupplierPoModal({
                open: false,
                orderId: null
              })
            }
            orderId={showSupplierPoModal.orderId}
            fetchParent={fetchParent}
            type={"reset"}
          />
        )}
      </div>
    </Modal>
  );
};

export default OnHoldActionModal;
