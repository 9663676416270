const saveData = (function () {
  if (typeof document === "undefined") return () => {};
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, fileName) {
    const blob = new Blob([data], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
})();

const saveDataWithLink = (function () {
  if (typeof document === "undefined") return () => {};
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (url) {
    a.href = url;
    a.target = "_blank";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
})();

export { saveData, saveDataWithLink };
