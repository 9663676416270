import { Button, LinearProgress, Typography, Tab, Tabs, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RFQDetails from "./RFQDetails";
import { useAuthStore } from "../../../../state/auth.state";
import { axiosInstance } from "../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import TicketsModal from "../../../../components/TicketsModal";
import dayjs from "dayjs";
import { dueDateTimeFormat } from "../../../../core/constants";
import RFSDetails from "./RFSDetails";
import RevisionModal from "./RevisionModal";
import PODetails from "./PODetails";
import RFQDetailsCustomized from "./RFQDetailsCustomized";
import RFSDetailsCustomized from "./RFSDetailsCustomized";

const ProductDetails = () => {
  const { id: projectProductCategoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [tabValue, setTabValue] = useState("RFQ");
  const [revisionModal, setRevisionModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthStore();

  const isAdmin =
    user && user.userTeams && user.userTeams.find((item) => item.team_name === "ADMIN");

  const productColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50
    },
    {
      field: "due_date",
      headerName: "Due Date",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return params.row.due_date ? (
          <div>{dayjs(params.row.due_date).format(dueDateTimeFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "ticket",
      headerName: "Tickets",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return params.row.product_name ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            endIcon={params.row.is_ticket_open ? <ErrorOutlineIcon /> : <div />}
            onClick={() => handleChangeSearchParams("ticketId", params.row.id)}>
            Tickets
          </Button>
        ) : null;
      }
    },
    {
      field: "revision",
      headerName: "Revision",
      sortable: false,
      width: 140,
      renderCell: () => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              setRevisionModal(true);
            }}>
            View Revision
          </Button>
        );
      }
    },
    {
      field: "categories_field",
      headerName: "Category",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto h-full flex items-center">
            {params.row.categories.map((cat) => (
              <div key={cat.category_id}>
                <label className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                  {cat.category_name}
                </label>
              </div>
            ))}
          </div>
        );
      },
      width: 400
    },
    {
      field: "description",
      headerName: "Description",
      width: 200
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 80
    },
    {
      field: "other_info",
      headerName: "Other Info",
      width: 150
    },
    {
      field: "tds_document_link",
      headerName: "Required Product TDS",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.buyer_tds ? (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                component="label"
                onClick={() => {
                  window.open(params.row.buyer_tds, "_blank");
                }}>
                Download TDS
              </Button>
            ) : (
              "Not Available"
            )}
          </div>
        );
      }
    }
  ];

  const columns = productColumns;

  useEffect(() => {
    if (projectProductCategoryId) {
      fetchProductDetails();
    }
  }, [searchParams, projectProductCategoryId]);

  useEffect(() => {
    if (searchParams.get("supplyTeam") === null && !isAdmin) {
      handleChangeSearchParams("supplyTeam", user.id);
    }
  }, []);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      let response = {};
      response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${projectProductCategoryId}`
      );
      console.log(response.data);
      setData([response.data]);
      const is_customization = response.data && response.data.is_customization;
      if (response.data.stage === "QUOTATION") {
        const tabValue = is_customization ? "RFQcustomized" : "RFQ";
        setTabValue(tabValue);
      } else if (response.data.stage === "SAMPLE") {
        const tabValue = is_customization ? "RFScustomized" : "RFS";
        setTabValue(tabValue);
      } else if (response.data.stage === "PO") {
        setTabValue("PO");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleMarkAsNVD = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectProductCategoryId}/mark-as-new-vendor-development`
      );
      fetchProductDetails();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleMarkAsRegret = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectProductCategoryId}/mark-as-regret`
      );
      fetchProductDetails();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleMarkAsCustomization = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectProductCategoryId}/mark-as-customization`
      );
      fetchProductDetails();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChangeSearchParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRemoveSearchParams = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  if (columns && columns.length === 0) {
    return null;
  }

  return (
    <div className="h-[calc(100vh-200px)] w-[calc(100vw-290px)]">
      <div className="flex w-full">
        <Typography variant="h5" gutterBottom>
          Product : {data && data[0] && data[0].product_name}{" "}
          {data && data[0] && data[0].is_customization && (
            <Chip label="Customization" color="primary" />
          )}
        </Typography>
      </div>
      <div className="h-[150px] w-full mb-4">
        <DataGrid
          rowSelection={false}
          rows={data || []}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          hideFooter
          // onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
      </div>
      <div className="overflow-x-auto">
        <Tabs value={tabValue} onChange={handleTabValueChange} centered sx={{ width: "100%" }}>
          {data && data[0] && data[0].is_customization ? (
            <Tab sx={{ width: "33%", maxWidth: "33%" }} value="RFQcustomized" label={"RFQ"} />
          ) : (
            <Tab sx={{ width: "33%", maxWidth: "33%" }} value="RFQ" label={"RFQ"} />
          )}
          {data && data[0] && data[0].is_customization ? (
            <Tab
              disabled={data && data.length > 0 && data[0].stage === "QUOTATION"}
              sx={{ width: "33%", maxWidth: "33%" }}
              value="RFScustomized"
              label={"RFS"}
            />
          ) : (
            <Tab
              disabled={data && data.length > 0 && data[0].stage === "QUOTATION"}
              sx={{ width: "33%", maxWidth: "33%" }}
              value="RFS"
              label={"RFS"}
            />
          )}
          <Tab
            disabled={
              data &&
              data.length > 0 &&
              (data[0].stage === "QUOTATION" || data[0].stage === "SAMPLE")
            }
            sx={{ width: "33%", maxWidth: "33%" }}
            value="PO"
            label={"PO"}
          />
        </Tabs>
        {tabValue === "RFQ" && data && (
          <RFQDetails
            rfqData={data}
            fetchParent={fetchProductDetails}
            onMarkAsRegret={(id) => {
              handleMarkAsRegret(id);
            }}
            onMarkAsNVD={(id) => {
              handleMarkAsNVD(id);
            }}
            onMarkAsCustomization={(id) => {
              handleMarkAsCustomization(id);
            }}
          />
        )}
        {tabValue === "RFQcustomized" && data && (
          <RFQDetailsCustomized
            rfqData={data}
            fetchParent={fetchProductDetails}
            onMarkAsRegret={(id) => {
              handleMarkAsRegret(id);
            }}
            onMarkAsNVD={(id) => {
              handleMarkAsNVD(id);
            }}
            onMarkAsCustomization={(id) => {
              handleMarkAsCustomization(id);
            }}
          />
        )}
        {tabValue === "RFScustomized" && (
          <RFSDetailsCustomized rfsData={data} fetchParent={fetchProductDetails} />
        )}
        {tabValue === "RFS" && <RFSDetails rfsData={data} fetchParent={fetchProductDetails} />}
        {tabValue === "PO" && <PODetails poData={data} fetchParent={fetchProductDetails} />}
      </div>
      {Boolean(searchParams.get("ticketId")) && (
        <TicketsModal
          userTeam="SUPPLY"
          open={searchParams.get("ticketId")}
          onClose={() => handleRemoveSearchParams("ticketId")}
          id={searchParams.get("ticketId")}
          fetchParent={fetchProductDetails}
        />
      )}
      {revisionModal && (
        <RevisionModal
          open={"1"}
          onClose={() => {
            setRevisionModal(false);
          }}
          id={projectProductCategoryId}
        />
      )}
    </div>
  );
};

export default ProductDetails;
