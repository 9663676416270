import { IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { useEffect, useState } from "react";
// import { axiosInstance } from "../../core/apiInstance";

const VendorViewDetailsModal = ({ open, handleClose }) => {
  // const [data, setData] = useState([]);
  // const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     axiosInstance
  //       .get(`/api/vendors/${id}/products`)
  //       .then((response) => {
  //         setLoading(false);
  //         setData(response.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   }
  // }, [id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                View Details
              </Typography>
            </div>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[400px] flex flex-col">
          <TextField
            id="standard-basic"
            label="name"
            variant="standard"
            value="Vinit Pigment"
            disabled
          />
          <TextField id="standard-basic" label="poc name" variant="standard" />
          <TextField id="standard-basic" label="poc email" variant="standard" />
          <TextField id="standard-basic" label="poc phone number" variant="standard" />
        </div>
      </div>
    </Modal>
  );
};

export default VendorViewDetailsModal;
