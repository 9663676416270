import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
// import { dateFormat } from "../../../../../core/constants";
import DataGridCustomToolbar from "../../../../../components/DataGridCustomToolbar";
import { DataGrid } from "@mui/x-data-grid";
// import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../../core/apiInstance";
import OnHoldActionModal from "./OnHoldActionModal";

const OnHoldPO = () => {
  const [data, setData] = useState([]);
  const [showOnHoldModal, setShowOnHoldModal] = useState({
    open: false,
    orderId: null
  });
  const params = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 200
    },
    {
      field: "rejected_from",
      headerName: "Rejected From",
      width: 150
    },
    {
      field: "rejected_reason",
      headerName: "Rejected Reason",
      width: 300
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowOnHoldModal({
                open: true,
                orderId: params.row.id
              })
            }>
            Action
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  const fetchDraftOrders = async () => {
    const { id } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${id}/orders?state=ON_HOLD`
      );
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChangeSearchParams = (key, value) => {
  //   searchParams.set(key, value);
  //   setSearchParams(searchParams);
  // };

  // const handleRemoveSearchParams = (key) => {
  //   searchParams.delete(key);
  //   setSearchParams(searchParams);
  // };

  return (
    <div className="h-[calc(100vh-500px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showOnHoldModal.open) && (
        <OnHoldActionModal
          open={showOnHoldModal.open}
          onClose={() =>
            setShowOnHoldModal({
              open: false,
              orderId: null
            })
          }
          orderId={showOnHoldModal.orderId}
          fetchParent={fetchDraftOrders}
        />
      )}
    </div>
  );
};

export default OnHoldPO;
