import { IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../core/apiInstance";

const ProductViewDetailsModal = ({ open, handleClose, id }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosInstance
        .get(`/api/products/object/${id}`)
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                View Details
              </Typography>
            </div>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[400px] flex flex-col gap-4">
          <TextField
            disabled
            id="standard-basic"
            label="UID"
            variant="standard"
            value={data?.uid}
          />
          <TextField id="standard-basic" label="Name" variant="standard" value={data?.name} />
          <TextField
            multiline
            rows={4}
            id="standard-basic"
            label="Details"
            variant="standard"
            value={data?.description}
          />

          {false && data && isLoading && <div />}
        </div>
      </div>
    </Modal>
  );
};

export default ProductViewDetailsModal;
