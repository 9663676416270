import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DataGridCustomToolbar from "./../../../components/DataGridCustomToolbar";
import { axiosInstance } from "./../../../core/apiInstance";
import DateRangePicker from "../../../components/DateRangePicker";
// import dayjs from "dayjs";
// import { useAuthStore } from "../../../../state/auth.state";

const Dashboard = () => {
  /*
  const { user } = useAuthStore();
  const isAdmin = user && user.userTeams && user.userTeams.find((item) => item.team_name === "ADMIN");
*/

  const [data, setData] = useState();
  const [overviewDate, setOverviewDate] = useState({
    startDate: null,
    endDate: null
  });

  const [RFQDate, setRFQDate] = useState({
    startDate: null,
    endDate: null
  });
  const [RFSDate, setRFSDate] = useState({
    startDate: null,
    endDate: null
  });
  //const [loading, setLoading] = useState(false);

  const [supplyTeamMember, setSupplyTeamMember] = useState([]);
  const [selectedSupplyTeamMemberOverview, setSelectedSupplyTeamMemberOverview] = useState(null);
  const [selectedSupplyTeamMemberRFQ, setSelectedSupplyTeamMemberRFQ] = useState(null);
  const [selectedSupplyTeamMemberRFS, setSelectedSupplyTeamMemberRFS] = useState(null);

  const [accountManager, setAccountManager] = useState([]);
  const [selectedAccountManagerOverview, setSelectedAccountManagerOverview] = useState(null);
  const [selectedAccountManagerRFQ, setSelectedAccountManagerRFQ] = useState(null);
  const [selectedAccountManagerRFS, setSelectedAccountManagerRFS] = useState(null);

  const OverviewColumns = [
    {
      field: "name",
      headerName: "",
      width: 200
    },
    {
      field: "total_queries",
      headerName: "Total Queries",
      width: 200
    },
    {
      field: "open_queries",
      headerName: "Open Queries",
      width: 200
    },
    {
      field: "on_hold",
      headerName: "On hold",
      width: 200
    },
    {
      field: "regret",
      headerName: "Regret",
      width: 200
    }
  ];

  const RFQColumns = [
    {
      field: "name",
      headerName: "",
      width: 200
    },
    {
      field: "total_queries",
      headerName: "Total queries",
      width: 180
    },
    {
      field: "on_hold",
      headerName: "On Hold",
      width: 120
    },
    {
      field: "regret",
      headerName: "Regret",
      width: 200
    },
    {
      field: "in_progress_on_time",
      headerName: "In progress (On Time)",
      width: 200
    },
    {
      field: "in_progress_delayed",
      headerName: "In Progress (Delayed)",
      width: 200
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 130
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: 130
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 100
    }
  ];

  const RFSColumns = [
    {
      field: "name",
      headerName: "",
      width: 200
    },
    {
      field: "total_queries",
      headerName: "Total queries",
      width: 180
    },
    {
      field: "on_hold",
      headerName: "On Hold",
      width: 120
    },
    {
      field: "regret",
      headerName: "Regret",
      width: 200
    },
    {
      field: "in_progress_on_time",
      headerName: "In progress (On Time)",
      width: 200
    },
    {
      field: "in_progress_delayed",
      headerName: "In Progress (Delayed)",
      width: 200
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 130
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: 130
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 100
    },
    {
      field: "po_received",
      headerName: "PO Received",
      width: 120
    }
  ];

  useEffect(() => {
    handleFetchAccountManager();
    handleFetchSupplyTeamMember();
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [
    overviewDate,
    RFQDate,
    RFSDate,
    selectedAccountManagerOverview,
    selectedAccountManagerRFQ,
    selectedAccountManagerRFS,
    selectedSupplyTeamMemberOverview,
    selectedSupplyTeamMemberRFQ,
    selectedSupplyTeamMemberRFS
  ]);

  const handleFetchSupplyTeamMember = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=SUPPLY");
      setSupplyTeamMember(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchAccountManager = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=DEMAND");
      setAccountManager(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDetails = async () => {
    //setLoading(true);
    let filterObj = {
      overviewDateRange: overviewDate,
      RFQDateRange: RFQDate,
      RFSDateRange: RFSDate,
      accountManagerOverview: selectedAccountManagerOverview,
      supplyTeamOverview: selectedSupplyTeamMemberOverview,
      accountManagerRFQ: selectedAccountManagerRFQ,
      supplyTeamRFQ: selectedSupplyTeamMemberRFQ,
      accountManagerRFS: selectedAccountManagerRFS,
      supplyTeamRFS: selectedSupplyTeamMemberRFS
    };
    try {
      let response = await axiosInstance.get("/api/ppm/analytics/analytics-dashboard", {
        params: filterObj
      });
      setData(response.data);
      //setLoading(false);
    } catch (err) {
      console.log(err);
      //setLoading(false);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-6">
            <Typography variant="h5" gutterBottom>
              Overview
            </Typography>
            <DateRangePicker
              label={"Select Date Range"}
              value={overviewDate}
              onChange={(value) => setOverviewDate({ ...value })}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel>Supply team member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedSupplyTeamMemberOverview}
                onChange={(e) => setSelectedSupplyTeamMemberOverview(e.target.value)}
                label="Supply Team Member">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {supplyTeamMember.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel>Account manager</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedAccountManagerOverview}
                onChange={(e) => setSelectedAccountManagerOverview(e.target.value)}
                label="Account Manager">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {accountManager.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full h-[200px]">
            <DataGrid
              rowSelection={false}
              rows={data.overview}
              columns={OverviewColumns}
              //loading={loading}
              rowCount={502}
              pageSizeOptions={[10, 50, 100]}
              disableMultipleColumnsSorting
              paginationMode="server"
              hideFooter
              //paginationModel={filter.pagination}
              //onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
              sortingMode="server"
              //sortModel={filter.model}
              //onSortModelChange={(sort) => handleFilterChange("sort", sort)}
              disableColumnMenu
              editMode="row"
              slots={{
                toolbar: DataGridCustomToolbar,
                loadingOverlay: LinearProgress
              }}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="flex space-x-6">
            <Typography variant="h5" gutterBottom>
              RFQ
            </Typography>
            <DateRangePicker
              label={"Select Date Range"}
              value={RFQDate}
              onChange={(value) => setRFQDate({ ...value })}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel>Supply team member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedSupplyTeamMemberRFQ}
                onChange={(e) => setSelectedSupplyTeamMemberRFQ(e.target.value)}
                label="Supply Team Member">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {supplyTeamMember.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel>Account manager</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedAccountManagerRFQ}
                onChange={(e) => setSelectedAccountManagerRFQ(e.target.value)}
                label="Account Manager">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {accountManager.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full h-[200px]">
            <DataGrid
              rowSelection={false}
              rows={data.supply}
              columns={RFQColumns}
              //loading={loading}
              rowCount={502}
              hideFooter
              pageSizeOptions={[10, 50, 100]}
              disableMultipleColumnsSorting
              paginationMode="server"
              //paginationModel={filter.pagination}
              //onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
              sortingMode="server"
              //sortModel={filter.model}
              //onSortModelChange={(sort) => handleFilterChange("sort", sort)}
              disableColumnMenu
              editMode="row"
              slots={{
                toolbar: DataGridCustomToolbar,
                loadingOverlay: LinearProgress
              }}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="flex space-x-6">
            <Typography variant="h5" gutterBottom>
              RFS
            </Typography>
            <DateRangePicker
              label={"Select Date Range"}
              value={RFSDate}
              onChange={(value) => setRFSDate({ ...value })}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel>Supply team member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedSupplyTeamMemberRFS}
                onChange={(e) => setSelectedSupplyTeamMemberRFS(e.target.value)}
                label="Supply Team Member">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {supplyTeamMember.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel>Account manager</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedAccountManagerRFS}
                onChange={(e) => setSelectedAccountManagerRFS(e.target.value)}
                label="Account Manager">
                <MenuItem key={0} value={null}>
                  ALL
                </MenuItem>
                {accountManager.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full h-[200px]">
            <DataGrid
              rowSelection={false}
              rows={data.demand}
              columns={RFSColumns}
              //loading={loading}
              rowCount={502}
              hideFooter
              pageSizeOptions={[10, 50, 100]}
              disableMultipleColumnsSorting
              paginationMode="server"
              //paginationModel={filter.pagination}
              //onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
              sortingMode="server"
              //sortModel={filter.model}
              //onSortModelChange={(sort) => handleFilterChange("sort", sort)}
              disableColumnMenu
              editMode="row"
              slots={{
                toolbar: DataGridCustomToolbar,
                loadingOverlay: LinearProgress
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
