import { Button, FormHelperText, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../core/apiInstance";
import { useEffect } from "react";

const DateModal = ({ open, onClose, updateApi, getApi, fetchParent, modalTitle }) => {
  const schema = yup.object().shape({
    expectedDeliveryDate: yup.date().required("Expected Delivery Date is required")
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      expectedDeliveryDate: dayjs(new Date())
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    fetchDispatchInfoIfExist();
  }, []);

  const fetchDispatchInfoIfExist = async () => {
    try {
      const response = await axiosInstance.get(getApi);
      if (response.data) {
        reset({
          expectedDeliveryDate: dayjs(response.data.expectedDeliveryDate)
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    try {
      await axiosInstance.post(updateApi, { dispatchInfo: data });
      fetchParent && fetchParent();
      onClose();
    } catch (err) {
      console.log(data);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {modalTitle}
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="justify-center">
          <div className="flex flex-col justify-center space-y-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Expected Delivery Date */}
              <div className="flex flex-row items-center justify-center space-x-3 mb-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="expectedDeliveryDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disablePast
                        value={value}
                        onChange={onChange}
                        name="expectedDeliveryDate"
                        label="Expected Delivery Date"
                      />
                    )}
                  />
                  {errors.valid_upto_date?.message && (
                    <FormHelperText error>{errors.valid_upto_date?.message}</FormHelperText>
                  )}
                </LocalizationProvider>
                <p className="text-red-500">{errors.expectedDeliveryDate?.message}</p>
              </div>

              {/* AWB Number */}
              <div className="flex flex-col justify-center space-y-3">
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Upload
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DateModal;
