import { Button, IconButton, Modal, TextField, Typography, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

const SupplierPOModal = ({ open, onClose, fetchParent, orderId, type }) => {
  // const [formData, setFormData] = useState({
  //   price: "",
  //   quantity: "",
  //   expected_delivery_date: null,
  //   payment_terms: "",
  //   bulk_delivery_location: "",
  //   raw_material_audit_date: null,
  //   process_start_date: null,
  //   mid_process_audit_date: null,
  //   pss_delivery_location: "",
  //   pss_ready_date: null,
  //   pss_dispatch_date: null,
  //   expected_pss_delivery_date: null,
  //   bill_to_name: "",
  //   bill_delivery_address: "",
  //   ship_to_name: "",
  //   ship_delivery_address: "",
  //   testing_method: ""
  // });

  const validationSchema = yup.object().shape({
    price: yup.string().required("Price is required"),
    quantity: yup.string().required("Quantity is required"),
    supplier_expected_delivery_date: yup
      .date()
      .required("Supplier Expected Delivery Date is required"),
    expected_delivery_date: yup.date().required("Buyer Expected Delivery Date is required"),
    payment_terms: yup.string().required("Payment Terms is required"),
    bulk_delivery_location: yup.string().required("Bulk Delivery Location is required"),
    // raw_material_audit_date: yup.date().required("Raw Material Audit Date is required"),
    // process_start_date: yup.date().required("Process Start Date is required"),
    // mid_process_audit_date: yup.date().required("Mid Process Audit Date is required"),
    pss_delivery_location: yup.string().required("PSS Delivery Location is required"),
    pss_ready_date: yup.date().required("PSS Ready Date is required"),
    pss_dispatch_date: yup.date().required("PSS Dispatch Date is required"),
    expected_pss_delivery_date: yup.date().required("Expected PSS Delivery Date is required"),
    bill_to_name: yup.string().required("Bill to Name is required"),
    bill_delivery_address: yup.string().required("Bill Delivery Address is required"),
    ship_to_name: yup.string().required("Ship to Name is required"),
    ship_delivery_address: yup.string().required("Ship Delivery Address is required"),
    testing_method: yup.string().required("Testing Method is required")
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  // const handleDateChange = (date, name) => {
  //   setFormData({
  //     ...formData,
  //     [name]: date // Update the date field with the selected date
  //   });
  // };

  useEffect(() => {
    fetchSupplierPoData();
  }, []);

  const fetchSupplierPoData = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/ppm/order-actions/object/${orderId}/supplier-po-info`
      );
      reset({
        price: response.data?.supplier_po?.price,
        quantity: response.data?.supplier_po?.quantity,
        supplier_expected_delivery_date: dayjs(
          response.data?.supplier_po?.supplier_expected_delivery_date
        ),
        expected_delivery_date: dayjs(response.data?.expected_delivery_date),
        payment_terms: response.data?.supplier_po?.payment_terms,
        bulk_delivery_location: response.data?.supplier_po?.bulk_delivery_location,
        // raw_material_audit_date: dayjs(response.data?.supplier_po?.raw_material_audit_date),
        // process_start_date: dayjs(response.data?.supplier_po?.process_start_date),
        // mid_process_audit_date: dayjs(response.data?.supplier_po?.mid_process_audit_date),
        pss_delivery_location: response.data?.supplier_po?.pss_delivery_location,
        pss_ready_date: dayjs(response.data?.supplier_po?.pss_ready_date),
        pss_dispatch_date: dayjs(response.data?.supplier_po?.pss_dispatch_date),
        expected_pss_delivery_date: dayjs(response.data?.supplier_po?.pss_delivery_at),
        bill_to_name: response.data?.supplier_po?.bill_to_name,
        bill_delivery_address: response.data?.supplier_po?.bill_delivery_address,
        ship_to_name: response.data?.supplier_po?.ship_to_name,
        ship_delivery_address: response.data?.supplier_po?.ship_delivery_address,
        testing_method: response.data?.supplier_po?.testing_method
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSupplierPOFormSubmit = async (formData) => {
    try {
      // let supplierPOInfo = JSON.stringify(formData);
      // console.log(supplierPOInfo);
      let response = await axiosInstance.post(
        `/api/ppm/projects-products-categories/object/${orderId}/supplier-po-info`,
        {
          supplierPOinfo: formData
        },
        {
          params: {
            reset: type
          }
        }
      );

      console.log(response);
      fetchParent();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Update Supplier P.O. Info
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[900px] h-[600px] overflow-y-auto p-2">
          <div className="flex flex-col justify-center space-y-1">
            <form onSubmit={handleSubmit((formData) => handleSupplierPOFormSubmit(formData))}>
              <div className="flex flex-col justify-center space-y-3 mt-1">
                {/* <div className="flex flex-row justify-center w-full space-x-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="raw_material_audit_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="raw_material_audit_date"
                          label="Raw Material Audit Date"
                        />
                      )}
                    />
                    {errors.raw_material_audit_date?.message && (
                      <FormHelperText error>
                        {errors.raw_material_audit_date?.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="process_start_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="process_start_date"
                          label="Process Start Date"
                        />
                      )}
                    />
                    {errors.process_start_date?.message && (
                      <FormHelperText error>{errors.process_start_date?.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="mid_process_audit_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="mid_process_audit_date"
                          label="Mid Process Audit Date"
                        />
                      )}
                    />
                    {errors.mid_process_audit_date?.message && (
                      <FormHelperText error>
                        {errors.mid_process_audit_date?.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </div> */}
                <div className="flex flex-row justify-center w-full space-x-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="pss_ready_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="pss_ready_date"
                          label="PSS Ready Date"
                        />
                      )}
                    />
                    {errors.pss_ready_date?.message && (
                      <FormHelperText error>{errors.pss_ready_date?.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="pss_dispatch_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="pss_dispatch_date"
                          label="PSS Dispatch Date"
                        />
                      )}
                    />
                    {errors.pss_dispatch_date?.message && (
                      <FormHelperText error>{errors.pss_dispatch_date?.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="expected_pss_delivery_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="expected_pss_delivery_date"
                          label="Expected PSS Delivery Date"
                        />
                      )}
                    />
                    {errors.expected_pss_delivery_date?.message && (
                      <FormHelperText error>
                        {errors.expected_pss_delivery_date?.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </div>
                <div className="flex flex-row justify-center w-full space-x-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="expected_delivery_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disabled
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="expected_delivery_date"
                          label="Buyer Expected Delivery Date"
                        />
                      )}
                    />
                    {errors.expected_delivery_date?.message && (
                      <FormHelperText error>
                        {errors.expected_delivery_date?.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="supplier_expected_delivery_date"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          fullWidth
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="supplier_expected_delivery_date"
                          label="Supplier Expected Delivery Date"
                        />
                      )}
                    />
                    {errors.supplier_expected_delivery_date?.message && (
                      <FormHelperText error>
                        {errors.supplier_expected_delivery_date?.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </div>
                <div className="flex flex-row justify-between space-x-3">
                  <TextField
                    label="Price in Rs/kg"
                    name="price"
                    {...register("price")}
                    fullWidth
                    margin="normal"
                    error={!!errors.price}
                    helperText={errors.price ? errors.price.message : ""}
                  />
                  <TextField
                    label="Quantity/MT"
                    name="quantity"
                    {...register("quantity")}
                    fullWidth
                    margin="normal"
                    error={!!errors.quantity}
                    helperText={errors.quantity ? errors.quantity.message : ""}
                  />
                </div>
                <div className="flex space-x-3 space-y-3">
                  <TextField
                    label="Payment Terms"
                    name="payment_terms"
                    {...register("payment_terms")}
                    fullWidth
                    margin="normal"
                    error={!!errors.payment_terms}
                    helperText={errors.payment_terms ? errors.payment_terms.message : ""}
                  />
                </div>
                <div className="flex flex-row justify-between space-x-3 space-y-3">
                  <TextField
                    label="Bulk delivery loction"
                    name="bulk_delivery_location"
                    {...register("bulk_delivery_location")}
                    fullWidth
                    margin="normal"
                    error={!!errors.bulk_delivery_location}
                    helperText={
                      errors.bulk_delivery_location ? errors.bulk_delivery_location.message : ""
                    }
                  />
                </div>
                <div className="flex flex-row justify-between space-x-3 space-y-3">
                  <TextField
                    label="PSS delivery loction"
                    name="pss_delivery_location"
                    {...register("pss_delivery_location")}
                    fullWidth
                    margin="normal"
                    error={!!errors.pss_delivery_location}
                    helperText={
                      errors.pss_delivery_location ? errors.pss_delivery_location.message : ""
                    }
                  />
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-[20px]">
                  <Typography variant="h5" component="h2">
                    Bill to
                  </Typography>
                </div>
              </div>
              <TextField
                label="Name"
                name="bill_to_name"
                {...register("bill_to_name")}
                fullWidth
                margin="normal"
                error={!!errors.bill_to_name}
                helperText={errors.bill_to_name ? errors.bill_to_name.message : ""}
              />
              <TextField
                label="Delivery Address"
                name="bill_delivery_address"
                {...register("bill_delivery_address")}
                fullWidth
                margin="normal"
                error={!!errors.bill_delivery_address}
                helperText={
                  errors.bill_delivery_address ? errors.bill_delivery_address.message : ""
                }
                multiline
                rows={4}
              />
              <div className="flex items-center">
                <Typography variant="h5" component="h2">
                  Ship to
                </Typography>
              </div>
              <TextField
                label="Name"
                name="ship_to_name"
                {...register("ship_to_name")}
                fullWidth
                margin="normal"
                error={!!errors.ship_to_name}
                helperText={errors.ship_to_name ? errors.ship_to_name.message : ""}
              />
              <TextField
                label="Delivery Address"
                name="ship_delivery_address"
                {...register("ship_delivery_address")}
                fullWidth
                margin="normal"
                error={!!errors.ship_delivery_address}
                helperText={
                  errors.ship_delivery_address ? errors.ship_delivery_address.message : ""
                }
              />
              <TextField
                label="Testing Method"
                name="testing_method"
                {...register("testing_method")}
                fullWidth
                margin="normal"
                error={!!errors.testing_method}
                helperText={errors.testing_method ? errors.testing_method.message : ""}
              />
              <Button type="submit" fullWidth variant="contained" color="primary">
                Save
              </Button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupplierPOModal;
