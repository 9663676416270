import { useState, useEffect } from "react";
import { Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as dayjs from "dayjs";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../../core/apiInstance";
import AddQuotationModal from "../../Orders/common/AddQuotationModal";

const QuotationCacheSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ result: [] });
  const [filter, setFilter] = useState({
    pagination: {
      pageSize: 10,
      page: 0
    },
    sort: []
  });
  const [showAddQuotationModal, setShowAddQuotationModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });

  useEffect(() => {
    fetchQuotations();
  }, []);

  const fetchQuotations = () => {
    setLoading(true);
    axiosInstance
      .get("/api/ppm/quotation-cache/")
      .then((response) => {
        setData({ result: response.data.result });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFilterChange = (type, model) => {
    if (type === "pagination") {
      setFilter((filter) => ({ ...filter, pagination: { ...model } }));
    } else if (type === "sort") {
      setFilter((filter) => ({ ...filter, sort: [model[0]] }));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80
    },
    {
      field: "vendor_name",
      headerName: "Vendor's name",
      width: 100
    },
    {
      field: "product_name",
      headerName: "Product name",
      width: 160
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        );
      }
    },
    {
      field: "quotation_low",
      headerName: "Quotation low",
      width: 200
    },
    {
      field: "quotation_high",
      headerName: "Quotation high",
      width: 200
    },
    {
      field: "valid_upto_date",
      headerName: "Valid upto",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.valid_upto_date).diff(new Date(), "day", false)} days left
          </div>
        );
      }
    },
    {
      field: "action_2",
      headerName: "Add/Update Quotation",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowAddQuotationModal({
                open: true,
                vendorProductCategoryId: params.row.vendor_product_category_id
              })
            }>
            {params.row.valid_upto_date ? "Update" : "Add"} Quotation
          </Button>
        );
      }
    }
  ];

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Quotation Cache
        </Typography>
        {false && (
          <div className="ml-5 flex items-center gap-3">
            <Button color="primary" variant="outlined" onClick={() => {}}>
              Download sample bulk vendor product list csv
            </Button>
            <Button color="primary" variant="outlined" component="label">
              Upload bulk vendor product list csv
              <input type="file" hidden onChange={(e) => console.log(e)} />
            </Button>
          </div>
        )}
      </div>
      <div className="h-full">
        <DataGrid
          rowSelection={false}
          rows={data.result}
          columns={columns}
          loading={loading}
          rowCount={502}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          paginationMode="server"
          paginationModel={filter.pagination}
          onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
          sortingMode="server"
          sortModel={filter.model}
          onSortModelChange={(sort) => handleFilterChange("sort", sort)}
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
        {Boolean(showAddQuotationModal.open) && (
          <AddQuotationModal
            open={showAddQuotationModal.open}
            onClose={() => setShowAddQuotationModal({ open: false, vendorProductCategoryId: null })}
            vendorProductCategoryId={showAddQuotationModal.vendorProductCategoryId}
            fetchParent={() => fetchQuotations()}
          />
        )}
      </div>
    </div>
  );
};

export default QuotationCacheSection;
