import { Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
//import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../../core/apiInstance";
import dayjs from "dayjs";
import { dateFormat } from "../../../../core/constants";
import QualityFeedbackModal from "./QualityFeedbackModal";
// import dayjs from "dayjs";

const QualityAnalysisSection = () => {
  const [qualityFeedbackModal, setQualityFeedbackModal] = useState({
    open: null,
    title: null,
    api: null,
    qaType: null,
    feedbackType: null
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchQaAssigned();
  }, []);

  const fetchQaAssigned = () => {
    axiosInstance
      .get("/api/ppm/qa")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "due_date",
      headerName: "Due Date",
      width: 120,
      renderCell: (params) => {
        return params.row.due_date && params.row.product_name ? (
          <div>{dayjs(params.row.due_date).format(dateFormat)}</div>
        ) : (
          ""
        );
      }
    },
    {
      field: "type",
      headerName: "Type",
      width: 100
    },
    {
      field: "project_name",
      headerName: "Project Name",
      width: 150
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 150
    },
    {
      field: "industries",
      headerName: "Industry",
      width: 150
    },
    {
      field: "applications",
      headerName: "Application",
      width: 150
    },
    {
      field: "other_info",
      headerName: "Other Info",
      width: 200
    },
    {
      field: "client_tds",
      headerName: "Client Product TDS",
      width: 200,
      renderCell: (params) => {
        return params.row.buyer_tds ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              window.open(params.row.buyer_tds);
            }}>
            DOWNLOAD
          </Button>
        ) : (
          <p>N/A</p>
        );
      }
    },
    {
      field: "supplier_coa",
      headerName: "Supply Product COA",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              window.open(params.row.supplier_documents.COA);
            }}>
            DOWNLOAD
          </Button>
        );
      }
    },
    {
      field: "pss_coa",
      headerName: "PSS COA",
      width: 200,
      renderCell: (params) => {
        return params.row.pss_coa ? (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              window.open(params.row.pss_coa);
            }}>
            DOWNLOAD
          </Button>
        ) : (
          <p>N/A</p>
        );
      }
    },
    {
      field: "approve_or_reject",
      headerName: "Approve/Reject",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex flex-row space-x-3 justify-center items-center">
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component="label"
              onClick={() =>
                setQualityFeedbackModal({
                  open: true,
                  title: "Approval Feedback",
                  api:
                    params.row.type === "RFS"
                      ? `/api/ppm/project-actions/project-products-categories/${params.row.dedicated_id}/approve-qa`
                      : `/api/ppm/projects-products-categories/approve-qa-pss/${params.row.dedicated_id}`,
                  qaType: params.row.type,
                  feedbackType: "Approve"
                })
              }>
              Approve
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component="label"
              onClick={() =>
                setQualityFeedbackModal({
                  // params.row.ppc_id || params.row.order_id
                  open: true,
                  title: "Approval Feedback",
                  api:
                    params.row.type === "RFS"
                      ? `/api/ppm/project-actions/project-products-categories/${params.row.dedicated_id}/reject-qa`
                      : `/api/ppm/projects-products-categories/reject-qa-pss/${params.row.dedicated_id}`,
                  qaType: params.row.type,
                  feedbackType: "Reject"
                })
              }>
              Reject
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Quality Analysis
        </Typography>
      </div>
      <div className="h-full">
        <DataGrid
          rowSelection={false}
          rows={data}
          columns={columns}
          rowCount={502}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          paginationMode="server"
          sortingMode="server"
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
      </div>
      {Boolean(qualityFeedbackModal.open) && (
        <QualityFeedbackModal
          open={Boolean(qualityFeedbackModal.open)}
          api={qualityFeedbackModal.api}
          fetchParent={fetchQaAssigned}
          title={qualityFeedbackModal.title}
          feedbackType={qualityFeedbackModal.feedbackType}
          onClose={() =>
            setQualityFeedbackModal({
              open: null,
              title: null,
              api: null,
              qaType: null,
              feedbackType: null
            })
          }
        />
      )}
    </div>
  );
};

export default QualityAnalysisSection;
