import { Box, Button, Typography, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../../../core/apiInstance";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../../../state/auth.state";
// import { useAuthStore } from "../state/auth.state";

const RejectionModal = ({ open, onClose, id, api }) => {
  const [requirements, setRequirements] = useState([]);
  const [feedback, setFeedback] = useState({});

  const { user } = useAuthStore();

  useEffect(() => {
    getRequirements(id);
  }, []);

  const getRequirements = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/ppm/projects/object/${id}/requirements`);
      const { requirement } = response.data;
      setRequirements(requirement);
    } catch (err) {
      console.log(err);
    }
  };

  const sendFeedbackViaTickets = async () => {
    try {
      const response = await axiosInstance.post(api, {
        feedback,
        user_id: user.id
      });
      onClose();
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <Typography>Re-Evaluation Feedback</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[600px] h-[250px]">
          <div className="flex flex-col justify-end">
            <Box sx={{ width: "100%" }}>
              {requirements.map((requirement) => {
                return (
                  <div
                    key={requirement}
                    className="flex flex-row items-center justify-start space-x-3 mb-3">
                    <div className="w-[70px]">
                      <Typography>{requirement}</Typography>
                    </div>
                    <TextField
                      onChange={(event) =>
                        setFeedback({ ...feedback, [requirement]: event.target.value })
                      }
                      fullWidth
                      id={requirement}
                      variant="standard"
                    />
                  </div>
                );
              })}
            </Box>
            <Button
              color="primary"
              variant="contained"
              component="label"
              onClick={sendFeedbackViaTickets}>
              RE-Evaluate
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectionModal;
