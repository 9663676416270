import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const RevisedQuotationModal = ({
  open,
  onClose,
  getApi,
  user_team,
  postApi,
  vpcId,
  orderId,
  projectsProductsCategoryId,
  fetchParent
}) => {
  const [data, setData] = useState({
    quantity: "",
    price: "",
    unit: "",
    valid_upto_date: null,
    incoterm: ""
  });

  const [units, setUnits] = useState([]);

  useEffect(() => {
    handleFetchMetaData();
    fetchQuotationDetails();
  }, []);

  const fetchQuotationDetails = async () => {
    try {
      if (user_team === "demand") {
        const response = await axiosInstance.get(getApi);
        const { valid_upto_date } = response.data;
        const validUptoDate = dayjs(valid_upto_date);
        response.data.valid_upto_date = validUptoDate;
        setData(response.data);
      } else {
        const response = await axiosInstance.get(getApi, {
          params: { projectsProductsCategoryId: projectsProductsCategoryId }
        });
        const { valid_upto_date } = response.data;
        console.log(response.data);
        const validUptoDate = dayjs(valid_upto_date);
        response.data.valid_upto_date = validUptoDate;
        setData((prev) => ({
          ...prev,
          quantity: response.data.quantity,
          unit: response.data.unit,
          valid_upto_date: response.data.valid_upto_date,
          price: response.data.priceSuggest,
          incoterm: response.data.incoterm
        }));
        console.log(response);
        // const { valid_upto_date } = response.data;
        // const validUptoDate = dayjs(valid_upto_date);
        // response.data.valid_upto_date = validUptoDate;
        // setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchMetaData = async () => {
    try {
      const unitsResponse = await axiosInstance.get("/api/ppm/metadata/units");
      setUnits(unitsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const approveRRQ = async () => {
    try {
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${orderId}/approve-rrq`
      );
      fetchParent();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const rejectRRQ = async () => {
    try {
      let response = await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${orderId}/reject-rrq`
      );
      console.log(response);
      fetchParent();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const saveRRQ = async () => {
    try {
      let bodyData = {
        price_low: data.price,
        price_high: data.price,
        unit: data.unit,
        quantity: data.quantity,
        valid_upto_date: data.valid_upto_date,
        vendor_product_category_id: vpcId
      };
      let response = await axiosInstance.post(postApi, bodyData, {
        params: { vendorProductCategoryId: vpcId, isRRQ: true, order_id: orderId }
      });
      console.log(response);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 w-[800px]">
        <div className="flex justify-between pb-[10px]">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <div className="mt-2 mb-0">
            <Typography variant="h6" component="h5">
              Quotation
            </Typography>
          </div>
          <div className="flex flex-row gap-4 items-end">
            <Typography sx={{ width: 1 }} variant="subtitle1">
              {"Required Quantity = "}
            </Typography>
            <TextField
              disabled={user_team === "demand" ? true : false}
              value={data.quantity}
              sx={{ width: "100%" }}
              id="outlined-basic"
              placeholder="Quantity"
              variant="standard"
              onChange={(event) => {
                setData((prev) => ({
                  ...prev,
                  quantity: event.target.value
                }));
              }}
            />
            <FormControl fullWidth variant="standard" value={data.unit}>
              <InputLabel>Unit</InputLabel>
              <Select
                disabled={user_team === "demand" ? true : false}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={data.unit}
                label="Unit"
                onChange={(event) =>
                  setData((prev) => ({
                    ...prev,
                    unit: event.target.value
                  }))
                }>
                {units.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              = ₹
            </Typography>
            <TextField
              disabled={user_team === "demand" ? true : false}
              value={data.price}
              sx={{ width: "100%" }}
              id="outlined-basic"
              placeholder="Price"
              variant="standard"
              type={"number"}
              onChange={(event) => {
                setData((prev) => ({
                  ...prev,
                  price: event.target.value
                }));
              }}
            />
            <Typography variant="subtitle1">/KG</Typography>
          </div>
          <div className="my-2 mt-3 flex flex-row space-x-6">
            <Typography sx={{ whiteSpace: "nowrap" }}>Incoterm = </Typography>
            <TextField
              disabled
              value={data.incoterm}
              sx={{ width: "100%" }}
              id="outlined-basic"
              placeholder="Incoterm"
              variant="standard"
            />
          </div>
          <div className="my-2 mt-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={user_team === "demand" ? true : false}
                value={data.valid_upto_date}
                name="valid_upto_date"
                label="Valid Upto Date"
              />
            </LocalizationProvider>
          </div>
          {user_team === "demand" && (
            <div className="my-3 flex flex-col space-y-3">
              <Button fullWidth variant="contained" color="primary" onClick={approveRRQ}>
                Approve
              </Button>
              <Button fullWidth variant="contained" color="primary" onClick={rejectRRQ}>
                Reject
              </Button>
            </div>
          )}
          {user_team === "supply" && (
            <div className="my-3">
              <Button fullWidth variant="contained" color="primary" onClick={saveRRQ}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RevisedQuotationModal;
