import { Navigate, Route } from "react-router-dom";
import DocumentGeneratorSection from "./DocumentGenerator";
import TDSCompareSection from "./TDSCompare";
const ReRouteComponent = () => {
  <Navigate to="/dashboard/tools/doc-generation" />;
};

export const toolsRoutes = [
  {
    route: "",
    Component: ReRouteComponent
  },
  {
    route: "doc-generation",
    Component: DocumentGeneratorSection
  },
  {
    route: "tds-compare",
    Component: TDSCompareSection
  }
];

export default () => {
  return (
    toolsRoutes &&
    toolsRoutes.map(({ Component, route, nestedRoutes }) => (
      <Route key={route} path={route} element={<Component />}>
        {nestedRoutes && nestedRoutes()}
      </Route>
    ))
  );
};
