import { Button, FormHelperText, IconButton, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../core/apiInstance";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";

const DispatchInfoModal = ({
  open,
  onClose,
  updateApi,
  getApi,
  fetchParent,
  modalTitle,
  unloadingDateRequired,
  dispatchReceiptApi
}) => {
  const [, setRefresh] = useState(false);
  const schema = yup.object().shape({
    dispatchReceiptDocumentUrl: yup.string(),
    expectedDeliveryDate: yup.date(),
    awbNumber: yup.string(),
    courierPartner: yup.string(),
    otherInfo: yup.string(),
    unloadingDate: yup.date()
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      expectedDeliveryDate: dayjs(new Date())
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    fetchDispatchInfoIfExist();
  }, []);

  const fetchDispatchInfoIfExist = async () => {
    try {
      const response = await axiosInstance.get(getApi);
      if (response.data) {
        reset({
          expectedDeliveryDate: dayjs(response.data.expectedDeliveryDate),
          awbNumber: response.data.awbNumber,
          courierPartner: response.data.courierPartner,
          dispatchReceiptDocumentUrl: response.data.dispatchReceiptDocumentUrl
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadDispatchReceipt = async (e) => {
    if (e.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await axiosInstance.post(dispatchReceiptApi, formData);
      if (response.data?.url) {
        setValue("dispatchReceiptDocumentUrl", response.data.url);
        setRefresh((value) => !value);
      }
      // update state
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    try {
      await axiosInstance.post(updateApi, { dispatchInfo: data });
      fetchParent && fetchParent();
      onClose();
    } catch (err) {
      console.log(data);
    }
  };

  const downloadDispatchReceiptDocument = () => {
    window.open(getValues("dispatchReceiptDocumentUrl"));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {modalTitle}
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[500px]">
          <div className="flex flex-col justify-center space-y-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Expected Delivery Date */}
              <div className="flex flex-row items-center justify-start space-x-3 mb-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="expectedDeliveryDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disablePast
                        value={value}
                        onChange={onChange}
                        name="expectedDeliveryDate"
                        label="Expected Delivery Date"
                      />
                    )}
                  />
                  {errors.valid_upto_date?.message && (
                    <FormHelperText error>{errors.valid_upto_date?.message}</FormHelperText>
                  )}
                </LocalizationProvider>
                <p className="text-red-500">{errors.expectedDeliveryDate?.message}</p>
              </div>
              {/* AWB Number */}
              <div className="my-8 flex">
                <Button fullWidth color="primary" variant="outlined" component="label">
                  Upload Dispatch receipt File
                  <input type="file" hidden onChange={(e) => uploadDispatchReceipt(e)} />
                </Button>
                {Boolean(getValues("dispatchReceiptDocumentUrl")) && (
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    component="label"
                    onClick={downloadDispatchReceiptDocument}>
                    Download Dispatch receipt File
                  </Button>
                )}
              </div>
              {unloadingDateRequired && (
                <div className="flex flex-row items-center justify-start space-x-3 mb-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="unloadingDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          disablePast
                          value={value}
                          onChange={onChange}
                          name="unloadingDate"
                          label="Unloading Date"
                        />
                      )}
                    />
                    {errors.valid_upto_date?.message && (
                      <FormHelperText error>{errors.valid_upto_date?.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                </div>
              )}
              <Divider>OR</Divider>
              <div className="flex flex-col justify-center space-y-3 mt-6">
                <Controller
                  name="awbNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField variant="standard" {...field} label="AWB Number" />
                  )}
                />
                <p className="text-red-500">{errors.awbNumber?.message}</p>

                <Controller
                  name="courierPartner"
                  control={control}
                  render={({ field }) => (
                    <TextField variant="standard" {...field} label="Courier Partner" />
                  )}
                />
                <p className="text-red-500">{errors.courierPartner?.message}</p>

                <Controller
                  name="otherInfo"
                  control={control}
                  render={({ field }) => (
                    <TextField variant="standard" {...field} label="Other Info" />
                  )}
                />
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Upload
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DispatchInfoModal;
