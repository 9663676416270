import { useEffect, useState } from "react";
import { Button, Tabs, Tab, Typography } from "@mui/material";
import DraftOrders from "./DraftOrders";
import RunningOrders from "./RunnigOrders";
import OnHold from "./OnHold";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../core/apiInstance";
import Cancelled from "./Cancelled";
import Completed from "./Completed";
// import { useNavigate } from "react-router-dom";

// eslint-disable-next-line no-undef
//const isDevelopment = process.env.NODE_ENV === "development";

const ProjectProductsOrders = () => {
  const [orderParent, setOrderParent] = useState({});
  const [tabValue, setTabValue] = useState("DRAFT_ORDERS");
  const [refresh, setRefresh] = useState(false);
  const params = useParams();
  //  const { user } = useAuthStore();
  //   const isAdmin = user?.userTeams?.find((team) => team.team_name === "ADMIN");
  //   const isDemandSide = user?.userTeams?.find((team) => team.team_name === "DEMAND");
  //   const isSupplySide = user?.userTeams?.find((team) => team.team_name === "SUPPLY");
  //   const productColumns = [];

  useEffect(() => {
    fetchOrderParent();
  }, []);

  const fetchOrderParent = async () => {
    try {
      const { orderId, projectId } = params;
      const response = await axiosInstance.get(
        `/api/ppm/order-actions/projects/${projectId}/projects_products_categories/${orderId}/order-parent-details`
      );

      setOrderParent({
        ...response.data
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const repeatOrder = async () => {
    const { orderId } = params;
    await axiosInstance
      .post(`/api/ppm/order-actions/object/${orderId}/repeat-order`)
      .then((response) => {
        console.log(response);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const dueDateTimeFormat = isDevelopment ? "YYYY-MM-DD HH:mm A" : "YYYY-MM-DD";

  return (
    <div className="h-[calc(100vh-200px)] w-[calc(100vw-290px)]">
      <div className="flex justify-between my-4 w-full">
        <Typography variant="h5" gutterBottom>
          {orderParent.name} - {orderParent.product_name}
        </Typography>
        <Button color="primary" variant="contained" component="label" onClick={repeatOrder}>
          Repeat Order
        </Button>
      </div>
      <div className="overflow-x-auto">
        <Tabs value={tabValue} onChange={handleTabValueChange} centered sx={{ width: "100%" }}>
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="DRAFT_ORDERS" label={"Draft Orders"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="RUNNING" label={"Running"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="ON_HOLD" label={"On Hold"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="CANCELLED" label={"Cancelled"} />
          <Tab sx={{ width: "20%", maxWidth: "20%" }} value="COMPLETED" label={"Completed"} />
        </Tabs>
        {tabValue === "DRAFT_ORDERS" && <DraftOrders refresh={refresh} />}
        {tabValue === "RUNNING" && <RunningOrders />}
        {tabValue === "ON_HOLD" && <OnHold />}
        {tabValue === "CANCELLED" && <Cancelled />}
        {tabValue === "COMPLETED" && <Completed />}
      </div>
    </div>
  );
};

export default ProjectProductsOrders;
