import { Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import VendorProductsModal from "./VendorProductsModal";
import VendorViewDetailsModal from "./VendorViewDetailsModal";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import GetDocumentLinkModal from "../../../../components/GetDocumentLinkModal";
import { useShowAlertStore } from "../../../../state/alert.state";
import { saveData } from "../../../../core/utilsFunctions";
import { axiosInstance } from "../../../../core/apiInstance";

const VendorsSection = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ result: [] });
  const [showProductsModal, setProductModal] = useState({ id: null, show: false });
  const [showViewDetailsModal, setViewDetailsModal] = useState({ id: null, show: false });
  const [showGetDocumentLinkModal, setGetDocumentLinkModal] = useState(false);
  const { showAlert } = useShowAlertStore();
  const [filter, setFilter] = useState({
    pagination: {
      pageSize: 10,
      page: 0
    },
    sort: []
  });

  useEffect(() => {
    fetchVendors();
  }, [filter]);

  const fetchVendors = () => {
    setLoading(true);
    axiosInstance
      .get("/api/vendors", { params: filter })
      .then((response) => {
        setData({ result: response.data.result, count: response.data.count });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFilterChange = (type, model) => {
    if (type === "pagination") {
      setFilter((filter) => ({ ...filter, pagination: { ...model } }));
    } else if (type === "sort") {
      setFilter((filter) => ({ ...filter, sort: [model[0]] }));
    }
  };

  const handleDownloadSampleVendorProductListCsv = () => {
    axiosInstance
      .post("/api/vendor-products/download-sample-vendor-product-list-sample")
      .then((res) => {
        saveData(res.data, "sample-vendor-product-list.csv");
      })
      .catch((err) => console.log(err));
  };

  const uploadBulkVendorProductList = (event) => {
    if (event.target.files[0]) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);

      axiosInstance
        .post("/api/vendor-products/upload-bulk-vendor-products-list", bodyFormData)
        .then((response) => {
          showAlert({
            open: true,
            message: "Uploaded Successfully",
            severity: "success"
          });
          fetchVendors();
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          return setProductModal({ id: params.row.id, show: true });
        };

        return (
          <Button color="primary" variant="contained" onClick={onClick}>
            Show products ({params.row.product_count})
          </Button>
        );
      },
      width: 200
    },
    // {
    //   field: "show_details",
    //   headerName: "Details",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation();
    //       return setViewDetailsModal({ id: params.row.id, show: true });
    //     };

    //     return (
    //       <Button color="primary" variant="contained" onClick={onClick}>
    //         View details
    //       </Button>
    //     );
    //   },
    //   width: 150
    // },
    {
      field: "id",
      headerName: "id",
      width: 80
    },
    { field: "name", headerName: "name", width: 300 },
    { field: "point_of_contact_name", headerName: "Point of contact name", width: 250 },
    {
      field: "point_of_contact_phone_number",
      headerName: "Point of contact phone number",
      width: 250
    },
    { field: "point_of_contact_email", headerName: "Point of contact phone email", width: 250 }
  ];

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Vendors Products
        </Typography>
        <div className="ml-5 flex items-center gap-3">
          <Button
            color="primary"
            variant="outlined"
            onClick={handleDownloadSampleVendorProductListCsv}>
            Download sample bulk vendor product list csv
          </Button>
          <Button color="primary" variant="outlined" component="label">
            Upload bulk vendor product list csv
            <input type="file" hidden onChange={(e) => uploadBulkVendorProductList(e)} />
          </Button>
        </div>
      </div>
      <div className="h-full">
        <DataGrid
          rowSelection={false}
          rows={data.result}
          columns={columns}
          loading={loading}
          rowCount={data.count}
          pageSizeOptions={[10, 50, 100]}
          disableMultipleColumnsSorting
          paginationMode="server"
          paginationModel={filter.pagination}
          onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
          sortingMode="server"
          sortModel={filter.model}
          onSortModelChange={(sort) => handleFilterChange("sort", sort)}
          disableColumnMenu
          editMode="row"
          slots={{
            toolbar: DataGridCustomToolbar,
            loadingOverlay: LinearProgress
          }}
        />
      </div>
      <VendorProductsModal
        open={showProductsModal.show}
        id={showProductsModal.id}
        handleClose={() => setProductModal({ show: false, id: null })}
      />
      <VendorViewDetailsModal
        open={showViewDetailsModal.show}
        id={showViewDetailsModal.id}
        handleClose={() => setViewDetailsModal({ show: false, id: null })}
      />
      <GetDocumentLinkModal
        open={showGetDocumentLinkModal}
        handleClose={() => setGetDocumentLinkModal(false)}
      />
    </div>
  );
};

export default VendorsSection;
