import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ExistingSupplierModal from "./ExistingSupplierModal";
import { axiosInstance } from "../../../../core/apiInstance";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";

const SelectSupplierModal = ({
  onClose,
  setProductDocumentsModal,
  setShowIncotermModal,
  supplyTeam,
  segment,
  setShowAddQuotationModal,
  handleSelectVendor,
  projectsProductsCategoriesId,
  vendorProductCategoryId,
  refresh
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showExistingSupplierModal, setShowExistingSupplierModal] = useState({
    open: false,
    projectsProductsCategoriesId: null
  });

  useEffect(() => {
    fetchQuotations();
  }, []);

  useEffect(() => {
    fetchQuotations();
  }, [refresh]);

  const fetchQuotations = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/ppm/quotations/segments", {
        params: {
          supplyTeam,
          segment,
          projectsProductsCategoriesId,
          onlyQuery: true
        }
      });
      setLoading(false);
      setData(response.data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            disabled={!params.row?.isSelectable}
            fullWidth
            color="primary"
            variant="contained"
            component="label"
            onClick={() =>
              handleSelectVendor(
                projectsProductsCategoriesId,
                params.row.vendors_products_categories_id
              )
            }>
            Select
          </Button>
        );
      }
    },
    {
      field: "task",
      headerName: "Task",
      sortable: false,
      width: 200
    },
    {
      field: "quotations",
      headerName: "Quotations",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              setShowAddQuotationModal({
                open: true,
                id: null,
                activityId: null,
                vendorProductCategoryId: params.row.vendors_products_categories_id
              });
            }}>
            {params.row.quotation_valid_upto_date ? "Update" : "Add"} Quotation
          </Button>
        );
      }
    },
    {
      field: "vendor_name",
      headerName: "Vendor's name",
      sortable: false,
      width: 150
    },
    {
      field: "quotation_price_per_kg",
      headerName: "Price/kg",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.quotation_price_per_kg ? (
              <div className="flex items-center">
                <Typography variant="body1" className="mr-2">
                  {params.row.quotation_price_per_kg}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    {
      field: "quotation_valid_upto_date",
      headerName: "Valid upto",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="w-full overflow-y-auto">
            {params.row.quotation_valid_upto_date
              ? dayjs(params.row.quotation_valid_upto_date).diff(new Date(), "day", false) +
                " days left"
              : ""}
          </div>
        );
      }
    },
    {
      filed: "document_action",
      headerName: "Documents",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              console.log(params.row);
              setProductDocumentsModal({
                open: true,
                vendorProductCategoryId:
                  params.row?.vendors_products_categories_id ||
                  params.row?.final_vendor_product_category_id
              });
            }}>
            View Documents
          </Button>
        );
      }
    },
    {
      field: "incoterm",
      headerName: "Incoterm",
      width: 200,
      renderCell: (params) => {
        return <div className="w-full overflow-y-auto">{params.row.incoterm || ""}</div>;
      }
    },
    {
      field: "action_3",
      headerName: "Add/Update Incoterm",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowIncotermModal({
                open: true,
                vendorProductCategoryId:
                  params.row.final_vendor_product_category_id ||
                  params.row.vendors_products_categories_id
              })
            }>
            {params.row.incoterm ? "Update" : "Add"} Incoterm
          </Button>
        );
      }
    },
    {
      field: "action_sending_mail",
      headerName: "Re Evaluate",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            disabled={params.row.is_sent}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              sendMailToVendor(
                projectsProductsCategoriesId,
                params.row.vendors_products_categories_id
              )
            }>
            {params.row.is_sent ? "Mail Sent" : "Send Mail"}
          </Button>
        );
      }
    },
    {
      field: "mail_sent_at",
      headerName: "Mail Sent At",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return params.row.mail_sent_at ? (
          <div>Mail sent at {dayjs(params.row.mail_sent_at).format("DD/MM/YYYY hh:mm A")}</div>
        ) : (
          ""
        );
      }
    }
  ];

  const sendMailToVendor = async (projectsProductsCategoriesId, vendorProductCategoryId) => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${projectsProductsCategoriesId}/vendors/${vendorProductCategoryId}/send-mail`
      );
      fetchQuotations();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Select Supplier Modal
              </Typography>
            </div>
            <Button
              variant="outlined"
              disabled={Boolean(vendorProductCategoryId)}
              onClick={() =>
                setShowExistingSupplierModal({
                  open: true,
                  projectsProductsCategoriesId
                })
              }>
              Search from existing Supplier & Add
            </Button>
            <div className="ml-[10px]">
              <Button
                variant="outlined"
                disabled={Boolean(vendorProductCategoryId)}
                onClick={() => {
                  setShowAddQuotationModal({
                    open: true,
                    vendorProductCategoryId: null,
                    id: projectsProductsCategoriesId
                  });
                }}>
                Add Supplier
              </Button>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[820px] h-[480px]">
          <DataGrid
            hideFooter
            rows={data}
            loading={loading}
            disableColumnMenu
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
            columns={vendorProductCategoryId ? columns.slice(2) : columns}
          />
        </div>
        {showExistingSupplierModal.open && (
          <ExistingSupplierModal
            open={showExistingSupplierModal.open}
            onClose={() =>
              setShowExistingSupplierModal({ open: false, projectsProductsCategoriesId: null })
            }
            projectsProductsCategoriesId={showExistingSupplierModal.projectsProductsCategoriesId}
            fetchParent={fetchQuotations}
          />
        )}
      </div>
    </Modal>
  );
};

export default SelectSupplierModal;
