import { Box, Button, Modal, TextField, Typography, Select, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { useAuthStore } from "../../../../state/auth.state";
import { useShowAlertStore } from "../../../../state/alert.state";

const RequestForSampleInfoModal = ({ open, onClose, id, projectId }) => {
  //const [sampleInfo, setSampleInfo] = useState();
  const { user } = useAuthStore();
  const { showAlert } = useShowAlertStore();

  const [formData, setFormData] = useState({
    quantity: "",
    unit: "",
    poc_name: "",
    poc_contact: "",
    poc_email: "",
    poc_details: "",
    delivery_location: "",
    packaging: "",
    other_info: ""
  });

  const sendFeedbackViaTickets = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(
        `/api/ppm/project-actions/project-products-categories/${id}/request-for-sample-from-demand`,
        { sampleRequestInfo: formData, user_id: user.id, user_team: "DEMAND" }
      );
      console.log(response);
      if (!response.data.tds_present) {
        showAlert({
          open: true,
          message: "Please Upload the Client TDS as soon as possible",
          severity: "error"
        });
      }
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    getDetails(id);
  }, []);
  const getDetails = async () => {
    let response = await axiosInstance.get(`/api/ppm/projects/object/${projectId}`);
    setFormData({
      ...formData,
      poc_name: response.data.point_of_contact_name,
      poc_contact: response.data.point_of_contact_phone_number,
      poc_email: response.data.point_of_contact_email
    });
    console.log(response.data);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-center pb-[20px]">
          <Typography variant="h5">Request For Sample Info</Typography>
        </div>
        <Box className="h-fit w-[700px]">
          <form onSubmit={() => {}}>
            <div className="flex flex-row space-x-3">
              <TextField
                label="Quantity"
                name="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <Select
                fullWidth
                name="unit"
                variant="standard"
                label="Unit"
                id="demo-simple-select-standard"
                value={formData.unit}
                onChange={handleInputChange}>
                <MenuItem value={"MT"}>MT</MenuItem>
                <MenuItem value={"KG"}>kg</MenuItem>
                <MenuItem value={"G"}>gram</MenuItem>
                <MenuItem value={"LT"}>litre</MenuItem>
                <MenuItem value={"ML"}>Milli Litre</MenuItem>
              </Select>
            </div>
            <TextField
              label="POC Name"
              name="poc_name"
              value={formData.poc_name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <div className="flex flex-row space-x-3">
              <TextField
                label="POC Contact"
                name="poc_contact"
                value={formData.poc_contact}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="POC Email"
                name="poc_email"
                value={formData.poc_email}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </div>
            <TextField
              label="Delivery Location"
              name="delivery_location"
              value={formData.delivery_location}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Packaging"
              name="packaging"
              value={formData.packaging}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Other Info"
              name="other_info"
              value={formData.other_info}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Button
              fullWidth
              className="top-2 bottom-2"
              variant="contained"
              color="primary"
              type="submit"
              onClick={sendFeedbackViaTickets}>
              Request For Sample
            </Button>
          </form>
        </Box>
      </div>
    </Modal>
  );
};

export default RequestForSampleInfoModal;
