import { Route } from "react-router-dom";
import LoginPage from "./Login";

export const authRoutes = [
  {
    route: "",
    Component: "div"
  },
  {
    label: "Login",
    route: "login",
    Component: LoginPage
  }
];

export default () =>
  authRoutes.map(({ Component, route, nestedRoutes }) => (
    <Route key={route} path={route} element={<Component />}>
      {nestedRoutes && nestedRoutes()}
    </Route>
  ));
