import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, LinearProgress } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { segments } from "../../../../core/constants";
import SelectSupplierModal from "../../Supply/common/SelectSupplierModal";
import ProductDocumentModal from "../../../../components/ProductDocumentModal";
import AddUpdateIncotermModal from "../../../../components/AddUpdateIncotermModal";
import AddQuotationModal from "../../Orders/common/AddQuotationModal";
import { useAuthStore } from "../../../../state/auth.state";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../../../core/apiInstance";
import { useShowAlertStore } from "../../../../state/alert.state";

// eslint-disable-next-line no-undef
const isDevelopment = process.env.NODE_ENV === "development";

const RFQDetails = ({
  rfqData,
  onMarkAsRegret,
  onMarkAsNVD,
  fetchParent,
  onMarkAsCustomization
}) => {
  const [showSelectSupplierModal, setShowSelectSupplierModal] = useState({
    open: false,
    projectsProductsCategoriesId: null,
    vendorProductCategoryId: null,
    segment: null
  });
  const [showAddQuotationModal, setShowAddQuotationModal] = useState({
    id: null,
    activityId: null,
    vendorProductCategoryId: null,
    open: false
  });
  const [showProductDocumentsModal, setProductDocumentsModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });
  const [showIncotermModal, setShowIncotermModal] = useState({
    open: false,
    vendorProductCategoryId: null
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const { showAlert } = useShowAlertStore();
  const { user } = useAuthStore();
  const isAdmin =
    user && user.userTeams && user.userTeams.find((item) => item.team_name === "ADMIN");

  const dueDateTimeFormat = isDevelopment ? "YYYY-MM-DD HH:mm A" : "YYYY-MM-DD";

  let initialColumns = [
    {
      field: "status",
      headerName: "Status",
      width: 300,
      renderCell: (params) => {
        return <div>{segments[params.row.status]}</div>;
      }
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,

      renderCell: (params) => {
        return params.row.created_at ? (
          <div>{dayjs(params.row.created_at).format(dueDateTimeFormat)}</div>
        ) : (
          ""
        );
      }
    }
  ];

  const [columns, setColumns] = useState(initialColumns);

  const FINALIZED_SUPPLIER_AND_PRICES_columns = [
    {
      field: "view_suppliers",
      headerName: "View Suppliers",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowSelectSupplierModal({
                open: true,
                projectsProductsCategoriesId: params.row.id,
                vendorProductCategoryId: params.row.vendors_products_category_id,
                segment: params.row.status
              })
            }>
            View Suppliers
          </Button>
        );
      }
    }
  ];

  const FINALIZE_SUPPLIER_ADD_DATA_POINT_columns = [
    {
      field: "view_suppliers",
      headerName: "View Suppliers",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowSelectSupplierModal({
                open: true,
                projectsProductsCategoriesId: params.row.id,
                segment: params.row.status
              })
            }>
            View Suppliers
          </Button>
        );
      }
    },
    {
      field: "regret",
      headerName: "Regret",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          params.row.product_name && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              disabled={params.row.raise_as_regret}
              onClick={() => onMarkAsRegret(params.row.id)}>
              {params.row.is_regret_product
                ? "Regret Approved"
                : params.row.raise_as_regret
                ? "Raised Regret to Admin"
                : "Mark as regret"}
            </Button>
          )
        );
      }
    },
    {
      field: "nvd",
      headerName: "NVD",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            disabled={params.row.raise_as_new_vendor_development}
            onClick={() => onMarkAsNVD(params.row.id)}>
            {params.row.is_new_vendor_development
              ? "NVD Approved"
              : params.row.raise_as_new_vendor_development
              ? "Raised NVD to Admin"
              : "Mark as NVD"}
          </Button>
        );
      }
    }
  ];

  const NEW_VENDOR_DEVELOPMENT_columns = [
    {
      field: "view_suppliers",
      headerName: "View Suppliers",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowSelectSupplierModal({
                open: true,
                projectsProductsCategoriesId: params.row.id,
                segment: params.row.status
              })
            }>
            View Suppliers
          </Button>
        );
      }
    },
    {
      field: "regret",
      headerName: "Regret",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          params.row.product_name && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              disabled={params.row.raise_as_regret}
              onClick={() => onMarkAsRegret(params.row.id)}>
              {params.row.is_regret_product
                ? "Regret Approved"
                : params.row.raise_as_regret
                ? "Raised Regret to Admin"
                : "Mark as regret"}
            </Button>
          )
        );
      }
    }
  ];

  const commonColumns = [
    {
      field: "raise_as_customization",
      headerName: "Raise as Customization",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          params.row.product_name && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              component="label"
              disabled={params.row.raise_as_customization || params.row.disable_for_customization}
              onClick={() => onMarkAsCustomization(params.row.id)}>
              {params.row.is_customization
                ? "Customization Approved"
                : params.row.raise_as_customization
                ? "Raised Customization to Admin"
                : "Mark as Customization"}
            </Button>
          )
        );
      }
    }
  ];

  useEffect(() => {
    console.log(rfqData);
    let newColumns = [];
    if (rfqData && rfqData[0] && rfqData[0].status) {
      if (rfqData[0].status === "NEW_VENDOR_DEVELOPMENT") {
        newColumns = [...initialColumns, ...NEW_VENDOR_DEVELOPMENT_columns, ...commonColumns];
      } else if (rfqData[0].status === "FINALIZE_SUPPLIER_ADD_DATA_POINT") {
        newColumns = [
          ...initialColumns,
          ...FINALIZE_SUPPLIER_ADD_DATA_POINT_columns,
          ...commonColumns
        ];
      } else if (rfqData[0].status === "FINAL_SUPPLIER_PRICE_AVAILABLE") {
        newColumns = [...initialColumns, ...FINALIZED_SUPPLIER_AND_PRICES_columns];
      }

      setColumns(newColumns);
    }

    if (searchParams.get("supplyTeam") === null && !isAdmin) {
      handleChangeSearchParams("supplyTeam", user.id);
    }
  }, [rfqData]);

  const handleChangeSearchParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSelectVendor = async (projectsProductsCategoriesId, vendorProductCategoryId) => {
    try {
      await axiosInstance.post(
        `/api/ppm/projects/project-product-category/${projectsProductsCategoriesId}/move-to-final`,
        { vendorProductCategoryId }
      );
      fetchParent && fetchParent();
      setShowSelectSupplierModal({
        open: false,
        status: null,
        projectsProductsCategoriesId: null,
        vendorProductCategoryId: null
      });
      showAlert({ open: true, message: "Quotes Submitted TO DEMAND", severity: "success" });
    } catch (err) {
      console.log(vendorProductCategoryId);
    }
  };

  const handleRefreshSelectSupplier = () => {
    setShowSelectSupplierModal({
      ...showSelectSupplierModal,
      refresh: !showSelectSupplierModal.refresh
    });
    fetchParent();
  };

  const handleRefreshOnNewSupplierSelectSupplier = () => {
    setShowSelectSupplierModal({
      ...showSelectSupplierModal,
      segment: "FINAL_SUPPLIER_PRICE_AVAILABLE",
      refresh: !showSelectSupplierModal.refresh
    });
    fetchParent();
  };

  return (
    <div className="h-[400px] w-full mb-4">
      <DataGrid
        rowSelection={false}
        rows={rfqData}
        columns={columns}
        //loading={loading}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        // onPaginationModelChange={(pagination) => handleFilterChange("pagination", pagination)}
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showSelectSupplierModal.open) && (
        <SelectSupplierModal
          open={showSelectSupplierModal.open}
          onClose={() =>
            setShowSelectSupplierModal({
              open: false,
              projectsProductsCategoriesId: null,
              vendorProductCategoryId: null,
              status: null
            })
          }
          vendorProductCategoryId={showSelectSupplierModal.vendorProductCategoryId}
          projectsProductsCategoriesId={showSelectSupplierModal.projectsProductsCategoriesId}
          fetchParent={fetchParent}
          setProductDocumentsModal={setProductDocumentsModal}
          setShowIncotermModal={setShowIncotermModal}
          supplyTeam={isAdmin ? searchParams.get("supplyTeam") : user.id}
          segment={showSelectSupplierModal.segment}
          setShowAddQuotationModal={setShowAddQuotationModal}
          handleSelectVendor={handleSelectVendor}
          refresh={showSelectSupplierModal.refresh}
        />
      )}
      {Boolean(showAddQuotationModal.open) && (
        <AddQuotationModal
          fetchParent={handleRefreshOnNewSupplierSelectSupplier}
          open={showAddQuotationModal.open}
          id={showAddQuotationModal.id}
          vendorProductCategoryId={showAddQuotationModal.vendorProductCategoryId}
          activityId={showAddQuotationModal.activityId}
          onClose={() =>
            setShowAddQuotationModal({
              open: false,
              id: null,
              activityId: null,
              vendorProductCategoryId: null
            })
          }
        />
      )}
      {Boolean(showIncotermModal.open) && (
        <AddUpdateIncotermModal
          open={showIncotermModal.open}
          onClose={() => setShowIncotermModal({ open: false, vendorProductCategoryId: null })}
          vendorProductCategoryId={showIncotermModal.vendorProductCategoryId}
          fetchParent={handleRefreshSelectSupplier}
        />
      )}
      {Boolean(showProductDocumentsModal.open) && (
        <ProductDocumentModal
          open={open}
          onClose={() => setProductDocumentsModal({ open: false, vendorProductCategoryId: null })}
          vendorProductCategoryId={showProductDocumentsModal.vendorProductCategoryId}
          fetchParent={handleRefreshSelectSupplier}
        />
      )}
    </div>
  );
};

export default RFQDetails;
