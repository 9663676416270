import { Button, LinearProgress } from "@mui/material";
import { dateFormat } from "../../../../../core/constants";
import DataGridCustomToolbar from "../../../../../components/DataGridCustomToolbar";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../../core/apiInstance";
import { useShowAlertStore } from "../../../../../state/alert.state";
import FeedbackModal from "../../../Orders/Summary/FeedbackModal";

const RunningOrdersPO = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState({
    open: false,
    id: null,
    date: ""
  });
  const { showAlert } = useShowAlertStore();

  const columns = [
    // {
    //   field: "raw_material_date",
    //   headerName: "Expected Raw Material Date",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.raw_material_audit_at ? (
    //       <div>{dayjs(params.row.raw_material_audit_at).format(dateFormat)}</div>
    //     ) : null;
    //   }
    // },
    // {
    //   field: "raw_material_audit",
    //   headerName: "Raw Material Audit",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.actual_raw_material_audit_at ? (
    //       <div>{dayjs(params.row.actual_raw_material_audit_at).format(dateFormat)}</div>
    //     ) : (
    //       <div className="flex space-x-3">
    //         <Button
    //           fullWidth
    //           color="success"
    //           variant="contained"
    //           onClick={(event) => handleUploadActualRawMaterialAuditDate(event, params.row.id)}>
    //           ON TIME
    //         </Button>
    //         <Button
    //           fullWidth
    //           color="error"
    //           variant="contained"
    //           onClick={() =>
    //             setShowFeedbackModal({
    //               open: true,
    //               id: params.row.id,
    //               date: "raw_material_audit_date"
    //             })
    //           }>
    //           DELAYED
    //         </Button>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "mid_process_date",
    //   headerName: "Expected Mid Process Date",
    //   width: 200,
    //   renderCell: (params) => {
    //     return params.row.mid_process_at ? (
    //       <div>{dayjs(params.row.mid_process_at).format(dateFormat)}</div>
    //     ) : null;
    //   }
    // },
    // {
    //   field: "mid_process_audit",
    //   headerName: "Mid Process Audit",
    //   width: 250,
    //   renderCell: (params) => {
    //     return params.row.actual_mid_process_at ? (
    //       <div>{dayjs(params.row.actual_mid_process_at).format(dateFormat)}</div>
    //     ) : (
    //       <div className="flex space-x-3">
    //         <Button
    //           fullWidth
    //           color="success"
    //           variant="contained"
    //           onClick={(e) => handleUploadActualMidProcessDate(e, params.row.id)}>
    //           ON TIME
    //         </Button>
    //         <Button
    //           fullWidth
    //           color="error"
    //           variant="contained"
    //           onClick={() =>
    //             setShowFeedbackModal({
    //               open: true,
    //               id: params.row.id,
    //               date: "mid_process_date"
    //             })
    //           }>
    //           DELAYED
    //         </Button>
    //       </div>
    //     );
    //   }
    // },
    {
      field: "pss_ready_date",
      headerName: "PSS Ready Date",
      width: 200,
      renderCell: (params) => {
        return params.row.pss_ready_date ? (
          <div>{dayjs(params.row.pss_ready_date).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "pss_document_upload",
      headerName: "PSS Document Upload",
      width: 220,
      renderCell: (params) => {
        return (
          <Button fullWidth color="primary" variant="outlined" component="label">
            {params.row.qa_assigned_at ? "RE-UPLOAD" : "UPLOAD"}
            <input
              type="file"
              hidden
              onChange={(event) => handleUploadCOA(event, params.row.vpcid, params.row.id)}
            />
          </Button>
        );
      }
    },
    {
      field: "pss_delivery_date",
      headerName: "PSS Delivery Date",
      width: 200,
      renderCell: (params) => {
        return params.row.pss_delivery_at ? (
          <div>{dayjs(params.row.pss_delivery_at).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "request_for_qa_date",
      headerName: "Request For QA Date",
      width: 200,
      renderCell: (params) => {
        return params.row.qa_assigned_at ? (
          <div>{dayjs(params.row.qa_assigned_at).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "approved_from_QA",
      headerName: "Approved from QA",
      width: 200,
      renderCell: (params) => {
        return params.row.qa_assigned_at
          ? params.row.is_qa_approved_pss
            ? "APPROVED"
            : "PENDING"
          : null;
      }
    },
    {
      field: "logistics_for_pss_supplier_to_covvalent",
      headerName: "Logistics for PSS Supplier to Covvalent",
      width: 300,
      renderCell: (params) => {
        return params.row.logistics_type
          ? params.row.logistics_type === "PSS_SUPPLIER_TO_COVVALENT"
            ? "IN PROGRESS"
            : "DONE"
          : null;
      }
    },
    {
      field: "logistics_for_pss_covvalent_to_buyer",
      headerName: "Logistics for PSS Covvalent to Buyer",
      width: 300,
      renderCell: (params) => {
        return params.row.logistics_type
          ? params.row.logistics_type === "PSS_COVVALENT_TO_BUYER"
            ? !params.row.buyer_feedback_date
              ? "IN PROGRESS"
              : "DONE"
            : params.row.logistics_type === "BULK" && "DONE"
          : null;
      }
    },
    {
      field: "pss_feedback",
      headerName: "PSS Feedback",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            disabled={params.row.pss_feedback_report ? false : true}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => window.open(params.row.pss_feedback_report)}>
            {params.row.pss_feedback_report ? "Download File" : "Not Available"}
          </Button>
        );
      }
    },
    {
      field: "logistics_for_bulk",
      headerName: "Logistics For Bulk",
      width: 200,
      renderCell: (params) => {
        return params.row.logistics_type === "BULK" && "IN PROGRESS";
      }
    },
    {
      field: "actual_delivery_date",
      headerName: "Actual Delivery Date",
      width: 200,
      renderCell: (params) => {
        return params.row.expected_delivery_date ? (
          <div>{dayjs(params.row.expected_delivery_date).format(dateFormat)}</div>
        ) : null;
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  const fetchDraftOrders = async () => {
    const { id } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${id}/orders?state=RUNNING`
      );
      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleUploadRawMaterialAuditReport = async (event, id) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const bodyFormData = new FormData();
  //     bodyFormData.append("file", event.target.files[0]);
  //     await axiosInstance
  //       .post(
  //         `/api/ppm/projects-products-categories/upload-raw-material-audit-report/${id}`,
  //         bodyFormData
  //       )
  //       .then((response) => {
  //         setData((preValue) => ({
  //           ...preValue,
  //           raw_material_audit_report: response.data.url
  //         }));
  //         fetchDraftOrders();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // const handleUploadMidProcessReport = async (event, id) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const bodyFormData = new FormData();
  //     bodyFormData.append("file", event.target.files[0]);
  //     await axiosInstance
  //       .post(`/api/ppm/projects-products-categories/upload-mid-process-report/${id}`, bodyFormData)
  //       .then((response) => {
  //         setData((preValue) => ({
  //           ...preValue,
  //           mid_process_report: response.data.url
  //         }));
  //         fetchDraftOrders();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // const handleUploadActualRawMaterialAuditDate = async (event, orderId) => {
  //   await axiosInstance
  //     .post(
  //       `/api/ppm/projects-products-categories/upload-actual-raw-material-audit-date/${orderId}`
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       fetchDraftOrders();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleUploadActualMidProcessDate = async (event, orderId) => {
  //   await axiosInstance
  //     .post(`/api/ppm/projects-products-categories/upload-actual-mid-process-date/${orderId}`)
  //     .then((response) => {
  //       console.log(response);
  //       fetchDraftOrders();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleUploadCOA = (event, vpcid, orderId) => {
    console.log(event.target.files[0]);
    if (event.target.files[0]) {
      const bodyFormData = new FormData();
      // const level = "PSS";
      bodyFormData.append("file", event.target.files[0]);
      axiosInstance
        .post(`/api/ppm/order-actions/object/${orderId}/upload-pss-coa`, bodyFormData)
        .then((response) => {
          showAlert({
            open: true,
            message: "Uploaded Successfully",
            severity: "success"
          });
          fetchDraftOrders();
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      // .post(`/api/vendors/vendorProductCategory/${vpcid}/upload-document/COA`, bodyFormData, {
      //   params: {
      //     level: "PSS",
      //     orderId: orderId
      //   }
      // })
      // .then((response) => {
      //   showAlert({
      //     open: true,
      //     message: "Uploaded Successfully",
      //     severity: "success"
      //   });
      //   fetchDraftOrders();
      //   console.log(response);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    }
  };

  return (
    <div className="h-[calc(100vh-500px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        editMode="row"
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {Boolean(showFeedbackModal.open) && (
        <FeedbackModal
          open={showFeedbackModal.open}
          onClose={() => {
            setShowFeedbackModal({
              open: false,
              id: null,
              date: ""
            });
            fetchDraftOrders();
          }}
          postApi={
            showFeedbackModal.date === "mid_process_date"
              ? `/api/ppm/projects-products-categories/upload-actual-mid-process-date/${showFeedbackModal.id}`
              : `/api/ppm/projects-products-categories/upload-actual-raw-material-audit-date/${showFeedbackModal.id}`
          }
          id={showFeedbackModal.id}
        />
      )}
    </div>
  );
};

export default RunningOrdersPO;
