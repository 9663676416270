import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import SearchIcon from "@mui/icons-material/Search";

const SelectProductButton = ({
  productCategoryId,
  temp_category_id,
  temp_product_name,
  onClick,
  disabled
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (productCategoryId) {
      fetchProductCategory();
    }
  }, [productCategoryId]);

  useEffect(() => {
    if (temp_category_id) {
      fetchParentCategory();
    }
  }, [temp_category_id]);

  const fetchProductCategory = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/ppm/productsCategories/object/${productCategoryId}`
      );
      console.log("product data", response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchParentCategory = async () => {
    const response = await axiosInstance.get(`/api/categories/fetch-parent/${temp_category_id}`);
    console.log(response.data);
    setData({
      full_name: temp_product_name,
      categories: [...response.data]
    });
  };

  return (
    <button
      disabled={disabled}
      type="button"
      className="w-full border border-black min-h-[48px]"
      onClick={onClick}>
      {!data ? (
        <span className="flex justify-center items-center">
          <SearchIcon sx={{ marginRight: 1 }} fontSize="small" />
          Select Product
        </span>
      ) : (
        <div className="p-2">
          {data?.full_name}
          <div className="w-full overflow-y-auto mt-2">
            {data?.categories.map((cat) => (
              <label
                key={cat.category_id}
                className="border-2 mr-2 border-black text-sm inline-block px-2 py-1 text-[#000b36] bg-gray-200 rounded-lg">
                {cat.category_name}
              </label>
            ))}
          </div>
        </div>
      )}
    </button>
  );
};

export default SelectProductButton;
