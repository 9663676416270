import { Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import SupplierSampleDocumentUploadComponent from "./SupplierSampleDocumentUploadComponent";

const SupplierSampleDocumentUploadModal = ({ open, onClose }) => {
  const [row, setRow] = useState([<SupplierSampleDocumentUploadComponent key={0} />]);

  /*
  const deleteRow = (val)=>{
    console.log("Clicked");
    setRow((prevRow)=>{
      prevRow.id !== val
    })
  } 
  */

  const handleAddRow = () => {
    setRow([...row, <SupplierSampleDocumentUploadComponent key={row.length} />]);
  };

  console.log(row);

  console.log(row);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Supplier Sample Document Upload
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[250px] space-y-3">
          {row}
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={handleAddRow}>
            Add Row
          </Button>
        </div>
        <Button color="primary" variant="contained" component="label" onClick={() => {}}>
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default SupplierSampleDocumentUploadModal;
