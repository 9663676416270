import { Button, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "./DataGridCustomToolbar";
import { useEffect, useState } from "react";
import { axiosInstance } from "../core/apiInstance";
import { useShowAlertStore } from "../state/alert.state";

const ProductDocumentModal = ({
  open,
  onClose,
  vendorProductCategoryId,
  fetchParent,
  projectProductCategoryId
}) => {
  const [data, setData] = useState([]);
  const { showAlert } = useShowAlertStore();

  useEffect(() => {
    fetchDocuments();
  }, [vendorProductCategoryId]);

  const fetchDocuments = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/vendors/vendorProductCategory/${vendorProductCategoryId}/documents`
      );
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadProductDocument = (document_link) => {
    window.open(document_link);
  };

  const uploadDocumentToGetLink = (event, name) => {
    if (event.target.files[0]) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);

      let query = {};

      if (projectProductCategoryId) {
        query = { projectProductCategoryId, requestForQa: true };
      }

      axiosInstance
        .post(
          `/api/vendors/vendorProductCategory/${vendorProductCategoryId}/upload-document/${name}`,
          bodyFormData,
          { params: query }
        )
        .then((response) => {
          showAlert({
            open: true,
            message: "Uploaded Successfully",
            severity: "success"
          });
          console.log(response);
          fetchDocuments();
          fetchParent && fetchParent();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Product Documents
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[450px] h-[300px]">
          <DataGrid
            hideFooter
            rows={data}
            disableColumnMenu
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
            columns={[
              {
                field: "name",
                headerName: "Document Name",
                sortable: false,
                width: 150
              },
              {
                field: "action",
                headerName: "Action",
                sortable: false,
                width: 280,
                renderCell: (params) => {
                  return params.row.document_link ? (
                    <>
                      <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        component="label"
                        onClick={() => handleDownloadProductDocument(params.row.document_link)}>
                        Download
                      </Button>
                      <Button fullWidth color="primary" variant="outlined" component="label">
                        Re-Upload
                        <input
                          type="file"
                          hidden
                          onChange={(e) => uploadDocumentToGetLink(e, params.row.name)}
                        />
                      </Button>
                    </>
                  ) : (
                    <Button fullWidth color="primary" variant="outlined" component="label">
                      Upload
                      <input
                        type="file"
                        hidden
                        onChange={(e) => uploadDocumentToGetLink(e, params.row.name)}
                      />
                    </Button>
                  );
                }
              }
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductDocumentModal;
