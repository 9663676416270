import { IconButton, Modal, LinearProgress, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { axiosInstance } from "../../../../core/apiInstance";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { dueDateTimeFormat } from "../../../../core/constants";

const RevisionModal = ({ open, onClose, id }) => {
  const [data, setData] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 200
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 300
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 300,
      renderCell: (params) => {
        return params.row.created_at ? (
          <div>{dayjs(params.row.created_at).format(dueDateTimeFormat)}</div>
        ) : (
          ""
        );
      }
    }
  ];

  useEffect(() => {
    fetchRevisionDetails(id);
  }, []);

  const fetchRevisionDetails = async (ppcId) => {
    const response = await axiosInstance.get(
      `/api/ppm/projects-products-categories/object/${ppcId}/revisions`
    );
    setData(response.data);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Revision Info
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[300px]">
          <DataGrid
            hideFooter
            rows={data}
            //loading={loading}
            disableColumnMenu
            slots={{
              toolbar: DataGridCustomToolbar,
              loadingOverlay: LinearProgress
            }}
            columns={columns}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RevisionModal;
