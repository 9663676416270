import { Button, IconButton, Modal, TextField, Typography, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { Controller, useForm } from "react-hook-form";

const BuyerPOModal = ({ open, onClose, orderId }) => {
  // const [formData, setFormData] = useState({
  //   price: "",
  //   quantity: "",
  //   expected_delivery_date: null,
  //   payment_terms: "",
  //   bulk_delivery_location: "",
  //   pss_delivery_location: "",
  //   expected_approval_pss_lead_time: null,
  //   bill_to_name: "",
  //   bill_delivery_address: "",
  //   ship_to_name: "",
  //   ship_delivery_address: "",
  //   testing_method: ""
  // });

  const validationSchema = yup.object().shape({
    price: yup.string(), //.required("Price is required"),
    quantity: yup.string(), //.required("Quantity is required"),
    expected_delivery_date: yup.date(), //required("Expected Delivery Date is required"),
    payment_terms: yup.string(), //.required("Payment Terms is required"),
    bulk_delivery_location: yup.string(), //.required("Bulk Delivery Location is required"),
    pss_receivers_name: yup.string(), //.required("PSS Receiver Name is required"),
    pss_delivery_location: yup.string(), //.required("PSS Delivery Location is required"),
    expected_approval_pss_lead_time: yup.number().integer(),
    //.required("Expected Approval PSS Lead Time is required"),
    delivery_buffer_days: yup.number().integer(),
    bill_to_name: yup.string(), //.required("Bill to Name is required"),
    bill_delivery_address: yup.string(), //.required("Bill Delivery Address is required"),
    ship_to_name: yup.string(), //.required("Ship to Name is required"),
    ship_delivery_address: yup.string(), //.required("Ship Delivery Address is required"),
    testing_method: yup.string() //.required("Testing Method is required")
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      unit: ""
    },
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    fetchBuyerPoData();
  }, []);

  const fetchBuyerPoData = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/ppm/order-actions/object/${orderId}/buyer-po-info`
      );
      // if (response?.data && response.data?.buyer_po) {
      //   setFormData(response.data?.buyer_po);
      //   // TODO: dayjs changes needs to be done
      // }
      console.log(response);
      const { price_low, quantity, rfs_buyer_info } = response.data;
      reset({
        price: price_low,
        quantity,
        pss_delivery_location: rfs_buyer_info.delivery_location,
        pss_receivers_name: rfs_buyer_info.poc_name
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  // const handleDateChange = (date, name) => {
  //   setFormData({
  //     ...formData,
  //     [name]: date
  //   });
  // };

  const handleBuyerPOFormSubmit = async (formData) => {
    try {
      let response = await axiosInstance.post(
        `/api/ppm/order-actions/object/${orderId}/buyer-po-info`,
        {
          buyerPOinfo: formData
        }
      );
      console.log(response);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5">
        <div className="flex justify-between pb-[20px]">
          <div className="flex items-center">
            <div className="mr-[20px] flex flex-row">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Update Buyer P.O. Info
              </Typography>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="w-[700px] h-[550px] overflow-y-auto p-2">
          <div className="flex flex-col justify-center space-y-1">
            <form onSubmit={handleSubmit((formData) => handleBuyerPOFormSubmit(formData))}>
              <div className="flex flex-col justify-center space-y-3 mt-1">
                <div className="flex flex-row">
                  <div className="flex flex-row justify-center w-full space-x-3 space-y-3">
                    <TextField
                      label="Expected Approval PSS Lead time"
                      {...register("expected_approval_pss_lead_time")}
                      fullWidth
                      margin="normal"
                      type={"number"}
                      error={!!errors.expected_approval_pss_lead_time}
                      helperText={
                        errors.expected_approval_pss_lead_time
                          ? errors.expected_approval_pss_lead_time.message
                          : ""
                      }
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        control={control}
                        name="expected_delivery_date"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            disablePast
                            fullWidth
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            name="expected_delivery_date"
                            label="Expected Delivery Date"
                          />
                        )}
                      />
                      {errors.expected_delivery_date?.message && (
                        <FormHelperText error>
                          {errors.expected_delivery_date?.message}
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex flex-row justify-between space-x-3">
                  <TextField
                    label="Price in Rs/kg"
                    {...register("price")}
                    fullWidth
                    margin="normal"
                    error={!!errors.price}
                    helperText={errors.price ? errors.price.message : ""}
                  />
                  <TextField
                    label="Quantity/MT"
                    {...register("quantity")}
                    fullWidth
                    margin="normal"
                    error={!!errors.quantity}
                    helperText={errors.quantity ? errors.quantity.message : ""}
                  />
                </div>
                <TextField
                  label="PSS Receiver' Name"
                  {...register("pss_receivers_name")}
                  fullWidth
                  margin="normal"
                  error={!!errors.pss_receivers_name}
                  helperText={errors.pss_receivers_name ? errors.pss_receivers_name.message : ""}
                />
                <TextField
                  label="PSS Delivery Location"
                  {...register("pss_delivery_location")}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.pss_delivery_location}
                  helperText={
                    errors.pss_delivery_location ? errors.pss_delivery_location.message : ""
                  }
                />
                <TextField
                  label="Bulk Delivery Location"
                  {...register("bulk_delivery_location")}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.bulk_delivery_location}
                  helperText={
                    errors.bulk_delivery_location ? errors.bulk_delivery_location.message : ""
                  }
                />
                <TextField
                  label="Payment Terms"
                  {...register("payment_terms")}
                  fullWidth
                  margin="normal"
                  error={!!errors.payment_terms}
                  helperText={errors.payment_terms ? errors.payment_terms.message : ""}
                />
                <TextField
                  label="Delivery Buffer Days"
                  {...register("delivery_buffer_days")}
                  fullWidth
                  margin="normal"
                  type={"number"}
                  error={!!errors.delivery_buffer_days}
                  helperText={
                    errors.delivery_buffer_days ? errors.delivery_buffer_days.message : ""
                  }
                />
                <div className="flex items-center">
                  <div className="mr-[20px]">
                    <Typography variant="h5" component="h2">
                      Bill to
                    </Typography>
                  </div>
                </div>
                <TextField
                  label="Bill to Name"
                  {...register("bill_to_name")}
                  fullWidth
                  margin="normal"
                  error={!!errors.bill_to_name}
                  helperText={errors.bill_to_name ? errors.bill_to_name.message : ""}
                />
                <TextField
                  label="Bill Delivery Address"
                  {...register("bill_delivery_address")}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.bill_delivery_address}
                  helperText={
                    errors.bill_delivery_address ? errors.bill_delivery_address.message : ""
                  }
                />
                <div className="flex items-center">
                  <div className="mr-[20px]">
                    <Typography variant="h5" component="h2">
                      Ship to
                    </Typography>
                  </div>
                </div>
                <TextField
                  label="Ship to Name"
                  {...register("ship_to_name")}
                  fullWidth
                  margin="normal"
                  error={!!errors.ship_to_name}
                  helperText={errors.ship_to_name ? errors.ship_to_name.message : ""}
                />
                <TextField
                  label="Ship Delivery Address"
                  {...register("ship_delivery_address")}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.ship_delivery_address}
                  helperText={
                    errors.ship_delivery_address ? errors.ship_delivery_address.message : ""
                  }
                />
                <TextField
                  label="Testing Method"
                  {...register("testing_method")}
                  fullWidth
                  margin="normal"
                  error={!!errors.testing_method}
                  helperText={errors.testing_method ? errors.testing_method.message : ""}
                />
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BuyerPOModal;
