import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { segments } from "../../../../core/constants";
import DateRangePicker from "../../../../components/DateRangePicker";
import { saveData } from "../../../../core/utilsFunctions";
import { useAuthStore } from "../../../../state/auth.state";
import { useNavigate } from "react-router-dom";
import SelectProductModal from "../../../../components/SelectProductModal";
import AddProductCategoryModal from "../../../../components/AddProductCategoryModal";
import AssignToModal from "../../Supply/common/AssignToModal";

import SupplierSampleDocumentUploadModal from "../../Supply/common/SupplierSampleDocumentUploadModal";

const rfsFilters = [
  {
    id: 1,
    label: "Open Queries - All",
    value: "ALL",
    type: "ALL"
  },
  {
    id: 2,
    label: "Coa from supplier is needed",
    value: "COA_FROM_SUPPLIER_NEEDED",
    type: "SUPPLY"
  },
  {
    id: 3,
    label: "QA Not Approved",
    value: "QA_NOT_APPROVED",
    type: "SUPPLY"
  },
  {
    id: 4,
    label: "QA Approved Not yet dispatched",
    value: "QA_APPROVED",
    type: "SUPPLY"
  },
  {
    id: 5,
    label: "Supplier to Covvalent in Transit",
    value: "SUPPLIER_TO_COVVALENT_IN_TRANSIT",
    type: "SUPPLY"
  },
  {
    id: 6,
    label: "Supplier to Covvalent Received",
    value: "SUPPLIER_TO_COVVALENT_RECEIVED",
    type: "SUPPLY"
  },
  {
    id: 7,
    label: "Covvalent to Buyer in Transit",
    value: "COVVALENT_TO_BUYER_IN_TRANSIT",
    type: "SUPPLY"
  },
  {
    id: 8,
    label: "Covvalent to Buyer Received",
    value: "COVVALENT_TO_BUYER_RECEIVED",
    type: "DEMAND"
  },
  {
    id: 9,
    label: "Buyer Feedback Awaited",
    value: "BUYER_FEEDBACK_AWAITED",
    type: "DEMAND"
  },
  {
    id: 10,
    label: "Action From Supply Needed",
    value: "ACTION_FROM_SUPPLY_NEEDED",
    type: "SUPPLY"
  }
];

const poFilters = [
  {
    id: 1,
    label: "Open Queries - All",
    value: "ALL",
    type: "ALL"
  },
  {
    id: 2,
    label: "RRQ Raised",
    value: "RRQ_RAISED",
    type: "SUPPLY"
  },
  {
    id: 3,
    label: "Update Supplier PO",
    value: "UPDATE_SUPPLIER_PO",
    type: "SUPPLY"
  },
  {
    id: 4,
    label: "PSS COA Needed",
    value: "PSS_COA_NEEDED",
    type: "SUPPLY"
  },
  {
    id: 5,
    label: "Update Buyer PO",
    value: "UPDATE_BUYER_PO",
    type: "DEMAND"
  },
  {
    id: 6,
    label: "PSS Buyer Feedback Needed",
    value: "PSS_BUYER_FEEDBACK_NEEDED",
    type: "DEMAND"
  },
  {
    id: 7,
    label: "PSS Supplier To Covvalent",
    value: "PSS_SUPPLIER_TO_COVVALENT",
    type: "LOGISTICS"
  },
  {
    id: 8,
    label: "PSS Covvalent To Buyer",
    value: "PSS_COVVALENT_TO_BUYER",
    type: "LOGISTICS"
  },
  {
    id: 9,
    label: "Bulk Supplier To Covvalent",
    value: "BULK_SUPPLIER_TO_COVVALENT",
    type: "LOGISTICS"
  },
  {
    id: 10,
    label: "Bulk Covvalent To Buyer",
    value: "BULK_COVVALENT_TO_BUYER",
    type: "LOGISTICS"
  }
];

// eslint-disable-next-line no-undef
const isDevelopment = process.env.NODE_ENV === "development";

const QueriesListSection = ({ handleSetOpenQueriesCount }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [accountManager, setAccountManager] = useState([]);
  const [supplyTeamMember, setSupplyTeamMember] = useState([]);
  // const [value, setValue] = useState([null, null]);
  const [filterBy, setFilterBy] = useState("OPEN_QUERIES");
  const [stage, setStage] = useState("QUOTATION");
  const [rfsType, setRfsType] = useState("ALL");
  const [poType, setpoType] = useState("ALL");
  const [selectedAccountManager, setSelectedAccountManager] = useState(null);
  const [selectedSupplyTeamMember, setSelectedSupplyTeamMember] = useState(null);
  const [createdAt, setCreatedAt] = useState({
    startDate: null,
    endDate: null
  });
  const [dueDate, setDueDate] = useState({
    startDate: null,
    endDate: null
  });
  const [showSelectProductModal, setShowSelectProductModal] = useState({
    open: false,
    id: null,
    name: ""
  });
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showAssignToModal, setShowAssignToModal] = useState({
    open: false,
    projectsProductsCategoriesId: null,
    supplyTeamId: null
  });
  const [columns, setColumns] = useState([]);
  const [count, setCount] = useState();

  const { user } = useAuthStore();
  const isAdmin = user?.userTeams?.find((team) => team.team_name === "ADMIN");
  const isDemandSide = user?.userTeams?.find((team) => team.team_name === "DEMAND");
  const isSupplySide = user?.userTeams?.find((team) => team.team_name === "SUPPLY");

  const [supplierSampleDocumentUploadModal, setSupplierSampleDocumentUploadModal] = useState(false);

  const navigate = useNavigate();

  const productColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50
    },
    {
      field: "action_view",
      headerName: "View Details",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            disabled={Boolean(params.row.temp_product_name) || (!isAdmin && isDemandSide)}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() => {
              navigate(`id/${params.row.id}`);
            }}>
            View Details
          </Button>
        );
      }
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 160,
      renderCell: (params) => {
        if (params.row.stage === "QUOTATION") {
          if (params.row.due_date) {
            return (
              <div
                className={
                  params.row.is_delayed
                    ? "w-full overflow-y-auto text-red-800"
                    : "w-full overflow-y-auto"
                }>
                {dayjs(params.row.due_date).format(dueDateTimeFormat)}
              </div>
            );
          } else {
            return null;
          }
        } else {
          // Calculate the new date by adding 10 days to request_for_sample_at
          return (
            <div
              className={
                params.row.is_rfs_delayed
                  ? "w-full overflow-y-auto text-red-800"
                  : "w-full overflow-y-auto"
              }>
              {dayjs(params.row.rfs_due_date).format(dueDateTimeFormat)}
            </div>
          );
        }
      }
    },
    !isAdmin && isSupplySide
      ? {
          field: "project_id",
          headerName: "Project Id",
          sortable: false,
          width: 120
        }
      : {
          field: "project_name",
          headerName: "Project Name",
          sortable: false,
          width: 120
        },
    {
      field: "account_manager_name",
      headerName: "Account Manager",
      sortable: false,
      width: 120
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 150
    },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return params.row.temp_product_name ? (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() =>
              setShowSelectProductModal({
                open: true,
                id: params.row.id,
                name: params.row?.temp_product_name
              })
            }>
            {params.row.temp_product_name}
          </Button>
        ) : (
          params.row.product_name && (
            <div className="w-full overflow-y-auto">{params.row.product_name}</div>
          )
        );
      }
    },
    {
      field: "industries",
      headerName: "Industries",
      sortable: false,
      width: 150
    },
    {
      field: "applications",
      headerName: "Applications",
      sortable: false,
      width: 100
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false
    },
    {
      field: "unit",
      headerName: "Unit",
      sortable: false
    },
    {
      field: "supply_team_member_name",
      headerName: "Assigned To",
      sortable: false
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return segments[params.row.status];
      }
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 160,
      renderCell: (params) => {
        return params.row.created_at ? (
          <div className="w-full overflow-y-auto">
            {dayjs(params.row.created_at).format(dueDateTimeFormat)}
          </div>
        ) : null;
      }
    }
  ];

  useEffect(() => {
    handleFetchAccountManager();
    handleFetchSupplyTeamMember();

    if (user && isAdmin) {
      setColumns([
        {
          field: "assign_to",
          headerName: "Assign To",
          sortable: false,
          width: 150,
          renderCell: (params) => {
            return params.row.product_name ? (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                component="label"
                onClick={() =>
                  setShowAssignToModal({
                    open: true,
                    projectsProductsCategoriesId: params.row.id,
                    supplyTeamId: params.row.supply_team
                  })
                }>
                Assign to
              </Button>
            ) : null;
          }
        },
        ...productColumns
      ]);
    } else {
      setColumns(productColumns);
    }
  }, [user]);

  useEffect(() => {
    fetchAdminSummary();
  }, [user, stage, filterBy, createdAt, dueDate, selectedAccountManager, selectedSupplyTeamMember]);

  const fetchAdminSummary = async () => {
    let { id } = user;

    try {
      setLoading(true);
      const filterObj = {
        stage,
        filterBy,
        createdAtRange: createdAt,
        dueDateRange: dueDate,
        accountManager: !isAdmin && isDemandSide ? id : selectedAccountManager,
        supplyTeam: !isAdmin && isSupplySide ? id : selectedSupplyTeamMember
      };

      const response = await axiosInstance.get("/api/ppm/projects/supply-admin-summary/", {
        params: filterObj
      });

      setData(response.data);
      setCount(response.data?.length);
      handleSetOpenQueriesCount && handleSetOpenQueriesCount(response.data.length);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const downloadAdminSummary = async () => {
    try {
      setLoading(true);
      const filterObj = {
        filterBy,
        createdAtRange: createdAt,
        dueDateRange: dueDate,
        accountManager: selectedAccountManager,
        supplyTeam: selectedSupplyTeamMember
      };

      const response = await axiosInstance.get("/api/ppm/projects/supply-admin-summary/csv", {
        params: filterObj
      });

      saveData(response.data, `queries-${new Date().toISOString()}.csv`);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleFetchAccountManager = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=DEMAND");
      setAccountManager(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchSupplyTeamMember = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=SUPPLY");
      setSupplyTeamMember(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const dueDateTimeFormat = isDevelopment ? "YYYY-MM-DD HH:mm A" : "YYYY-MM-DD";

  const hideSearchProductShowAddSingleProduct = () => {
    setShowSelectProductModal({
      ...showSelectProductModal,
      open: false
    });
    setShowAddProductModal(true);
  };

  const handleOnSelectProduct = async (product) => {
    // attach this product
    await axiosInstance.post(
      `/api/ppm/project-actions/project-products-categories/${showSelectProductModal.id}/add-product-category/${product.id}`
    );
    fetchAdminSummary();
    setShowSelectProductModal({
      open: false,
      id: null,
      name: ""
    });
  };

  const handleCloseAddProductModal = async (data) => {
    setShowAddProductModal(false);

    if (data?.result) {
      for (const productCategory of data.result) {
        // attach this product

        await axiosInstance.post(
          `/api/ppm/project-actions/project-products-categories/${showSelectProductModal.id}/add-product-category/${productCategory.id}`
        );

        setShowSelectProductModal({
          open: false,
          id: null,
          name: ""
        });

        console.log(productCategory.id);
      }

      fetchAdminSummary();
    }
  };

  return (
    <div className="h-[calc(100vh-200px)]">
      <Typography variant="h5" gutterBottom>
        All Queries - {count}
      </Typography>
      <div className="flex items-center">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Stage</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={stage}
            label="Filter"
            onChange={(e) => {
              setStage(e.target.value);
              if (e.target.value === "QUOTATION") {
                setFilterBy("OPEN_QUERIES");
              } else {
                setFilterBy("ALL");
              }
            }}>
            <MenuItem value="QUOTATION">RFQ</MenuItem>
            <MenuItem value="SAMPLE">RFS</MenuItem>
            <MenuItem value="PO">PO</MenuItem>
          </Select>
        </FormControl>

        {stage === "QUOTATION" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={filterBy}
              label="Filter"
              onChange={(e) => setFilterBy(e.target.value)}>
              <MenuItem value="OPEN_QUERIES">Open Queries - All</MenuItem>
              <MenuItem value="OPEN_QUERIES_FINALIZE_SUPPLIER_ADD_DATA_POINT">
                Open Queries - Finalize supplier & add data point
              </MenuItem>
              <MenuItem value="OPEN_QUERIES_NEW_VENDOR_DEVELOPMENT">
                Open Queries - New Vendor Development
              </MenuItem>
              <MenuItem value="CLOSED_QUERIES">Closed Queries</MenuItem>
              <MenuItem value={"REGRET_PRODUCTS"}>Regret products</MenuItem>
              <MenuItem value={"OPEN_TICKETS"}>Open tickets</MenuItem>
              <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
              <MenuItem value={"DELAYED"}>Delayed</MenuItem>
            </Select>
          </FormControl>
        )}
        {stage === "SAMPLE" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={rfsType}
              label="Type"
              onChange={(e) => {
                setRfsType(e.target.value);
                if (e.target.value === "ALL") {
                  setFilterBy("ALL");
                } else if (e.target.value === "SUPPLY") {
                  setFilterBy("COA_FROM_SUPPLIER_NEEDED");
                } else {
                  setFilterBy("COVVALENT_TO_BUYER_RECEIVED");
                }
              }}>
              {["ALL", "DEMAND", "SUPPLY"].map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {stage === "SAMPLE" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={filterBy}
              label="Filter"
              onChange={(e) => setFilterBy(e.target.value)}>
              {rfsFilters
                .filter((filter) => filter.type === rfsType)
                .map((filter) => (
                  <MenuItem key={filter.id} value={filter.value}>
                    {filter.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {stage === "PO" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={poType}
              label="Type"
              onChange={(e) => {
                setpoType(e.target.value);
                if (e.target.value === "ALL") {
                  setFilterBy("ALL");
                } else if (e.target.value === "SUPPLY") {
                  setFilterBy("RRQ_RAISED");
                } else if (e.target.value === "DEMAND") {
                  setFilterBy("UPDATE_BUYER_PO");
                } else {
                  setFilterBy("PSS_SUPPLIER_TO_COVVALENT");
                }
              }}>
              {["ALL", "DEMAND", "SUPPLY", "LOGISTICS"].map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {stage === "PO" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={filterBy}
              label="Filter"
              onChange={(e) => setFilterBy(e.target.value)}>
              {poFilters
                .filter((filter) => filter.type === poType)
                .map((filter) => (
                  <MenuItem key={filter.id} value={filter.value}>
                    {filter.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <div className="mr-[10px]">
          <DateRangePicker
            label={"Created At"}
            value={createdAt}
            onChange={(value) => setCreatedAt({ ...value })}
          />
        </div>
        <DateRangePicker
          label={"Due Date"}
          value={dueDate}
          onChange={(value) => setDueDate({ ...value })}
        />
        {isAdmin && (
          <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel>Account manager</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedAccountManager}
              onChange={(e) => setSelectedAccountManager(e.target.value)}
              label="Account Manager">
              <MenuItem key={0} value={null}>
                ALL
              </MenuItem>
              {accountManager.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isAdmin && (
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel>Supply team member</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedSupplyTeamMember}
              onChange={(e) => setSelectedSupplyTeamMember(e.target.value)}
              label="Supply Team Member">
              <MenuItem key={0} value={null}>
                ALL
              </MenuItem>
              {supplyTeamMember.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button variant="contained" color="primary" onClick={downloadAdminSummary}>
          Download CSV
        </Button>

        {/* <Button
        className="left-2"
        variant="contained"
        color="primary"
        onClick={() => {
          setSupplierSampleDocumentUploadModal(true);
        }}>
        SupplierSampleDocumentUpload
      </Button>

      <Button
        className="left-3"
        variant="contained"
        color="primary"
        onClick={() => {

        }}>
        SupplierSampleDispatchInfoModal
      </Button> */}
      </div>
      <DataGrid
        loading={loading}
        hideFooter
        rows={data}
        disableColumnMenu
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
        columns={columns}
      />
      {Boolean(showSelectProductModal.open) && (
        <SelectProductModal
          showAddSingleProduct={hideSearchProductShowAddSingleProduct}
          open={showSelectProductModal.open}
          name={showSelectProductModal.name}
          onClose={() =>
            setShowSelectProductModal({
              open: false,
              id: null,
              name: ""
            })
          }
          onSelect={handleOnSelectProduct}
        />
      )}
      {showAddProductModal && (
        <AddProductCategoryModal open={showAddProductModal} onClose={handleCloseAddProductModal} />
      )}
      {Boolean(showAssignToModal.open) && (
        <AssignToModal
          open={showAssignToModal.open}
          onClose={() =>
            setShowAssignToModal({
              open: false,
              projectsProductsCategoriesId: null,
              supplyTeamId: null
            })
          }
          supplyTeamId={showAssignToModal.supplyTeamId}
          projectsProductsCategoriesId={showAssignToModal.projectsProductsCategoriesId}
          fetchParent={fetchAdminSummary}
        />
      )}
      {supplierSampleDocumentUploadModal && (
        <SupplierSampleDocumentUploadModal
          open={"1"}
          onClose={() => {
            setSupplierSampleDocumentUploadModal(false);
          }}
        />
      )}
    </div>
  );
};

export default QueriesListSection;
