import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../components/Header";
import { useAuthStore } from "../../state/auth.state";

const sideBarLinks = [
  {
    label: "Orders",
    routes: [
      {
        label: "Projects",
        route: "orders/projects",
        access: ["DEMAND", "ADMIN", "SUPPLY_HANDLING_DEMAND"]
      },
      {
        label: "All P.O.s",
        route: "orders/po",
        access: ["DEMAND", "ADMIN", "SUPPLY"]
      }
    ]
  },
  {
    label: "Queries",
    routes: [
      {
        label: "All Queries",
        route: "queries",
        access: ["DEMAND", "ADMIN", "SUPPLY"]
      }
    ]
  },
  {
    label: "Supply",
    routes: [
      {
        label: "Admin",
        route: "supply/admin",
        access: ["SUPPLY_ADMIN", "ADMIN"]
      },
      {
        label: "Quotation Cache",
        route: "supply/quotation-cache",
        access: ["SUPPLY", "ADMIN"]
      },
      {
        label: "Vendors",
        route: "supply/vendors",
        access: ["SUPPLY", "ADMIN"]
      },
      {
        label: "Quality Analysis",
        route: "supply/quality-analysis",
        access: ["SUPPLY_QA", "ADMIN"]
      },
      {
        label: "Logistics",
        route: "supply/logistics",
        access: ["SUPPLY_LOGICTICS", "ADMIN"]
      }
    ]
  },
  {
    label: "Tools",
    routes: [
      {
        label: "Doc Generation",
        route: "tools/doc-generation"
      },
      {
        label: "TDS/COA Compare",
        route: "tools/tds-compare"
      }
    ]
  },
  {
    label: "Dashboard",
    routes: [
      {
        label: "Critical Projects",
        route: "critical-projects"
      },
      {
        label: "Overview",
        route: "analytics-dashboard"
      }
    ]
  },
  {
    label: "Configurations",
    routes: [
      {
        label: "Configurations",
        route: "configurations",
        access: ["ADMIN"]
      }
    ]
  }
];

function DashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const userIsAdmin = user?.userTeams?.find((team) => team.team_name === "ADMIN");
  const userIsDemand = user?.userTeams?.find((team) => team.team_name === "DEMAND");
  const userIsSupply = user?.userTeams?.find((team) => team.team_name === "SUPPLY");

  if (!userIsAdmin && !userIsDemand && !userIsSupply) {
    return null;
  }

  return (
    <div className="absolute h-full w-full flex">
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          ["& .MuiDrawer-paper"]: { width: 240, boxSizing: "border-box" }
        }}
        anchor="left">
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {sideBarLinks.map((link) => (
              <ListItem key={link.label} disablePadding divider fullWidth>
                <Accordion className="w-full" fullWidth>
                  <AccordionSummary
                    className="w-full"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>{link.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {link.routes
                      .filter(({ access }) => {
                        if (access) {
                          let isPass = false;
                          for (const team of user.userTeams) {
                            isPass = access.includes(team.team_name);
                            if (isPass) {
                              return isPass;
                            }
                          }
                          return isPass;
                        } else {
                          return true;
                        }
                      })
                      .map(({ route, label }) => (
                        <ListItemButton key={route} divider onClick={() => navigate(route)}>
                          <ListItemText primary={label} />
                        </ListItemButton>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </div>
  );
}

export default DashboardPage;
