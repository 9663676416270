import { Route } from "react-router-dom";
import QuotationsViewAdminSection from "./QuotationsViewAdmin";
import VendorsSection from "./Vendors";
import QuotationCacheSection from "./QuotationCache";
import QualityAnalysisSection from "./QualityAnalysis";
import Logistics from "./Logistics";

export const supplyRoutes = [
  {
    route: "admin",
    Component: QuotationsViewAdminSection
  },
  {
    route: "quotation-cache",
    Component: QuotationCacheSection
  },
  {
    route: "vendors",
    Component: VendorsSection
  },
  {
    route: "quality-analysis",
    Component: QualityAnalysisSection
  },
  {
    route: "logistics",
    Component: Logistics
  }
];

export default () => {
  return (
    supplyRoutes &&
    supplyRoutes.map(({ Component, route, nestedRoutes }) => (
      <Route key={route} path={route} element={<Component />}>
        {nestedRoutes && nestedRoutes()}
      </Route>
    ))
  );
};
