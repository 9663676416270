import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "../../../../components/DataGridCustomToolbar";
import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../core/apiInstance";
import BuyerPOModal from "../common/BuyerPOModal";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { dateFormat } from "../../../../core/constants";
import RevisedQuotationModal from "./RevisedQuotationModal";
import QualityFeedbackModal from "../../Supply/QualityAnalysis/QualityFeedbackModal";

const DraftOrders = ({ refresh }) => {
  const [showBuyerPoModal, setShowBuyerPoModal] = useState({
    open: false,
    orderId: null
  });
  const [data, setData] = useState([]);
  const [showRevisedQuotationModal, setShowRevisedQuotationModal] = useState({
    open: false,
    projectsProductsCategoryId: null
  });
  const [showQualityFeedbackModal, setShowQualityFeedbackModal] = useState({
    open: false,
    title: "",
    api: ""
  });
  const params = useParams();

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 50
    },
    {
      field: "rrq_date",
      headerName: "RRQ Date",
      width: 200,
      renderCell: (params) => {
        return params.row.rrq_at ? <div>{dayjs(params.row.rrq_at).format(dateFormat)}</div> : null;
      }
    },
    {
      field: "revised_quotes",
      headerName: "Revised Quotes",
      width: 200,
      renderCell: (params) => {
        return params.row.revised_quotes_submitted_at ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              setShowRevisedQuotationModal({
                open: true,
                orderId: params.row.id,
                projectsProductsCategoryId: params.row.projects_products_categories_id
              })
            }>
            View
          </Button>
        ) : (
          <div>PENDING</div>
        );
      }
    },
    {
      field: "buyer_po",
      headerName: "Buyer PO",
      width: 200,
      renderCell: (params) => {
        console.log("orderId", params.row.id);
        return (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              setShowBuyerPoModal({
                open: true,
                orderId: params.row.id
              })
            }>
            Update P.O.
          </Button>
        );
      }
    },
    {
      field: "po_raised_date",
      headerName: "PO Raised Date",
      width: 300,
      renderCell: (params) => {
        return params.row.buyer_po_raised_at ? (
          <div>{dayjs(params.row.buyer_po_raised_at).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "supply_po",
      headerName: "Supply PO",
      width: 200,
      renderCell: (params) => {
        return params.row.supplier_po ? "UPDATED" : "PENDING";
      }
    },
    {
      field: "po_due_date",
      headerName: "PO Due Date",
      width: 300,
      renderCell: (params) => {
        return params.row.po_due_date ? (
          <div>{dayjs(params.row.po_due_date).format(dateFormat)}</div>
        ) : null;
      }
    },
    {
      field: "cancel",
      headerName: "CANCEL",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            disabled={params.row.logistics_type === "BULK" ? true : false}
            fullWidth
            color="primary"
            variant="outlined"
            component="label"
            onClick={() =>
              setShowQualityFeedbackModal({
                open: true,
                api: `/api/ppm/order-actions/object/${params.row.id}/cancel-order`,
                title: "CANCEL ORDER"
              })
            }>
            CANCEL
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    fetchDraftOrders();
  }, []);

  useEffect(() => {
    fetchDraftOrders();
  }, [refresh]);

  const fetchDraftOrders = async () => {
    const { orderId } = params;
    try {
      const response = await axiosInstance.get(
        `/api/ppm/projects-products-categories/object/${orderId}/orders?state=DRAFT`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const CancelOrder = async (orderId) => {
  //   try {
  //     const response = await axiosInstance.post(
  //       `/api/ppm/order-actions/object/${orderId}/cancel-order`
  //     );
  //     console.log(response);
  //     fetchDraftOrders();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="h-[calc(100vh-240px)]">
      <DataGrid
        rowSelection={false}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableMultipleColumnsSorting
        hideFooter
        disableColumnMenu
        slots={{
          toolbar: DataGridCustomToolbar,
          loadingOverlay: LinearProgress
        }}
      />
      {showBuyerPoModal.open && (
        <BuyerPOModal
          open={showBuyerPoModal.open}
          onClose={() =>
            setShowBuyerPoModal({
              open: false,
              orderId: null
            })
          }
          orderId={showBuyerPoModal.orderId}
        />
      )}
      {Boolean(showRevisedQuotationModal.open) && (
        <RevisedQuotationModal
          open={showRevisedQuotationModal.open}
          onClose={() =>
            setShowRevisedQuotationModal({
              open: false,
              orderId: null,
              projectsProductsCategoryId: null
            })
          }
          getApi={`/api/ppm/quotations/rrq-price/${showRevisedQuotationModal.projectsProductsCategoryId}`}
          projectsProductsCategoryId={showRevisedQuotationModal.projectsProductsCategoryId}
          user_team={"demand"}
          orderId={showRevisedQuotationModal.orderId}
          fetchParent={fetchDraftOrders}
        />
      )}
      {Boolean(showQualityFeedbackModal.open) && (
        <QualityFeedbackModal
          open={showQualityFeedbackModal.open}
          api={showQualityFeedbackModal.api}
          fetchParent={fetchDraftOrders}
          title={showQualityFeedbackModal.title}
          onClose={() =>
            setShowQualityFeedbackModal({
              open: false,
              title: "",
              api: ""
            })
          }
        />
      )}
    </div>
  );
};

export default DraftOrders;
