import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../core/apiInstance";
import { saveData } from "../../../../core/utilsFunctions";
import ProjectsView from "./ProjectsView";
import { useAuthStore } from "../../../../state/auth.state";
import { useEffect, useState } from "react";

const AllProjectsSection = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { user } = useAuthStore();
  const isAdmin = user?.userTeams?.find((team) => team.team_name === "ADMIN");
  const [accountManager, setAccountManager] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState(user.id);

  const handleFetchAccountManager = async () => {
    try {
      const response = await axiosInstance.get("/api/ppm/users?team=DEMAND");
      setAccountManager(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadPriorityCSV = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/ppm/projects/download-projects-for-priority-csv"
      );
      saveData(response.data, "priority-projects.csv");
    } catch (err) {
      console.log(err);
    }
  };

  const uploadProjectPriority = async (e) => {
    if (e.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      await axiosInstance.post("/api/ppm/projects/upload-projects-for-priority-csv", formData);
      // update state
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleFetchAccountManager();
  }, []);

  return (
    <div className="h-[calc(100vh-150px)] w-[calc(100vw-290px)]">
      <div className="flex">
        <Typography variant="h5" gutterBottom>
          Projects
        </Typography>
        <div className="ml-5 flex items-center gap-3">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate("action/createProject")}>
            Create project
          </Button>
          <Button color="primary" variant="outlined" onClick={handleDownloadPriorityCSV}>
            Download Priority CSV
          </Button>
          <Button color="primary" variant="outlined" component="label">
            Upload Priority CSV
            <input type="file" hidden onChange={(e) => uploadProjectPriority(e)} />
          </Button>
        </div>
        {isAdmin && (
          <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel>Account manager</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedAccountManager}
              onChange={(e) => setSelectedAccountManager(e.target.value)}
              label="Account Manager">
              <MenuItem key={0} value={null}>
                ALL
              </MenuItem>
              {accountManager.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div>
          <TextField
            id="input-with-icon-textfield"
            label="Search"
            fullWidth
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant="standard"
          />
        </div>
      </div>
      <div className="h-full overflow-x-auto">
        <ProjectsView accountManager={selectedAccountManager} searchText={searchText} />
      </div>
    </div>
  );
};

export default AllProjectsSection;
