import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, Link, Typography, Grid } from "@mui/material";
import { useAuthStore } from "../../../state/auth.state";
import { axiosInstance } from "../../../core/apiInstance";
import { useShowAlertStore } from "../../../state/alert.state";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function LoginPage() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setToken } = useAuthStore();
  const { showAlert } = useShowAlertStore();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "development") {
      setValue("password", "Covvalent@123");
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post("/api/ppm/auth/login", data);
      const user = jwtDecode(response.data.token);
      setToken(response.data.token, user);
      setIsLoading(false);
      showAlert({ open: true, message: "Logged In Successfully", severity: "success" });
      console.log(user);
      const isDemandSide = user.userTeams.find((team) => team.team_name === "DEMAND");
      const isSupply_QA_Side = user.userTeams.find((team) => team.team_name === "SUPPLY_QA");
      const isSupplySide = user.userTeams.find((team) => team.team_name === "SUPPLY");

      if (isDemandSide) {
        navigate("/dashboard/orders/projects");
      } else if (isSupply_QA_Side) {
        return navigate("/dashboard/supply/quality-analysis");
      } else if (isSupplySide) {
        navigate("/dashboard/queries");
      } else {
        navigate("/dashboard/products");
      }

      // look for super admin
      // look for admin
      // look for supply side
      // look for demand side
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      showAlert({ open: true, message: err?.response?.data?.message, severity: "error" });
    }
  };

  return (
    <div className="flex flex-col justify-center min-h-screen bg-gray-100">
      <div className="max-w-md mx-auto">
        <div className="text-center">
          <Typography variant="h4">Login</Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register("email")}
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                // inputRef={register({
                //   required: "Email is required",
                //   pattern: {
                //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                //     message: "Invalid email address"
                //   }
                // })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                {...register("password")}
                variant="outlined"
                label="Password"
                name="password"
                type="password"
                // inputRef={register({ required: "Password is required" })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
              {false && (
                <div className="flex justify-end">
                  <Link href="#" className="text-sm">
                    Forgot password?
                  </Link>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}>
                {isLoading ? "Loading..." : "Login"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
